//@flow
import React from 'react';
import {Alert, Col, Modal, Row} from "react-bootstrap";
import {AuthPage} from "../lib/Components";
import {useMsgs} from "../lib/Language";

const ContactScreen=() => {
    const msgs=useMsgs();
    return <AuthPage title={msgs.gui.titleContact}>
        <Modal.Body>
            <Row>
                <Col md={6}>
                    {msgs.links.phone?<Alert variant="info">
                        <a href={"tel:"+msgs.links.phone} className="font-weight-normal font-size-lg">
                            <span className="icon icon-mobile icon-size"/>
                            {msgs.links.phoneLabel}
                        </a>
                    </Alert>:null}
                </Col>
                <Col md={6}>
                    {msgs.links.email?<Alert variant="info">
                        <a href={"mailto:"+msgs.links.email} className="font-weight-normal font-size-lg">
                            <span className="icon icon-mail-envelope-closed icon-size"/>
                            {msgs.links.emailLabel}
                        </a>
                    </Alert>:null}
                </Col>
            </Row>
            <Alert variant="info">
                <span className="icon icon-location font-size-lg"/>
                <div className="content font-size-lg" dangerouslySetInnerHTML={{ __html: msgs.gui.contactCompanyInfo}}/>
            </Alert>
        </Modal.Body>
    </AuthPage>
};


export default ContactScreen;