//@flow
import React from "react";
import {useRpcStaticQuery} from "./QueryUtils";
import type {StaticPage} from "../api";

const ContentPage = ({ page, className }: {
    page: string;
    className?: string;
}) => {
    const content=useRpcStaticQuery<StaticPage>("/public", "getStaticPage", [ page ]);
    return <div className={className} dangerouslySetInnerHTML={{ __html: content.data?content.data.content:null }}/>

}
export default ContentPage;