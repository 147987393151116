import React, {useCallback, useState} from 'react';
import {Events, store, useEvent} from "./application";
import LoginApp from "./auth/LoginApp";
import UserApp from "./user/UserApp";
import TermsScreen from "./TermsScreen";


/**
 * W tym miejscu tylko obsługujemy przejście pomiędzy aplikacją autoryzacji (logowania)
 * i aplikacji użytkownika (zalogowanego)
 * @return {JSX.Element}
 * @constructor
 */
const App=() => {
    const [ logged, setLogged ] = useState(store.isLogged);
    const updateLoginState=useCallback(() => {
        setLogged(store.isLogged);
    }, [setLogged]);
    useEvent(Events.Login, updateLoginState, []);

    if(logged) {
        if(store.user.agreements) {
            return <TermsScreen/>;
        }
        return <UserApp/>;
    }
    else return <LoginApp/>;
}

export default App;