//@flow
import React, {useMemo} from 'react';
import {DialogLink, formatString, useMsgs} from "../lib/Language";
import {Alert, Breadcrumb, Card, Col, Row} from "react-bootstrap";
import {BreadcrumbItem, ForEach, NotificationEntry, PageHeader} from "../lib/Components";
import DesktopScreen from "./DesktopScreen";
import {InvoiceMonitoringImportDialog} from "./InvoiceMonitoringInvoicesScreen";
import {store} from "../application";
import {Redirect} from "react-router-dom";
import {NotificationCountersTable, OrderedStages, StageColors} from "../lib/InvoiceMonitoringComponents";
import {useRpcQuery} from "../lib/QueryUtils";
import type {InvoiceMonitoringSubscriptionType, InvoicesStats, MonitoringDesktopInformation} from "../api";
import CircleProgress from "../lib/CircleProgress";
import LazyBarChart from "../lib/LazyBarChart";
import {MonitoringStage, UserWebNotification} from "../api";
import {formatDateTime} from "../lib/DateTime";
import {formatMoney} from "../lib/Utils";
import type {BarChartEntry} from "../lib/BarChart";
import cn from 'classnames';

const BasicInfo = ({ value }: { value: MonitoringDesktopInformation }) => {
    const msgs=useMsgs();
    if(!value) return null;

    let before: InvoicesStats=value.beforeStats;
    if(!before) before={ unpaid: 0, invoices: 0, clients: 0, paid: 0, amount: 0 };


    return <div className="im-desktop-counter">
        <div>
            <CircleProgress
                value={value.invoices-before.invoices}
                total={value.invoices}
                totalColor="gray"
                valueColor="var(--secondary)"
                width="10rem"
                height="10rem"
                thickness={14}
            ><div>
                <span className="h2">{(value.invoices)}</span><br/>
                <span className="h5">{msgs.gui.imLabelInvoices}</span>
            </div></CircleProgress>
        </div>
        <div>
            <div className="overdue">
                {value.overdueStats?<>
                    <span>{formatMoney(value.overdueStats.unpaid, "PLN")}</span>
                    {msgs.gui.imLabelAfterDateOfPayment}
                </>:"--"}
            </div>
            <hr/>
            <div className="before">
                {value.beforeStats?<>
                    <span>{formatMoney(value.beforeStats.unpaid, "PLN")}</span>
                    {msgs.gui.imLabelBeforeDateOfPayment}
                    </>:"--"}
            </div>
        </div>
    </div>
}

const StageChart = ({ value }: { value: { [MonitoringStage]: InvoicesStats } }) => {
    const msgs=useMsgs();
    const data: null|Array<BarChartEntry> = useMemo(() => {
        if(!value) return null;
        let res: Array<BarChartEntry>=[];
        for(let i=0;i<OrderedStages.length;++i) {
            const s=OrderedStages[i];
            const v: InvoicesStats=value[s];
            if(!v) continue;
            res.push({
                value: v.unpaid,
                color: StageColors[i],
                name: msgs.gui['monitoringInvoiceStage'+s]
            })
        }
        // console.log("Chart data: ", res);
        return res;
    }, [ value, msgs ]);
    if(!data) return null;
    const rem=parseFloat(getComputedStyle(document.documentElement).fontSize);
    return <LazyBarChart
        data={data}
        xAxisLabel={msgs.gui.imLabelInvoicesByStages}
        maxBarSize={2*rem}
    />
}

const SyncInfo = ({ value }: { value: MonitoringDesktopInformation }) => {
    const msgs=useMsgs();
    return <div className={cn("sync-info", value.lastSyncDanger && "sync-danger")}>
        <label>{msgs.gui.imLabelLastSyncDate}</label>
        {!value.lastSync?<span className="text-danger">{msgs.gui.imLabelNeverSync}</span>:<span>{formatDateTime(value.lastSync)}</span>}
        {value.lastSyncDaysAgo && ((value.lastSyncDaysAgo>0)?<><span> ({formatString(msgs.gui.agoDays, value.lastSyncDaysAgo)})</span></>:<span> ({msgs.gui.agoToday})</span>)}
    </div>
}

const InvoiceMonitoringEvents = ({ value }: { value: Array<UserWebNotification> }) => {
    return <ForEach value={value}>{(n: UserWebNotification, i) => <NotificationEntry key={i} value={n}/>}</ForEach>
}

const InvoiceMonitoringSubscriptionInfo = ({ type, periodSms, periodEmail, extraSms, extraEmail }: {
    type: InvoiceMonitoringSubscriptionType;
    periodSms: number; periodEmail: number;
    extraSms: number; extraEmail: number;
}) => {
    const msgs=useMsgs();

    let typeInfo;
    switch (type) {
    }

    return <>
        <label>{msgs.gui.labelCurrentSubscription}</label>
        {msgs.gui['imSubscription'+type]}<br/>
        {formatString(msgs.gui['imSubscription'+type+'Usage'], periodEmail, periodSms)}<br/>
        {(extraSms>0 || extraEmail>0)?formatString(msgs.gui.imSubscriptionExtraAvailability, extraEmail, extraSms):null}
    </>
}

const InvoiceMonitoringDesktopScreen = () => {
    const msgs=useMsgs();
    const query = useRpcQuery("/user", "getMonitoringDesktopInformation");
    if(!store.invoiceMonitoring || !store.invoiceMonitoring.read) return <Redirect to="/" push={false}/>

    const d:MonitoringDesktopInformation=query.data;
    if(!d) return null;
    let plnStats: InvoicesStats;
    if(d.byCurrency && d.byCurrency['PLN']) plnStats=d.byCurrency['PLN'];
    else plnStats={ amount: 0, paid: 0, clients: 0, invoices: 0, unpaid: 0 };

    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.gui.menuInvoiceMonitoringDesktop}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.gui.menuInvoiceMonitoringDesktop}/>
        <Row>
            <Col xl={9}>
                <Card className="data basic shadow">
                    <Card.Body>
                        <Row>
                            <Col md={7}>
                                <Row className="basic-header">
                                    <Col>
                                        <label>{msgs.gui.imLabelCurrentlyMonitored}</label>
                                        <BasicInfo value={d}/>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <SyncInfo value={d}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={5} className="col-splitter">
                                <Row className="basic-header">
                                    <Col>
                                        <StageChart value={d.byStage}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col xl={3}>
                <Card className="data card-secondary state mt-4 mt-xl-0">
                    <Card.Body className="d-flex flex-column">
                        <Row>
                            <Col>
                                <label>{msgs.gui.imLabelEffectivenessOfMonitoring}</label>
                                {typeof(d.efficiency)==='number'?`${d.efficiency.toFixed(1)}%`:"---"}
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <label>{msgs.gui.imLabelAverageOverdue}</label>
                                {d.overdueAverageDays} {msgs.gui.labelDays}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <InvoiceMonitoringSubscriptionInfo
                                    type={d.type}
                                    periodSms={d.periodSms} periodEmail={d.periodEmail}
                                    extraSms={d.extraSms} extraEmail={d.extraEmail}
                                />
                                <label>{msgs.gui.imAutoVindication}</label>
                                <span dangerouslySetInnerHTML={{
                                    __html: d.autoVindication?formatString(msgs.gui.imAutoVindicationEnabled, d.autoVindicationGraceDays):msgs.gui.imAutoVindicationDisabled
                                }}/>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Card className="mt-5">
            <Card.Body>
                <Card.Title>{msgs.gui.imLastSent}</Card.Title>
                <NotificationCountersTable days={14} sent={d && d.sent} projected={d && d.projected}/>
            </Card.Body>
        </Card>
        <Card className="mt-5">
            <Card.Body>
                <Card.Title>{msgs.gui.imLabelLastMonitoringEvents}</Card.Title>
                <InvoiceMonitoringEvents value={d.events}/>
            </Card.Body>
        </Card>
    </>;
}
InvoiceMonitoringDesktopScreen.url="/im/desktop";
InvoiceMonitoringDesktopScreen.link = () => InvoiceMonitoringDesktopScreen.url;
export default InvoiceMonitoringDesktopScreen;