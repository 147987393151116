//@flow
import React, {useCallback, useMemo, useState} from 'react';
import {getLangValue, useMsgs} from "../lib/Language";
import {Alert, Breadcrumb, Button, Card, Col, InputGroup, Row} from "react-bootstrap";
import {BreadcrumbItem, ConfirmDialog, IconAlert, InlineDialog, PageHeader, SaveButton} from "../lib/Components";
import DesktopScreen from "./DesktopScreen";
import InvoiceMonitoringDesktopScreen from "./InvoiceMonitoringDesktopScreen";
import {BForm, Form} from "../lib/Form";
import {
    ChangeScenarioDialog,
    FormScenarioSelect, integrationFormSettingsToSettings,
    IntegrationSettings,
    IntegrationSettingsEditForm,
    integrationSettingsToFormSettings,
    MonitoringScenarioController,
    MonitoringScenarioSettingsInput,
    MonitoringScenarioStepsForm,
    ScenarioSelect
} from "../lib/InvoiceMonitoringComponents";
import {QueryLoader, useRpcQuery, useRpcStaticQuery} from "../lib/QueryUtils";
import {MonitoringSettings} from "../api";
import {store} from "../application";
import type {SaveButtonState} from "../lib/Components";
import {Redirect} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";
import type {
    InvoiceMonitoringIntegrationSettings,
    MonitoringScenarioChangeMode,
    MonitoringScenarioDetails
} from "../api";
import {UseQueryResult} from "@tanstack/react-query";

const ScenarioConfig = ({ value, steps }) => {
    const msgs=useMsgs();
    const details = useRpcStaticQuery("/user", "getMonitoringScenario", value || "");
    const query = useQueryClient();
    const [edit, setEdit] = useState(false);
    const [change, setChange] = useState(false);

    const handleEdit = useCallback(() => {
        setEdit(true);
    }, []);
    const handleSubmitEdit = useCallback(async(steps) => {
        const res=await store.userApi.updateInvoiceMonitoringScenario(value, steps);
        if(res) {
            await query.invalidateQueries(["rpc", "/user", "getInvoiceMonitoringSettings"]);
        }
        setEdit(false);
    }, [ value ]);

    const handleChange = useCallback(() => {
        setChange(true);
    }, []);
    const handleSubmitChange = useCallback(async (newScenario: string, mode: boolean|null) => {
        const res = await store.userApi.updateInvoiceMonitoringScenario(newScenario, null);
        if (res) {
            await query.invalidateQueries(["rpc", "/user", "getInvoiceMonitoringSettings"]);
        }
        setChange(false);
    }, []);

    if (!details.isSuccess) return null;
    const data: MonitoringScenarioDetails = details.data;

    return <>
        {!value?<h3 className="text-warning">{msgs.gui.imNoScenario}
            <Button className="float-right" onClick={handleChange}>{msgs.gui.buttonSelectScenario}</Button>
        </h3>:<h3>
            <div className="float-right">
                <Button onClick={handleChange}>{msgs.gui.buttonChangeScenario}</Button>
            </div>
            {getLangValue(data.title)}
        </h3>}
        {data && <Alert variant="info" className="my-3">{getLangValue(data.desc)}</Alert>}
        {value && <MonitoringScenarioStepsForm scenario={value} previewOrg={store.user.orgId} data={data} readonly={true} value={steps} collapsible={false}/>}
        {value && <Row className="mb-3"><Col><Button variant="success" onClick={handleEdit}>{msgs.gui.buttonEditSteps}</Button></Col></Row>}

        {edit && <MonitoringScenarioController
            scenario={value} previewOrg={store.user.orgId}
            value={steps}
            onHide={() => setEdit(false)}
            readonly={false}
            onChange={handleSubmitEdit}
        />}
        {change && <ChangeScenarioDialog value={value} onChange={handleSubmitChange} onHide={e => setChange(false)}/>}
    </>;
}

const InvoiceMonitoringIntegrationDialog = ({ onHide }: {
    onHide: () => void;
}) => {
    const msgs = useMsgs();
    const query = useRpcQuery<InvoiceMonitoringIntegrationSettings>('/user', 'getInvoiceMonitoringIntegrationSettings');
    const queryClient = useQueryClient();
    const value=useMemo(() => {
        if(!query.data) return null;
        return integrationSettingsToFormSettings(query.data);
    }, [ query.data ])

    if (!query.isSuccess) return null;
    return <InlineDialog
        title={msgs.gui.imIntegrationTitle}
        onCancel={onHide}
    >
        <IntegrationSettingsEditForm
            value={value}
            onSubmit={async (type, value) => {
                if (type === "DataIntegrator") {
                    const key = await store.userApi.createInvoiceMonitoringDataIntegratorKey();
                    if (!key) {
                        window.alert("Błąd generowania klucza!");
                        return;
                    }
                } else {
                    // console.log("Value: ", value);
                    const vs=integrationFormSettingsToSettings(value);
                    // console.log("Settings: ", vs);
                    const res=await store.userApi.setInvoiceMonitoringIntegration(type, vs.key, vs.key2 || null, vs.key3 || null);
                    if(!res) {
                        window.alert("Błąd zapisywania ustawień integracji!");
                        return;
                    }
                }
                await queryClient.invalidateQueries(["rpc", "/user", "getInvoiceMonitoringIntegrationSettings"]);
                onHide();
            }}/>
    </InlineDialog>
}

const InvoiceMonitoringSettingsScreen = () => {
    const msgs=useMsgs();
    const settings:UseQueryResult<MonitoringSettings>=useRpcQuery<MonitoringSettings>("/user", "getInvoiceMonitoringSettings");
    const query = useQueryClient();
    const [saveState, setSaveState] = useState<SaveButtonState>();
    const [ dialog, setDialog ] = useState(null);
    const [ apply, setApply ] = useState<MonitoringScenarioChangeMode>("None");
    const handleDeleteInvoices = useCallback(() => {
        setDialog(<ConfirmDialog
            title={msgs.gui.actionDeleteInvoices}
            onHide={() => setDialog(null)}
            variant="danger"
            accept={msgs.gui.actionDeleteInvoices}
            captcha
            onAccept={async (code) => {
                const res=await store.userApi.invoiceMonitoringDeleteInvoices(code);
                if(res===null) return false;
                setDialog(<InlineDialog
                    title={msgs.gui.actionDeleteInvoices}
                    cancelButton={msgs.gui.buttonClose}
                    onCancel={() => {
                        setDialog(null);

                    }}
                >
                    <IconAlert>{msgs.gui.deleteInvoicesInfo}</IconAlert>
                </InlineDialog>);
                return true;
            }}
        >
            <IconAlert>{msgs.gui.labelDeleteInfo}</IconAlert>
        </ConfirmDialog>);
    }, [ msgs ]);
    const handleDeleteAll = useCallback(() => {
        setDialog(<ConfirmDialog
            key="confirm-dialog"
            title={msgs.gui.actionDeleteAll}
            onHide={() => setDialog(null)}
            variant="danger"
            accept={msgs.gui.actionDeleteAll}
            captcha
            onAccept={async (code) => {
                const res=await store.userApi.invoiceMonitoringPurge(code);
                if(res===null) return false;
                await query.invalidateQueries([ "rpc", "/user", "getInvoiceMonitoringSettings" ]);
                setDialog(<InlineDialog
                    title={msgs.gui.actionDeleteAll}
                    cancelButton={msgs.gui.buttonClose}
                    onCancel={() => {
                        setDialog(null)
                    }}
                >
                    <IconAlert>{msgs.gui.deleteAllInfo}</IconAlert>
                </InlineDialog>);
                return true;
            }}
        >
            <IconAlert>{msgs.gui.labelDeleteInfo}</IconAlert>
        </ConfirmDialog>);
    }, [ msgs, query ]);
    const handleApply=useCallback(async () => {
        const res=await store.userApi.applyMonitoringSettings(apply);
        setDialog(<InlineDialog
            title={msgs.gui.labelApplyDefaultScenario}
            cancelButton={msgs.gui.buttonClose}
            onCancel={() => {
                setDialog(null)
            }}
        >
            {msgs.gui.infoExecutedSuccessful}
        </InlineDialog>)
        setApply("None");
    }, [ apply ])

    if(!store.invoiceMonitoring || !store.invoiceMonitoring.write) return <Redirect to="/" push={false}/>

    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
            <BreadcrumbItem to={InvoiceMonitoringDesktopScreen.link()}>{msgs.gui.menuInvoiceMonitoringDesktop}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.gui.menuInvoiceMonitoringSettings}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.gui.menuInvoiceMonitoringSettings}/>
        <Card className="mb-4">
            <Card.Body>
                <h2 className="mb-3">{msgs.gui.imDefaultScenarioSettings}</h2>
                <QueryLoader query={settings}>
                    {settings.data && <ScenarioConfig value={settings.data.scenario} steps={settings.data.steps}/>}
                    <BForm.Label>{msgs.gui.labelApplyDefaultScenario}</BForm.Label>
                    <InputGroup>
                        <BForm.Control as="select" value={apply} onChange={e => setApply(e.target.value)}>
                            <option value="None">{msgs.gui.optionSelect}</option>
                            <option value="Blank">{msgs.gui.actionApplySettingsBlank}</option>
                            <option value="Force">{msgs.gui.actionApplySettingsForce}</option>
                        </BForm.Control>
                        <InputGroup.Append>
                            <Button
                                variant="outline-secondary"
                                disabled={apply==="None"}
                                onClick={handleApply}
                            >{msgs.gui.buttonExecute}</Button>
                        </InputGroup.Append>
                    </InputGroup>
                </QueryLoader>
            </Card.Body>
        </Card>
        <Card className="mb-4">
            <Card.Body>
                <h2>{msgs.gui.imOtherSettings}</h2>
                <QueryLoader query={settings}>
                    <Form
                        enableReinitialize={true}
                        initialValues={settings.data}
                        validate={(values: MonitoringSettings) => {
                            const err: $Shape<{ [$Keys<MonitoringSettings>] : string }>={};
                            if(values.timeFrom>=values.timeTo) {
                                err.timeFrom="validation.invalidData";
                                err.timeTo="validation.invalidData";
                            } else {
                                if (values.timeFrom < 9 * 60) err.timeFrom = "validation.invalidData";
                                if (values.timeTo > 16 * 60) err.timeTo = "validation.invalidData";
                            }
                            return err;
                        }}
                        onSubmit={async (values: MonitoringSettings, helpers) => {
                            try {
                                setSaveState("loading")
                                const res = await store.userApi.setInvoiceMonitoringSettings(values);
                                if (res) {
                                    settings.remove();
                                } else {
                                    // TODO: Komunikat błędu?
                                }
                            }finally {
                                setSaveState("done")
                            }
                        }}
                    >{(formik) => <>
                        <Form.Row>
                            <Form.ColGroup  name="minimalBalance" md={6}>
                                <Form.Label>{msgs.gui.labelSkipWithBalanceLess}</Form.Label>
                                <InputGroup>
                                    <Form.Number min={0}/>
                                    <InputGroup.Append>
                                        <InputGroup.Text>PLN</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.ColGroup>
                            <Form.ColGroup  name="stop" md={6}>
                                <Form.Label>{msgs.gui.labelStopMonitoringWithSynchronization}</Form.Label>
                                <Form.Number min={1}/>
                            </Form.ColGroup>
                        </Form.Row>
                        <Form.Row>
                            <Form.ColGroup name="workDaysOnly" md={4}>
                                <Form.Label>{msgs.gui.labelNotificationWorkingDaysOnly}</Form.Label>
                                <Form.Switch/>
                            </Form.ColGroup>
                            <Form.ColGroup name="timeFrom" md={4}>
                                <Form.Label>{msgs.gui.labelNotificationFrom}</Form.Label>
                                <Form.DayTime from={9*60} to={16*60} step={60}/>
                            </Form.ColGroup>
                            <Form.ColGroup name="timeTo" md={4}>
                                <Form.Label>{msgs.gui.labelNotificationTo}</Form.Label>
                                <Form.DayTime from={9*60} to={16*60} step={60}/>
                            </Form.ColGroup>
                        </Form.Row>
                        <Form.Row>
                            <Form.ColGroup name="email" md={6}>
                                <Form.Label>{msgs.gui.labelNotificationEmail}</Form.Label>
                                <Form.Email/>
                            </Form.ColGroup>
                            <Form.ColGroup name="phone" md={6}>
                                <Form.Label>{msgs.gui.labelNotificationPhone}</Form.Label>
                                <Form.Phone/>
                            </Form.ColGroup>
                        </Form.Row>
                        <Form.RowGroup name="logo">
                            <Col>
                                <Form.Label className="d-block">{msgs.gui.labelYourLogo}</Form.Label>
                                <Form.FileInput
                                    simple preview imageOnly
                                    maxSize={1048576}
                                    maxWidth={320}
                                    maxHeight={200}
                                    downscale
                                    className="d-block"
                                />
                                <p className="text-info">{msgs.gui.labelYourLogoHint}</p>
                            </Col>
                        </Form.RowGroup>
                        <Form.RowGroup name="observe">
                            <Col>
                                <Form.Label className="d-block">{msgs.gui.labelAutoObserve}</Form.Label>
                                <Form.Switch disabled={!store.monitoring.read && !store.monitoring.write}/>
                            </Col>
                        </Form.RowGroup>
                        <Form.Row>
                            <Col>
                                <h3>{msgs.gui.labelAutoVindication}</h3>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col><p className="text-info">{msgs.gui.labelAutoVindicationInfo}</p></Col>
                        </Form.Row>
                        <Form.Row>
                            <Form.ColGroup name="debtCollection">
                                <Form.Check id="auto-debt-collection">{msgs.gui.labelAutoVindicationSwitch}</Form.Check>
                            </Form.ColGroup>
                        </Form.Row>
                        <Form.RowGroup name="amicable">
                            <Col md={4}>
                                <Form.Label>{msgs.gui.labelAutoVindicationMode}</Form.Label>
                            </Col>
                            <Col md={6}>
                                <Form.Radio disabled={!formik.values.debtCollection} id="auto-amicable" value={true}>{msgs.gui.labelAutoVindicationAmicable}</Form.Radio><br/>
                                <Form.Radio disabled={!formik.values.debtCollection} id="auto-judicial" value={false}>{msgs.gui.labelAutoVindicationJudicial}</Form.Radio><br/>
                            </Col>
                        </Form.RowGroup>
                        <Form.Row>
                            <Col className="text-center">
                                <SaveButton state={saveState}/>
                            </Col>
                        </Form.Row>
                    </>}
                    </Form>
                </QueryLoader>
            </Card.Body>
        </Card>
        <Card>
            <Card.Body>
                <div className="mb-3">
                    <div className="float-right">
                        <Button onClick={() => {
                            setDialog(<InvoiceMonitoringIntegrationDialog onHide={() => setDialog(null)}/>);
                        }}>{msgs.gui.imIntegrationChange}</Button>
                    </div>
                    <h2>{msgs.gui.imIntegrationTitle}</h2>
                </div>
                <IntegrationSettings/>
            </Card.Body>
        </Card>

        <Card className="mt-5">
            <Card.Body>
                <Row>
                    <Col>
                        <Button variant="warning" size="lg" onClick={handleDeleteInvoices}>{msgs.gui.actionDeleteInvoices}</Button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Button variant="danger" size="lg" onClick={handleDeleteAll}>{msgs.gui.actionDeleteAll}</Button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <p className="text-info">
                            {msgs.gui.labelDeleteInfo}
                        </p>
                    </Col>
                </Row>
                {dialog}
            </Card.Body>
        </Card>
    </>;
}
InvoiceMonitoringSettingsScreen.url="/im/settings";
InvoiceMonitoringSettingsScreen.link = () => InvoiceMonitoringSettingsScreen.url;
export default InvoiceMonitoringSettingsScreen;