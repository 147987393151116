//@flow
import React from 'react';
import type {AddState, AddVindicationAPI, VindicationServerAPI} from "../lib/AddVindication";
import {AddVindication} from "../lib/AddVindication";
import msgs, {LangContext, langLink} from "../lib/Language";
import {Button, Modal} from "react-bootstrap";
import {AuthPage, NotificationButton} from "../lib/Components";
import {checkPromo, RegisterComplete, RegisterInput, setRegisterData} from "./RegisterScreen";
import {Ref} from "react";
import {Form} from "../lib/Form";
import {store} from "../application";
import {RouteComponentProps} from "react-router";
import {Redirect} from "react-router-dom";
import * as FacebookPixel from "../lib/FacebookPixel";

type State = {

}

export default class RegisterWithVindicationScreen extends React.Component<RouteComponentProps, State> {
    static url="/register_debtcollection";
    formik: Ref<Form>;
    api: VindicationServerAPI;

    constructor(props) {
        super(props);
        this.formik=React.createRef(null);
        this.state = {

        }
        this.api={
            // Ogólne publiczne
            getCountry: store.getCountry.bind(store),
            getDictionaries: store.getDictionaries.bind(store),
            getInsurers: async() => (await store.getDictionaries()).insurers,   // wszyscy (?)
            getCompanyInfo: store.publicApi.getCompanyInfo.bind(store.publicApi),
            // To dla trybu windykacja z rejestracją
            getRegisteredReport: store.publicApi.getRegisteredReport.bind(store.publicApi),
            updateRegisterVindicationContacts: store.publicApi.updateRegisterVindicationContacts.bind(store.publicApi),
            // To dla trybu zalogowanego użytkownika
            getVindicationsForDebtor: null,
            registerVindication: null,
            generateReport: null,
            updateVindicationContacts: null,
            registerMultipleVindications: null,
            checkDocuments: null,
        }
    }

    componentDidMount() {
        FacebookPixel.trackSinglePageView();
    }

    renderUserForm = (addState: AddState, goNext: (regId: string, regFullId: string) => void) => {
        return {
            title: msgs.gui.titleRegisterWithVindication,
            stage: "register",
            handleBack: null,
            handleNext: (e) => this.formik.current.handleSubmit(e),
            nextLabel: msgs.gui.buttonConfirm,
            render: () => <RegisterInput
                registerButton={false}
                formikRef={this.formik}
                onComplete={async (helpers, values, companyInfo) => {
                    // console.log("Registered user: ", values, companyInfo);
                    console.log("OnComplete", addState, values);
                    let res;
                    if (addState.docsFromFile) {
                        res = await store.publicApi.registerWithVindication(
                            {...addState.value, documents: []},
                            values, addState.docsFile
                        );
                    } else {
                        res = await store.publicApi.registerWithVindication(
                            addState.value, values, null
                        );
                    }
                    if (Form.setError(helpers, res)) return;
                    setRegisterData(values);    // do wyświetlanie ekranu podsumowania
                    goNext(res[0], res[1]);
                }}/>
        }
    }

    render() {
        if(checkPromo()) return <Redirect to={RegisterWithVindicationScreen.url}  push={false}/>;
        return <AddVindication
            logged={false}
            registerRender={this.renderUserForm}
            maxSize={store.webSettings.uploadLimit}
            onSuccess={() => {
                this.props.history.replace(langLink(RegisterComplete.url));
            }}
            serverApi={this.api}
        >{(api: AddVindicationAPI) => <AuthPage
            className="register-vindication"
            title={api.title}
        >
            <Modal.Body>
                {api.render()}
            </Modal.Body>
            <Modal.Footer>
                {(api.stage!=="contact" && api.handleBack)?<Button
                    onClick={api.handleBack}
                    variant="outline-secondary"
                >{msgs.gui.buttonBack}</Button>:null}
                <NotificationButton
                    message={api.nextMessage}
                    onClick={api.handleNext}
                >{api.nextLabel || msgs.gui.buttonNext}</NotificationButton>
            </Modal.Footer>
        </AuthPage>}</AddVindication>;
    }
}
RegisterWithVindicationScreen.contextType=LangContext;