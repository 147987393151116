//@flow
import React, {useEffect, useState} from 'react';
import EventEmitter from "eventemitter3";

export const events=new EventEmitter();

function isValidEvent(name: any): boolean {
    return typeof(name)==='string' && name.length>0;
}

/** Wywołanie funkcji z EventEmiter */
export function addEventListener(event: string, listener: () => void) {
    if(!isValidEvent(event)) return;
    events.on(event, listener);
}

/** Wywołanie funkcji z EventEmiter */
export function removeEventListener(event: string, listener: ()=>void) {
    if(!isValidEvent(event)) return;
    events.off(event, listener);
}

/** Wywołanie funkcji z EventEmiter */
export function emitEvent(event: string, ...args) {
    if(!isValidEvent(event)) return;
    events.emit(event, ...args);
}

/**
 * Opakowanie na funkcję useEffect do obsługi zdarzeń.
 */
export function useEvent(event: string, listener: () => void, inputs: ?$ReadOnlyArray<mixed>) {
    if(!Array.isArray(inputs)) inputs=[];
    useEffect(() => {
        if(!isValidEvent(event)) return;

        events.on(event, listener);
        return () => events.off(event, listener);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, listener, ...inputs] );
}

/**
 * Pomocnicza funkcja, która zwraca licznik aktywowań danych zdarzeń.
 * Działa w oparciu o useState oraz useEffect. Można użyć jak zależność do innego hook-a
 */
export function useEventTrigger(event: string|Array<string>): number {
    const [ counter, setCounter ] = useState(0);
    useEffect(() => {
        const func=() => setCounter(counter+1);
        if(typeof(event)==='string') {
            if(!isValidEvent(event)) return;
            events.on(event, func);
            return () => events.off(event, func);
        } else if(Array.isArray(event)) {
            if(event.length>0) {
                event.forEach(e => {
                    if(!isValidEvent(e)) return;
                    events.on(e, func)
                });
                return () => event.forEach(e => {
                    if(!isValidEvent(e)) return;
                    events.off(e, func)
                });
            }
        }
    }, [ event ])

    return counter;
}

/**
 * Połączenie rejestracji zdarzenia z useState.
 * Założenie jest takie, że parametr zdarzenia to dane
 */
export function useEventState<T>(event: string, initial?: T):T {
    const [ value, setValue ] = useState<T>(initial);
    useEffect(() => {
        const func= (value) => setValue(value);
        events.on(event, func);
        return () => events.off(event, func);
    }, [ event ]);
    return value;
}
