//@flow
import React from 'react';
import {Alert, Button, Container, Form as BForm, Modal} from "react-bootstrap";
import msgs, {currentLang, formatString, getLangValue, getMessage, LangContext, langPath, SwitchLanguage} from "./lib/Language";
import {AuthPage, contactUrl, Icon, staticPageUrl} from "./lib/Components";
import {store} from "./application";
import type {AgreementInfo} from "./api";
import {arrayToggleItem} from "./lib/Utils";
import {Route, Switch} from "react-router-dom";
import StaticScreen from "./auth/StaticScreen";
import ContactScreen from "./auth/ContactScreen";

type State = {
    agreements: Array<AgreementInfo>|null;
    selected: Array<string>;
    submit: boolean;
}

export default class TermsScreen extends React.Component<void, State> {
    static url="/terms";

    constructor(props) {
        super(props);
        this.state = {
            agreements: store.user.agreements,
            selected: [],
            submit: false,
        }
    }


    componentDidMount() {
        document.body.classList.add("auth");
        // store.publicApi.getAgreements().then(agreements => this.setState({ agreements }));
    }
    componentWillUnmount() {
        document.body.classList.remove("auth");
    }

    handleSend() {
        let valid=true;
        this.state.agreements.forEach((a: AgreementInfo) => {
            if(a.required && !this.state.selected.includes(a.name)) valid=false;
        });
        if(!valid) {
            this.setState({
                submit: true
            });
        } else {
            store.userApi.acceptTerms(this.state.selected).then(needed => {
                window.location.reload();
            })
        }
    }

    render() {
        if (!this.state.agreements) return null;
        const user = store.user;
        const lang = currentLang.code;

        return <main>
            <Container>
                <header>
                    <a href="/">
                        <Icon.Logo/>
                    </a>
                    <SwitchLanguage/>
                </header>
                <Switch>
                    <Route path={langPath(staticPageUrl)} component={StaticScreen}/>
                    <Route path={langPath(contactUrl)} component={ContactScreen}/>
                    <Route>
                        <AuthPage title={msgs.gui.titleTerms}>
                            <Modal.Body>
                                <Alert variant="info">
                                    <div className="icon in-circle">
                                        <Icon.Info/>
                                    </div>
                                    <div className="content">
                                        <span/>
                                        <div dangerouslySetInnerHTML={{ __html: formatString(msgs.gui.termsInfo, user.firstname) }}/>
                                    </div>
                                </Alert>
                                <BForm className="mt-5">
                                    {this.state.agreements.map((a: AgreementInfo) => {
                                        const selected = this.state.selected.includes(a.name);
                                        const error = (!selected && a.required && this.state.submit) ? msgs.validation.required : null;
                                        return <BForm.Group
                                            key={a.name}
                                        >
                                            <BForm.Check id={"agreement_" + a.name} custom>
                                                <BForm.Check.Input
                                                    isInvalid={!!error}
                                                    type="checkbox"
                                                    checked={selected}
                                                    onChange={(e) => {
                                                        this.setState({selected: arrayToggleItem(this.state.selected, a.name)});
                                                    }}
                                                />
                                                <BForm.Check.Label dangerouslySetInnerHTML={{  __html: getLangValue(a.info) }}/>
                                                {error ? <BForm.Control.Feedback type="invalid">{getMessage(error)}</BForm.Control.Feedback> : null}
                                            </BForm.Check>
                                        </BForm.Group>
                                    })}
                                    <div className="text-right">
                                        <Button
                                            size="lg"
                                            onClick={() => this.handleSend()}
                                        >{msgs.gui.actionAcceptTerms}</Button>
                                    </div>
                                </BForm>
                            </Modal.Body>
                        </AuthPage>
                    </Route>
                </Switch>
            </Container>
        </main>;
    }
}
TermsScreen.contextType=LangContext;