//@flow
import React from 'react';
import {RouteComponentProps} from "react-router";
import {Alert, Breadcrumb, Card, Col, Row} from "react-bootstrap";
import msgs from "../lib/Language";
import {BreadcrumbItem, PageHeader} from "../lib/Components";
import {Form} from "../lib/Form";
import {ContactMessageInitial, ContactMessageValidation} from "../api";
import {store} from "../application";
import DesktopScreen from "./DesktopScreen";

export default class ContactScreen extends React.Component<RouteComponentProps, void> {
    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={DesktopScreen.contextType}>{msgs.gui.titleSummary}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.gui.titleContact}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.gui.titleContact}/>
            <Row>
                <Col md={7}>
                    <Card>
                        <Card.Body>
                            <Card.Title>{msgs.gui.titleContactForm}</Card.Title>
                            <Form
                                initialValues={ContactMessageInitial}
                                validate={ContactMessageValidation}
                                onSubmit={async (values, helpers) => {
                                    let res=await store.userApi.sendContactMessage(values);
                                    if(!Form.setError(helpers, res)) {
                                        helpers.setValues(ContactMessageInitial);
                                        helpers.setTouched({});
                                        window.alert(msgs.gui.hintMessageSend);
                                    }
                                }}
                            >{(formik) => <>
                                <Form.Group name="topic">
                                    <Form.Label>{msgs.gui.labelTopic}</Form.Label>
                                    <Form.Text/>
                                </Form.Group>
                                <Form.Group name="message">
                                    <Form.Label>{msgs.gui.labelMessage}</Form.Label>
                                    <Form.TextArea rows={6}/>
                                </Form.Group>
                                <div className="text-center">
                                    <Form.Submit>{msgs.gui.actionSend}</Form.Submit>
                                </div>
                            </>}</Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={5}>
                    <Card>
                        <Card.Body>
                            <Card.Title>{msgs.gui.titleContact}</Card.Title>
                            {msgs.links.phone?<Alert variant="info">
                                <a href={"tel:"+msgs.links.phone} className="font-weight-normal font-size-lg">
                                    <span className="icon icon-mobile icon-size"/>
                                    {msgs.links.phoneLabel}
                                </a>
                            </Alert>:null}
                            {msgs.links.email?<Alert variant="info">
                                <a href={"mailto:"+msgs.links.email} className="font-weight-normal font-size-lg">
                                    <span className="icon icon-mail-envelope-closed icon-size"/>
                                    {msgs.links.emailLabel}
                                </a>
                            </Alert>:null}
                            <Alert variant="info">
                                <span className="icon icon-location font-size-lg"/>
                                <div className="content font-size-lg" dangerouslySetInnerHTML={{ __html: msgs.gui.contactCompanyInfo}}/>
                            </Alert>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>;
    }
}
ContactScreen.mainClassName="screen-contact";
