//@flow
import React, {useCallback, useMemo, useState} from 'react';
import {formatString, LangLink, useMsgs} from "../lib/Language";
import {Alert, Breadcrumb, Button, Card, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {BreadcrumbItem, InlineDialog, PageHeader} from "../lib/Components";
import DesktopScreen from "./DesktopScreen";
import InvoiceMonitoringDesktopScreen from "./InvoiceMonitoringDesktopScreen";
import {useRpcQuery} from "../lib/QueryUtils";
import type {InvoiceMonitoringSubscription} from "../api";
import {store} from "../application";
import {Redirect} from "react-router-dom";
import { isFragment } from "react-is";
import {formatMoney} from "../lib/Utils";
import {InvoiceMonitoringPacketPrice, InvoiceMonitoringPacketType} from "../api";
import cn from 'classnames';
import {formatDate} from "../lib/DateTime";
import InvoiceMonitoringSubscriptionHistoryScreen from "./InvoiceMonitoringSubscriptionHistoryScreen";

let buttonId=0;

const BuyButton = ({ onClick, disabled, children, ...props }: {
    onClick: false|(e: SyntheticEvent) => void;
    disabled?: string|React$Node|boolean;
}) => {
    const id = useMemo(() => "bb_" + (++buttonId), []);
    if (onClick === false) return null;
    const hasOverlay=disabled && typeof(disabled)!=='boolean';
    let res = <Button
        disabled={!!disabled}
        style={{ pointerEvents: hasOverlay?'none':null  }}
        variant="success"
        size="lg"
        onClick={onClick}
        {...props}
    >{children}</Button>
    if (!hasOverlay) return res;
    return <OverlayTrigger overlay={<Tooltip id={id}>{disabled}</Tooltip>}><span style={{ display: 'inline-block' }}>{res}</span></OverlayTrigger>
}

const SubscriptionPacket = ({ title, subtitle, price, priceInfo, children, footer, onBuy, disabled }: {
    title: string|React$Node; subtitle: string|React$Node;
    price: string|React$Node; priceInfo: string|React$Node;
    footer: string|React$Node;
    onBuy?: () => void;
    disabled?: string|boolean;
}) => {
    const msgs=useMsgs();

    return <Card className="subscription-packet">
        <Card.Body>
            <div className="header-part">
                <h4>{title}</h4>
                <span>{subtitle}</span><br/>
            </div>
            <div className="buy-part">
                <BuyButton disabled={disabled} onClick={onBuy}>{msgs.gui.imSubscriptionBuy}</BuyButton>
            </div>
            <div className="price-part">
                {isFragment(price)?price:[
                    <p key="price" className="price">{formatMoney(price, "PLN")}</p>,
                    priceInfo && <p key="info">{priceInfo}</p>
                ]}
            </div>
            <div className="info-part">
                {children}
            </div>
            <div className="footer-part">
                {footer}
            </div>
        </Card.Body>
    </Card>
}

const ExtraPacket = ({ title, price, priceInfo, children, onBuy, disabled }: {
    title: string|React$Node;
    price: string|React$Node; priceInfo: string|React$Node;
    onBuy: () => void;
    disabled?: string|boolean;
}) => {
    const msgs=useMsgs();
    return <Card className="extra-packet">
        <Card.Body>
            <div className="header-part">
                <h4>{title}</h4>
            </div>
            <div className="buy-part">
                <BuyButton disabled={disabled} onClick={onBuy}>{msgs.gui.labelBuyPacket}</BuyButton>
            </div>
            <div className="price-part">

                {price && <p className="price">{formatMoney(price, "PLN")}</p>}
                {priceInfo && <p>{priceInfo}</p>}
            </div>
            <div className="info-part">
                {children}
            </div>
        </Card.Body>
    </Card>
}

const PriceInfo = ({ net, gross, children, title, hint, active, onClick }) => {
    const msgs= useMsgs();
    return <Alert
        variant="success" className={cn("price-select", active && "active")}
        onClick={onClick}
    >
        <div className="price-info">{title}</div>
        <div className="price-net"><span>{msgs.gui.imPayNet}</span><span>{formatMoney(net, "PLN")}</span></div>
        <div className="price-gross"><span>{msgs.gui.imPayGross}</span><span>{formatMoney(gross, "PLN")}</span></div>
        {hint && <div className="extra-hint" dangerouslySetInnerHTML={{ __html: hint }}/>}
        {children}
    </Alert>
}

/**
 * Okno dla zakupu wybranego pakietu.
 */
const BuyDialog = ({ title, packet, onBuy, onCancel }: {
    title: React$Node;
    packet: InvoiceMonitoringPacketPrice;
    onBuy: (packet: InvoiceMonitoringPacketPrice, mode: string) => void;
    onCancel: () => void;
}) => {
    const msgs= useMsgs();
    const [ mode, setMode ] = useState(() => {
        if(packet.x12monthPrice) return "12";
        if(packet.x6monthPrice) return "6";
        if(packet.x3monthPrice) return "3";
        return "1";
    });

    let options=[];
    if(packet.x3monthPrice) {
        options.push(<PriceInfo
            key="x3" net={packet.x3monthPrice} gross={packet.x3monthPriceGross}
            active={mode==="3"} onClick={() => setMode("3")}
            title={msgs.gui.imPay3month}
            hint={msgs.gui.imSubscription3MonthInfo}
        />);
    }
    if(packet.x6monthPrice) {
        options.push(<PriceInfo
            key="x6" net={packet.x6monthPrice} gross={packet.x6monthPriceGross}
            active={mode==="6"} onClick={() => setMode("6")}
            title={msgs.gui.imPay6month}
            hint={msgs.gui.imSubscription6MonthInfo}
        />);
    }
    if(packet.x12monthPrice) {
        options.push(<PriceInfo
            key="x12" net={packet.x12monthPrice} gross={packet.x12monthPriceGross}
            active={mode==="12"} onClick={() => setMode("12")}
            title={msgs.gui.imPay12month}
            hint={msgs.gui.imSubscription12MonthInfo}
        />);
    }
    if(options.length>0) {
        options.unshift(<PriceInfo
            key="x1" net={packet.price} gross={packet.priceGross}
            active={mode==="1"} onClick={() => setMode("1")}
            title={msgs.gui.imPay1month}
            hint={msgs.gui.imSubscription1MonthInfo}
        />)
    } else {    // dla pakietów ekstra, tylko jedna opcja
        options.push(<PriceInfo
            key="x1" net={packet.price} gross={packet.priceGross}
            active={mode==="1"} onClick={() => setMode("1")}
            hint={msgs.gui.imSubscriptionExtraInfo}
        />)
    }

    return <InlineDialog
        title={title}
        onAccept={() => onBuy(packet, mode)}
        acceptButton={msgs.gui.actionBuy}
        acceptVariant="success"
        onCancel={onCancel}
    >
        {options}
    </InlineDialog>
}

const InvoiceMonitoringSubscriptionScreen = () => {
    const msgs = useMsgs();
    const q = useRpcQuery<InvoiceMonitoringSubscription>("/user", "getInvoiceMonitoringSubscription")
    const [ buyDialog, setBuyDialog ] = useState<React$Node|null>(null);

    const handlePay=useCallback(async (packet: InvoiceMonitoringPacketPrice, mode: string) => {
        console.log("Buy: ", { packet, mode });
        const url=await store.userApi.buyMonitoringPacket(packet.type, mode);
        setBuyDialog(null);
        if(url) {
            window.location.href=url;
        }
    }, []);

    if (!store.invoiceMonitoring || !store.invoiceMonitoring.settings.im_subscription) return <Redirect to="/" push={false}/>
    const data: InvoiceMonitoringSubscription=q.data;


    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.menuInvoiceMonitoringSubscription}</BreadcrumbItem>
            <BreadcrumbItem
                to={InvoiceMonitoringDesktopScreen.link()}>{msgs.gui.menuInvoiceMonitoringDesktop}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.gui.menuInvoiceMonitoringSubscription}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.gui.menuInvoiceMonitoringSubscription}/>
        {buyDialog}
        {data && <>
            <Card className="blue">
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <label>{msgs.gui.labelCurrentSubscription}</label>
                            <div>{msgs.gui['imSubscription' + data.subscription]}</div>
                            {data.term && <div>{formatString(msgs.gui.imSubscriptionTerm, formatDate(data.term))}</div>}
                            <div><LangLink to={InvoiceMonitoringSubscriptionHistoryScreen.link()}>{msgs.gui.menuInvoiceMonitoringSubscriptionHistory}</LangLink></div>
                            {/*{data.dom>0 && <div>{formatString(msgs.gui.imSubscriptionPeriodDay, data.dom)}</div>}*/}
                        </Col>
                        <Col md={4}>
                            <label>{msgs.gui.labelNotificationsSend}</label>
                            {formatString(msgs.gui.labelNotificationsSendValue, data.sendSMSs, data.sendEmails)}
                        </Col>
                        {data.subscription!=="Corporate" && <Col md={4}>
                            <label>{msgs.gui.imSubscriptionAvailable}</label>
                            {formatString(msgs.gui.imSubscriptionAvailableValue, data.subscriptionEmails, data.extraEmails, data.subscriptionSMSs, data.extraSMSs)}
                        </Col>}
                        {/*<Col md={3} className="sep-border">*/}
                        {/*    <label>{msgs.gui.labelAllDebtsSum}</label>*/}
                        {/*</Col>*/}
                        {/*<Col md={3}>*/}
                        {/*</Col>*/}
                    </Row>
                </Card.Body>
            </Card>
            <Card className="mt-5">
                <Card.Body>
                    <h3>{msgs.gui.labelSubscriptions}</h3>
                    <div className="subscription-packets">
                        <SubscriptionPacket
                            title={msgs.gui.imSubscriptionFree}
                            subtitle={formatString(msgs.gui.labelRecommendedContractors, 5)}
                            price={data.prices["Free"].price}
                            priceInfo={msgs.gui.labelAlwaysFree}
                            footer={msgs.gui.labelAlwaysFree}
                            disabled={!data.prices["Free"].canBuy}
                            onBuy={false}
                        >
                            <b>{msgs.gui.imSubscriptionFreeCount}</b><br/>
                            {msgs.gui.imSubscriptionMonthly}
                        </SubscriptionPacket>
                        <SubscriptionPacket
                            title={msgs.gui.imSubscriptionMini}
                            subtitle={formatString(msgs.gui.labelRecommendedContractors, 10)}
                            price={data.prices["Mini"].price}
                            priceInfo={msgs.gui.labelPriceMonth}
                            footer={msgs.gui.labelUpPayment}
                            disabled={!data.prices["Mini"].canBuy && ("Mini"===data.subscription?msgs.gui.imSubscriptionNoExtend:msgs.gui.imSubscriptionNotAvailable)}
                            onBuy={() => {
                                setBuyDialog(<BuyDialog
                                    title={msgs.gui.menuInvoiceMonitoringSubscription+' '+msgs.gui.imSubscriptionMini}
                                    packet={data.prices["Mini"]}
                                    onBuy={handlePay}
                                    onCancel={() => setBuyDialog(null)}
                                />);
                            }}
                        >
                            <b>{msgs.gui.imSubscriptionMiniCount}</b><br/>
                            {msgs.gui.imSubscriptionMonthly}
                        </SubscriptionPacket>
                        <SubscriptionPacket
                            title={msgs.gui.imSubscriptionMidi}
                            subtitle={formatString(msgs.gui.labelRecommendedContractors, 40)}
                            price={data.prices["Midi"].price}
                            priceInfo={msgs.gui.labelPriceMonth}
                            footer={msgs.gui.labelUpPayment}
                            disabled={!data.prices["Midi"].canBuy && ("Midi"===data.subscription?msgs.gui.imSubscriptionNoExtend:msgs.gui.imSubscriptionNotAvailable)}
                            onBuy={() => {
                                setBuyDialog(<BuyDialog
                                    title={msgs.gui.menuInvoiceMonitoringSubscription+' '+msgs.gui.imSubscriptionMidi}
                                    packet={data.prices["Midi"]}
                                    onBuy={handlePay}
                                    onCancel={() => setBuyDialog(null)}
                                />);
                            }}
                        >
                            <b>{msgs.gui.imSubscriptionMidiCount}</b><br/>
                            {msgs.gui.imSubscriptionMonthly}
                        </SubscriptionPacket>
                        <SubscriptionPacket
                            title={msgs.gui.imSubscriptionMaxi}
                            subtitle={formatString(msgs.gui.labelRecommendedContractors, 100)}
                            price={data.prices["Maxi"].price}
                            priceInfo={msgs.gui.labelPriceMonth}
                            footer={msgs.gui.labelUpPayment}
                            disabled={!data.prices["Maxi"].canBuy && ("Maxi"===data.subscription?msgs.gui.imSubscriptionNoExtend:msgs.gui.imSubscriptionNotAvailable)}
                            onBuy={() => {
                                setBuyDialog(<BuyDialog
                                    title={msgs.gui.menuInvoiceMonitoringSubscription+' '+msgs.gui.imSubscriptionMaxi}
                                    packet={data.prices["Maxi"]}
                                    onBuy={handlePay}
                                    onCancel={() => setBuyDialog(null)}
                                />);
                            }}
                        >
                            <b>{msgs.gui.imSubscriptionMaxiCount}</b><br/>
                            {msgs.gui.imSubscriptionMonthly}
                        </SubscriptionPacket>
                        <SubscriptionPacket
                            onBuy={false}
                            title={msgs.gui.imSubscriptionCorporate}
                            subtitle={msgs.gui.labelCorporateContractors}
                            footer={msgs.gui.labelCorporatePayment}
                            price={<>
                                <p>{msgs.gui.imSubscriptionCorporateEmailPrice}</p>
                                <p>{msgs.gui.imSubscriptionCorporateSmsPrice}</p>
                            </>}
                        >
                            <a href={msgs.links.imCorporate}>{msgs.gui.imSubscriptionCorporateContact}</a>
                        </SubscriptionPacket>
                    </div>
                    <p className="text-right text-info">{msgs.gui.labelPricesInfo}</p>
                    <p className="text-info">{msgs.gui.labelSubscriptionInfo}</p>
                    <p className="text-info">{msgs.gui.labelPaymentInfo} <img alt="Logo dotpay"
                                                                              src="/resources/img/p24_logo.png"/></p>
                </Card.Body>
            </Card>
            <Card className="mt-5">
                <Card.Body>
                    <h3>{msgs.gui.labelInvoiceMonitoringPackets}</h3>
                    <p className="text-info">{msgs.gui.labelInvoiceMonitoringPacketsInfo}</p>
                    <div className="extra-packets">
                        <ExtraPacket
                            title={msgs.gui.imPacketSMS} price={data.prices["ExtraSms"].price}
                            priceInfo={msgs.gui.labelPricePacket}
                            disabled={!data.prices["ExtraSms"].canBuy && msgs.gui.imPacketNotAvailable}
                            onBuy={() => {
                                setBuyDialog(<BuyDialog
                                    title={msgs.gui.imPacketSMS}
                                    packet={data.prices["ExtraSms"]}
                                    onBuy={handlePay}
                                    onCancel={() => setBuyDialog(null)}
                                />);
                            }}
                        >
                            <b>{msgs.gui.imPacketSMSDetails}</b>
                        </ExtraPacket>
                        <ExtraPacket
                            title={msgs.gui.imPacketMail} price={data.prices["ExtraMail"].price}
                            priceInfo={msgs.gui.labelPricePacket}
                            disabled={!data.prices["ExtraMail"].canBuy && msgs.gui.imPacketNotAvailable}
                            onBuy={() => {
                                setBuyDialog(<BuyDialog
                                    title={msgs.gui.imPacketMail}
                                    packet={data.prices["ExtraMail"]}
                                    onBuy={handlePay}
                                    onCancel={() => setBuyDialog(null)}
                                />);
                            }}
                        >
                            <b>{msgs.gui.imPacketMailDetails}</b>
                        </ExtraPacket>
                        <ExtraPacket
                            title={msgs.gui.imPacketSMSMail} price={data.prices["ExtraSmsMail"].price}
                            priceInfo={msgs.gui.labelPricePacket}
                            disabled={!data.prices["ExtraSmsMail"].canBuy && msgs.gui.imPacketNotAvailable}
                            onBuy={() => {
                                setBuyDialog(<BuyDialog
                                    title={msgs.gui.imPacketSMSMail}
                                    packet={data.prices["ExtraSmsMail"]}
                                    onBuy={handlePay}
                                    onCancel={() => setBuyDialog(null)}
                                />);
                            }}
                        >
                            <b>{msgs.gui.imPacketSMSMailDetails}</b>
                        </ExtraPacket>

                    </div>
                    <p className="text-right text-info">{msgs.gui.labelPricesInfo}</p>
                    <p className="text-info">{msgs.gui.labelInvoiceMonitoringPacketsInfo2}</p>
                    <p className="text-info">{msgs.gui.labelPaymentInfo} <img alt="Logo dotpay"
                                                                              src="/resources/img/p24_logo.png"/></p>
                </Card.Body>
            </Card>
        </>}
    </>;
}

InvoiceMonitoringSubscriptionScreen.url="/im/subscription";
InvoiceMonitoringSubscriptionScreen.link = () => InvoiceMonitoringSubscriptionScreen.url;
export default InvoiceMonitoringSubscriptionScreen;

export const InvoiceMonitoringSubscriptionDoneScreen = () => {
    const msgs = useMsgs();
    const q = useRpcQuery<InvoiceMonitoringSubscription>("/user", "getPaymentStatus")
    if(q.isLoading) return null;
    if(q.data===null) return <Redirect to="/" push={false}/>;   // nie ma żadnej płatności

    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.menuInvoiceMonitoringSubscription}</BreadcrumbItem>
            <BreadcrumbItem
                to={InvoiceMonitoringDesktopScreen.link()}>{msgs.gui.menuInvoiceMonitoringDesktop}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.gui.menuInvoiceMonitoringSubscription}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.gui.menuInvoiceMonitoringSubscription}/>
        <Card className="mb-4">
            <Card.Body>
                <p className="mb-5">{msgs.gui['paymentStatus'+q.data]}</p>
                {msgs.gui.paymentStatusGotoSubscription && <p><LangLink to={InvoiceMonitoringSubscriptionScreen.link()}>{msgs.gui.paymentStatusGotoSubscription}</LangLink></p>}
                {msgs.gui.paymentStatusGotoHistory && <p><LangLink to={InvoiceMonitoringSubscriptionHistoryScreen.link()}>{msgs.gui.paymentStatusGotoHistory}</LangLink></p>}
            </Card.Body>
        </Card>
    </>
}
InvoiceMonitoringSubscriptionDoneScreen.url="/im/subscription_done";
InvoiceMonitoringSubscriptionDoneScreen.link = () => InvoiceMonitoringSubscriptionScreen.url;
