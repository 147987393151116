//@flow
import React, {createRef, useContext, useRef, useState} from 'react';
import type {ErrorResult, ValidationRules} from "./Validation";
import Validate from "./Validation";
import {FormProps} from "react-bootstrap/Form";
import {FieldHelperProps, FieldInputProps, Form as FForm, Formik, FormikConfig, FormikHelpers, FormikProps, FormikValues, useField, useFormikContext} from "formik";
import {Button, ButtonProps, Col, Form as BForm, FormLabelProps, InputGroup} from "react-bootstrap";
import {CombinedNIPInput, CountryInput, ErrorMessage, reactSelectStyleProxy, SaveButton, StatusInput} from "./Components";
import {currentLang, getLangValue, getMessage, updateKey, useMsgs} from "./Language";
import type {Language} from "./Language";
import DatePicker from "react-datepicker";
import {formatJSON, parseDate, TimeSelect} from "./DateTime";
import {arrayToggleItem, parseMoney} from "./Utils";
import {SingleFileInput} from "./Upload";
import type {SingleFileInputProps} from "./Upload";
import ReactSelect from 'react-select'
import type {Option} from "../api";
import { getOptionLabel as utilsGetOptionLabel, getOptionValue as utilsGetOptionValue } from "./Utils";

/** Definicja typu dla kontekstu formularza */
export type FormContextType = {
    name: string;
    validate: ValidationRules;
    /** Czy formularz tylko do odczytu */
    readonly: boolean;
}
/** Kontekst całego formularza */
export const FormContext=React.createContext<FormContextType>({});
FormContext.displayName="FormContext";

/** Definicja typu dla kontekstu pola w formularzu */
export type GroupContextType = {
    name: string;
}
/** Kontekst dla pojedynczego pola w formularzu */
export const GroupContext=React.createContext<GroupContextType>({});
GroupContext.displayName="FormGroupContext";

type MainFormErrorType = {
    saved: boolean;
    error: string|null;
    setError: (newError: string|null) => void;
}
const MainFormErrorContext=React.createContext<MainFormErrorType>({});
MainFormErrorContext.displayName="MainFormErrorContext";

type FormState = {
    errorContext: MainFormErrorType;
}

/** Kontekst dla pół wielojęzykowych */
export const MultiLanguageContext= React.createContext<Language>(null);
MultiLanguageContext.displayName=MultiLanguageContext;

type _FormProps = FormProps & FormikConfig<FormikValues> & {
    name?: string;
    validate?: ValidationRules;
    /** Dodatkowa funkcja sprawdzająca dane */
    customValidation?: (values: FormikValues) => {},
    /** Gdy jest ustawione, to dodawany jest kontekst językowy */
    lang?: Language;
    formikRef?: FormikProps;
    /** Czy formularz tylko do odczytu */
    readonly?: boolean;
    children: (formik: FormikProps) => React$Node;
}

const DatePickerInput=React.forwardRef((props, ref) => (
    <InputGroup>
        <BForm.Control type="text" ref={ref} {...props}/>
        <InputGroup.Append>
            <span className="input-group-text"><span className="icon-calendar"/> </span>
        </InputGroup.Append>
    </InputGroup>
));

/**
 * Opakowanie dla Bootstrapowego Form, które ustawia kontekst dla pozostałych komponentów
 * oraz podpina formika.
 */
export class Form extends React.Component<_FormProps, FormState> {
    /** Kontekst dla formularza */
    ctx: FormContextType;
    /** Funkcja walidacji */
    validate: (values: {}) => {};

    constructor(props) {
        super(props);
        this.ctx={
            validate: this.props.validate || {},
            name: this.props.name,
            readonly: this.props.readonly===true,
        };
        if(!this.props.readonly) {
            if(typeof(this.props.validate)==='function') {
                this.validate=this.props.validate;
            } else if (typeof (this.props.validate) === 'object') {
                this.validate = (values: {}) => {
                    // console.log("Validate values: ", values);
                    let custom = null;
                    if (typeof (this.props.customValidation) === 'function') {
                        custom = this.props.customValidation(values);
                    }
                    if (custom) return {...custom, ...Validate.validate(values, this.props.validate)};
                    return Validate.validate(values, this.props.validate);
                }
            } else if(typeof(this.props.customValidation)==='function') {
                this.validate=this.props.customValidation;
            }
        }
        this.state={
            errorContext: {
                error: null,
                setError: (newError) => this.handleSetError(newError),
                saved: false,
            }
        }
    }

    handleSetError(newError: string|null): void {
        if(this.state.errorContext.error!==newError) {
            this.setState({
                errorContext: {
                    error: newError,
                    setError: (newError) => this.handleSetError(newError),
                    saved: false,
                }
            });
        }
    }

    render() {
        const { children, inline, validated, className, style, validate, formikRef, onSubmit, readonly, ...props } = this.props;
        return <FormContext.Provider value={this.ctx}>
            <MultiLanguageContext.Provider value={this.props.lang || "pl"}>
                <MainFormErrorContext.Provider value={this.state.errorContext}>
                    <Formik
                        validate={this.validate}
                        innerRef={formikRef}
                        onSubmit={readonly?null:async (values, helpers) => {
                            let res=await onSubmit(values, {...helpers, setMainError: (newError) => this.handleSetError(newError)});
                            // console.log("Form submit result: ", res);
                            if(res===true) {
                                this.setState({
                                    errorContext: {
                                        setError: (newError) => this.handleSetError(newError),
                                        error: null,
                                        saved: true,
                                    }
                                })
                            }
                        } }
                        {...props}
                    >{ (formikProps) => (
                        <FForm
                            onReset={formikProps.handleReset}
                            onSubmit={formikProps.handleSubmit} inline={inline}
                            className={className}
                            // validated={true}
                            style={style}
                        >
                            {typeof(children)==='function'?children(formikProps):children}
                        </FForm>
                    )}</Formik>
                </MainFormErrorContext.Provider>
            </MultiLanguageContext.Provider>
        </FormContext.Provider>;
    }

    /** Formularz z Bootstrapa */
    static Row=BForm.Row;
    /** Grupa dla pola - tworzy grupę Bootsrapową oraz ustawia kontekst grupy */
    static Group=({children, controlId, name, ...props}) => {
        if(typeof(name)!=='string') throw new Error("Missing group name");
        const context=React.useMemo(() => ({ name }), [ name ]);
        if(typeof(controlId)!=='string') controlId=name;
        return <BForm.Group controlId={controlId} {...props}>
            <GroupContext.Provider value={context}>{children}</GroupContext.Provider>
        </BForm.Group>;
    };

    /** Nakładka na Bootstrapowy Form.Control, który używa danych z formika oraz z group
     * oraz dodaje pole do wyświetlania błędu */
    static Control=({children, noError, readonly, fieldName, ...props}) => {
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ field, meta ] = useField(fieldName || context.name);
        const error= meta.error && meta.touched;
        return <>
            <BForm.Control
                className={error?"is-invalid ":""+(props.className || "")}
                readOnly={fc.readonly || readonly}
                disabled={fc.readonly || readonly}
                {...field}
                {...props}>{children}</BForm.Control>
            {(!noError && error)?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </>
    };

    static Number=({ float, step, min, max, noError, fieldName, ...props} : {
        step?: number; min?: number; max?: number;
        float?: boolean;
        fieldName?: string;
    }) => {
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ { value, onChange, ...field }, meta, helpers ] = useField(fieldName || context.name);
        const error=meta.error && meta.touched;
        return <>
            <BForm.Control
                type="number"
                readOnly={fc.readonly}
                className={error?"is-invalid ":""+(props.className || "")}
                value={typeof(value)==='number'?value:""}
                step={typeof(step)!=='undefined'?step:(float?undefined:1)}
                min={min}
                max={max}
                onChange={(e) => {
                    if(e.target.value==="") helpers.setValue(null);
                    else if(float) helpers.setValue(parseFloat(e.target.value));
                    else helpers.setValue(parseInt(e.target.value));
                }}
                {...field}
                {...props}/>
            {(!noError && error)?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </>
    }

    static BigNumber=({ step, min, max, noError, fieldName, asBigNumber, ...props} : {
        step?: number; min?: number; max?: number;
        fieldName?: string;
        asBigNumber?: boolean;
    }) => {
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ { value, onChange, ...field }, meta, helpers ] = useField(fieldName || context.name);
        const error=meta.error && meta.touched;
        const val=parseMoney(meta.value);


        return <>
            <BForm.Control
                type="number"
                readOnly={fc.readonly}
                className={error?"is-invalid ":""+(props.className || "")}
                value={val===null?"":val.toPrecision()}
                step={step}
                onChange={(e) => {
                    if(e.target.value==="") helpers.setValue(null);
                    const p=parseMoney(e.target.value);
                    if(asBigNumber) helpers.setValue(p);
                    else {
                        if(p===null) helpers.setValue(null);
                        else helpers.setValue(p.toPrecision());
                    }
                }}
                {...field}
                {...props}/>
            {(!noError && error)?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </>
    }

    static Error=({ fieldName }: { fieldName?: string }) => {
        const context=useContext<GroupContextType>(GroupContext);
        const [ , meta, ] = useField(fieldName || context.name);
        if(Array.isArray(meta.error)) return null;
        const error=meta.error && meta.touched;
        if(error) return <BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>;
        return null;
    }

    static StatusSelect = ({ ...props }) => {
        // const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ { value }, meta, helpers ] = useField(context.name);
        const error=meta.error && meta.touched;
        return <>
            <StatusInput value={value} onChange={(status) => helpers.setValue(status)} {...props}/>
            {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </>;
    }

    /** Specjalny rodzaj pola, który działa przy współpracy z MultilLanguageContext i obsługuje pola LangString */
    static MultiLanguageTextInput = ({ children, lang, ...props }: { lang?: Language }) => {
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        if(lang===null || lang===undefined) lang=useContext<Language>(MultiLanguageContext);
        const [ field: FieldInputProps, meta, helpers: FieldHelperProps ] = useField(context.name);
        const error=meta.error && meta.touched;
        return <>
            <BForm.Control
                type="text"
                readOnly={fc.readonly}
                className={error?"is-invalid ":""+(props.className || "")}
                value={meta.value?(meta.value[lang]||''):''}
                onChange={(e) => helpers.setValue(updateKey(meta.value, lang, e.target.value) )}
                onBlur={field.onBlur}
                {...props}
            >{children}</BForm.Control>
            {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </>
    }

    static MultiSelect = ({ options, className, getOptionValue, getOptionLabel, readonly, ...props }) => {
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ { value, onChange, ...field }, meta, helpers ] = useField(context.name);
        const error=meta.error && meta.touched;
        if(!getOptionValue) getOptionValue=utilsGetOptionValue;
        if(!getOptionLabel) getOptionLabel=utilsGetOptionLabel;
        const msgs=useMsgs();
        return <>
            <ReactSelect
                className="react-select country-select"
                classNamePrefix="react-select"
                styles={reactSelectStyleProxy}
                // isReadOnly={fc.readonly}
                isDisabled={fc.readonly || readonly}
                isClearable={!(fc.readonly || readonly)}
                // className={"form-control "+(error?"is-invalid ":"")+(props.className || "")}
                isMulti={true}
                options={options}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                value={value?value.map(v => options.find(o => getOptionValue(o)===v)):null}
                onChange={sel => helpers.setValue(sel?sel.map(i => getOptionValue(i)):null)}
                noOptionsMessage={() => msgs.gui.comboboxNoOptions}
                placeholder=""
                {...field}
                {...props}
            />
            {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </>
    }

    static Date = ({className, ...props}) => {
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ field, meta, helpers ] = useField(context.name);
        const error=meta.error && meta.touched;
        // console.log("Date: ", error, field);
        return <>
            <DatePicker
                className={error?"is-invalid ":""+(className || "")}
                disabled={fc.readonly}
                customInput={<DatePickerInput/>}
                dateFormat='yyyy-MM-dd'
                selected={parseDate(meta.value)}
                onChange={(date) => helpers.setValue(formatJSON(date))}
                onBlur={field.onBlur}
                locale={currentLang.dateFns}
                {...props}
            />
            {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </>
    }

    static DayTime = ({className, fieldName, ...props}) => {
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ field, meta, helpers ] = useField(fieldName || context.name);
        const error=meta.error && meta.touched;
        // console.log("DayTime: ", meta);
        return <>
            <TimeSelect
                className={error?"is-invalid ":""+(className || "")}
                disabled={fc.readonly}
                value={meta.value}
                onChange={(time) => helpers.setValue(time)}
                onBlur={field.onBlur}
                {...props}
            />
            {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </>
    }

    /** Nakładka na Bootstrapowy Checkbox z obsługa formika i groupy oraz wyświetlaniem błędów */
    static Check = ({id, children, ...props}) => {
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ field, meta ] = useField({
            name: context.name,
            type: "checkbox",
        });
        const error=meta.error && meta.touched;
        return <BForm.Check id={id || context.name} custom {...props}>
            <BForm.Check.Input isInvalid={error} type="checkbox" readOnly={fc.readonly} disabled={fc.readonly} {...field}/>
            <BForm.Check.Label>{children}</BForm.Check.Label>
            {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </BForm.Check>;
    };

    /** Nakładka na Bootstrapowy Checkbox z obsługa formika i groupy oraz wyświetlaniem błędów */
    static Radio = ({id, children, value, disabled, ...props}) => {
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ field, meta, helpers ] = useField({
            name: context.name,
            type: "radio",
            value,
        });
        const error=meta.error && meta.touched;
        const fDisabled=typeof(disabled)==='boolean'?disabled:fc.readonly;

        return <BForm.Check id={id || context.name} custom {...props}>
            <BForm.Check.Input isInvalid={error} type="radio" readOnly={fDisabled} disabled={fDisabled}
                               checked={meta.value===value}
                               onChange={() => helpers.setValue(value) }
            />
            <BForm.Check.Label>{children}</BForm.Check.Label>
            {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </BForm.Check>;
    };

    static Switch = ({id, children, ...props}) => {
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ field, meta ] = useField({
            name: context.name,
            type: "checkbox",
        });
        const error=meta.error && meta.touched;
        return <BForm.Switch id={id || context.name} custom {...props}>
            <BForm.Check.Input isInvalid={error} type="checkbox" readOnly={fc.readonly} disabled={fc.readonly} {...field}/>
            <BForm.Check.Label>{children}</BForm.Check.Label>
            {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </BForm.Switch>;

    }

    /** Komponent, który łączy select + checkboxem */
    static NullableSelect = ({ id, label, children, checkboxClassName, checkboxLabelClassName, ...props }) => {
        const context=useContext<GroupContextType>(GroupContext);
        const fc=useContext<FormContextType>(FormContext);
        const [ field, meta, helper ] = useField(context.name);
        const error=meta.error && meta.touched;
        const [ notNull, setNotNull ] = useState(field.value!==null);
        const [ value, setValue ] = useState(field.value || "");
        const selectRef=useRef();

        return children({
            checkbox: () => <BForm.Check id={id || (context.name+"_nuller")} custom className={checkboxClassName}>
                    <BForm.Check.Input
                        type="checkbox" readOnly={fc.readonly} disabled={fc.readonly}
                        value={notNull}
                        onChange={(e) => {
                            const nv=e.target.checked;
                            setNotNull(nv);
                            if(!nv) helper.setValue(null);
                            else {
                                const sv=selectRef.current.value;
                                helper.setValue(sv);
                            }
                        }}
                    />
                    <BForm.Check.Label className={checkboxLabelClassName}>{label}</BForm.Check.Label>
                </BForm.Check>,
            select: (content) => <><BForm.Control
                custom
                ref={selectRef}
                as="select"
                className={error?"is-invalid ":""+(props.className || "")}
                readOnly={fc.readonly || !notNull}
                disabled={fc.readonly || !notNull}
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    field.onChange(e);
                }}
                onBlur={field.onBlur}
                {...props}>{content}</BForm.Control>
                {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}</>,
        })
    }

    static FlagCheck = ({id, children, flag, ...props}) => {
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ , meta, helpers ] = useField(context.name);
        const error=meta.error && meta.touched;
        return <BForm.Check id={id || context.name} custom {...props}>
            <BForm.Check.Input
                isInvalid={error}
                type="checkbox"
                disabled={fc.readonly}
                checked={meta.value.includes(flag)}
                onChange={(e) => {
                    helpers.setValue(arrayToggleItem(meta.value, flag));
                }}
            />
            <BForm.Check.Label>{children}</BForm.Check.Label>
            {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </BForm.Check>;
    }

    /** Przekombinowany komponent do pola hasła */
    static Password = ({checkStrength, onVisibilityChange, toggleVisibility, ...props}: {
        checkStrength?: boolean,
        onVisibilityChange?: (visible: boolean) => void,
        toggleVisibility?: boolean
    }) => {
        const [ visible, setVisible ]=useState(false);
        const [ changed, setChanged ]=useState(false);
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ field, meta ] = useField(context.name);
        const error=meta.error && meta.touched;
        let ref=createRef();
        let strength=(checkStrength===true)?Validate.passwordCheck(field.value):-1;
        return <>
            <BForm.Control
                ref={ref}
                as="input"
                readOnly={fc.readonly}
                type="password"
                isInvalid={error}
                {...field} {...props}
                onChange={(e) => { setChanged(true); field.onChange(e); }}
            />
            {toggleVisibility===false?null:<span
                className={"clickable fa fa-fw field-icon "+(visible?"fa-eye-slash":"fa-eye")}
                onClick={() => {
                    if(visible===false) ref.current.setAttribute("type", "text");
                    else ref.current.setAttribute("type", "password");
                    setVisible(!visible);
                    if(typeof(onVisibilityChange)==='function') onVisibilityChange(!visible);
                }}
            />}
            {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:(
                !checkStrength?null:((changed && strength>=0)?
                <span className={"password-strength "+Validate.passwordVariant(strength)}>{Validate.passwordMessage(strength)}</span>
                    :<span className="password-strength">&nbsp;</span>))}
        </>;
    }

    static NIP = ({value, onChange, readonly, ...props}) => {
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ field, meta ] = useField(context.name);
        const error=meta.error && meta.touched;
        // console.log("NIP: ", error, field);
        return <>
            <CombinedNIPInput readonly={fc.readonly || readonly} {...field} {...props} isInvalid={error}/>
            {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </>
    }

    static Country = ({ fieldName, ...props}) => {
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ field, meta ] = useField(fieldName || context.name);
        const error=meta.error && meta.touched;
        return <>
            <CountryInput
                className={error?"is-invalid ":""+(props.className || "")}
                readonly={fc.readonly}
                {...field} {...props}
            />
            {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </>

    }

    static FileInput = (props: $Diff<SingleFileInputProps, { value: string, onChange: (v: string) => void }>) => {
        const fc=useContext<FormContextType>(FormContext);
        const context=useContext<GroupContextType>(GroupContext);
        const [ , meta, helpers ] = useField(context.name);
        const error=meta.error && meta.touched;
        return <>
             <SingleFileInput
                value={meta.value}
                readonly={fc.readonly}
                onChange={(fid) => helpers.setValue(fid)}
                className={error?"is-invalid":null}
                {...props}
             />
            {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
        </>
    }

    /** Skrót dla group, która jednoczenie jest wierszem dla grid layout */
    static RowGroup=({children, ...props}) => <Form.Group as={BForm.Row} {...props}>{children}</Form.Group>;
    static ColGroup=(props) => <Form.Group as={Col} {...props}/>
    /** Nakładka do form label, która dodaje obsługę dla walidacji */
    static Label=({children, ...props}: FormLabelProps) => {
        const formContext=useContext<FormContextType>(FormContext);
        const groupContext=useContext<GroupContextType>(GroupContext);
        const req=Validate.isRequired(formContext.validate[groupContext.name]);

        return <BForm.Label {...props}>{children}{req?"*":null}</BForm.Label>
    }
    /** Nakładka na submit z obsługą formika */
    static Submit= ({children, size, ...props }: ButtonProps) => {
        const formik = useFormikContext();
        return <Button type="submit" size={size || "lg"} {...props} onClick={formik.handleSubmit}>{children}</Button>;
    };

    static Save= ({ children, state, ...props }: ButtonProps) => {
        const formik=useFormikContext();
        const error=useContext(MainFormErrorContext);
        return <SaveButton
            type="submit"
            onClick={formik.handleSubmit}
            state={formik.isSubmitting?"loading":(error.saved?"done":state)}
            {...props}
        >{children}</SaveButton>;
    }

    static Text = Form.Control;
    static TextArea = ({...props}) => <Form.Control as="textarea" {...props}/>;
    static Email = ({...props}) => <Form.Control type="email" {...props}/>;
    static PostalCode = Form.Control;
    static Phone = ({...props}) => <Form.Control type="phone" {...props}/>;
    static Select = ({children, ...props}) => <Form.Control custom as="select" {...props}>{children}</Form.Control>
    static OptionSelect = ({children, ...props} : { children: Array<Option> }) => <Form.Control custom as="select" {...props}>{
        children.map((o: Option) => <option value={o.value}>{o.label}</option>)
    }</Form.Control>
    static LangOptionSelect = ({children, ...props} : { children: Array<LangOption> }) => <Form.Control custom as="select" {...props}>{
        children.map((o: Option) => <option value={o.value}>{getLangValue(o.label)}</option>)
    }</Form.Control>
    static PositiveNumber = ({...props}) => <Form.Control
        type="number" min={0}
        {...props}/>


    /** Komponent do wyświetlania błędu ogólnego dla formularza */
    static FormError = () => {
        const error=useContext(MainFormErrorContext);
        return <ErrorMessage message={error.error}/>;
    }
    /** Komponent do wyświetlania błędu ogólnego dla formularza w układzie grid layout */
    static RowFormError = () => <Form.Row>
        <Col md={12} className="text-center"><Form.FormError/></Col>
    </Form.Row>;

    /**
     * Funkcja ustawiająca pola błędów w formiku
     * @param helpers obiekt formika z onSubmit
     * @param res wynik z serwera typu ErrorResult
     * @param status opcjonalna nazwa dla pola z głównym błędem; gdy null nie będzie ustawiany błąd dla pola głównego
     * @return {boolean} czy res był obiektem błędu
     */
    static setError = (helpers: FormikHelpers, res: ErrorResult|any): boolean => {
        if(res===null || res===undefined || typeof(res)!=='object' || res.error!==true) return false;    // brak błędu
        // główny błąd
        if(typeof(res.main)==='string') {
            console.log("Main error: ", res.main);
            helpers.setMainError(res.main);
        }
        // błędy dla pól
        if(typeof(res.fields)==='object') {
            console.log("Field errors: ", res.fields);
            helpers.setErrors(res.fields);
        }
        return true;
    }

}

export { Form as BForm } from 'react-bootstrap';