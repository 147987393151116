//@flow
import React, {useCallback, useState} from 'react';
import type {ImportResult, InvoiceMonitoringImportClient, InvoiceMonitoringImportInvoice} from "../api";
import msgs, {formatString, useMsgs} from "./Language";
import {NetworkDataProcessor, RequestError} from "./Network";
import {BForm} from "./Form";
import {closeDialog, Dialog, IconAlert, waitGlass} from "./Components";
import {DefaultDropzone} from "./Upload";
import {Alert, Button, Table} from "react-bootstrap";
import BigNumber from "bignumber.js";
import {formatMoney, parseMoney, stringCompare, stringToLower} from "./Utils";
import {store} from "../application";
import {useHistory, useLocation} from "react-router-dom";
import {emitEvent} from "./Events";

type InvoiceImportType = {
    /** Źródłowy numer wiersza */
    $row: number;
    Number: string;
    DebtorName: string;
    Amount: number;
    Currency: string;
    SellDate: string;
    PaymentDate: string;
    NIP: string;
    Address: string;
    PostalCode: string;
    City: string;
    Country: string;
    Email: string;
    Phone: string;
}

type InvoiceMonitoringImportClientStats = $Diff<InvoiceMonitoringImportClient, { invoices: Array }> & {

}

/**
 * Okno importu faktur z pliku.
 */
export const ImportInvoicesForm = ({ onReady }: {
    onReady: (invoices: Array<InvoiceMonitoringImportClient>) => void;
}) => {
    const [ stage, setState ] = useState<0|1>(0);
    const [ importFileName, setImportFileName ] = useState();
    const [ clients, setClients ] = useState<null|Array<InvoiceMonitoringImportClient>>(null);
    const [ importErrors, setImportErrors ] = useState();
    const msgs=useMsgs();
    const handleFileDrop = useCallback(async(file: FileList) => {
        if(file.length!==1) return;
        const wg=waitGlass();
        try {
            let importer=new NetworkDataProcessor("invoices", "invoices");
            const res: ImportResult = await importer.upload(file[0]);
            const items: Array<InvoiceImportType> = res.data;
            let clients:Map<string, InvoiceMonitoringImportClient>=new Map();
            console.log("Import res: ", res);
            let errors=[];
            let checkMap:Map<string, Set<string> >=new Map();
            for(let i=0;i<items.length;++i) {
                const row: InvoiceImportType=items[i];
                const er={
                    row: row.$row,
                    errors: [],
                }
                if(!row.NIP) er.errors.push(msgs.gui.importInvalidTax);
                if(!row.DebtorName) er.errors.push(msgs.gui.importInvalidContractor);

                if(typeof(row.Number)==='number') row.Number=row.Number.toString();
                else if(typeof(row.Number)!=='string') row.Number="";
                else row.Number=row.Number.trim();

                if(!row.Number) er.errors.push(msgs.gui.importInvalidNumber);
                if(!row.SellDate) er.errors.push(msgs.gui.importInvalidSellDate);
                if(!row.PaymentDate) er.errors.push(msgs.gui.importInvalidPayDate);
                if(!row.Amount) er.errors.push(msgs.gui.importInvalidAmount);
                else {
                    let v=parseMoney(row.Amount);
                    if(!v || v.isZero()) er.errors.push(msgs.gui.importInvalidAmount);
                }
                if(row.SellDate && row.PaymentDate && row.PaymentDate<row.SellDate) er.errors.push(msgs.gui.importInvalidSellPayDate);

                let c:InvoiceMonitoringImportClient=clients.get(row.NIP);
                if(!c) {    // nowy klient
                    c={
                        tax: row.NIP,
                        city: row.City,
                        postalCode: row.PostalCode,
                        country: row.Country || "PL",
                        address: row.Address,
                        name: row.DebtorName,
                        email: row.Email,
                        phone: row.Phone,
                        invoices: []
                    }
                    clients.set(row.NIP, c);
                    checkMap.set(row.NIP, new Set());
                } else {    // aktualizacja?
                    if(!c.city) c.city=row.City;
                    if(!c.postalCode) c.postalCode=row.PostalCode;
                    if(!c.address) c.address=row.Address;
                    if(!c.name) c.name=row.DebtorName;
                    if(!c.email) c.email=row.Email;
                    if(!c.phone) c.phone=row.Phone;
                }
                c.invoices.push({
                    currency: row.Currency || "PLN",
                    number: row.Number,
                    amount: row.Amount,
                    sell: row.SellDate,
                    payment: row.PaymentDate
                });
                if(row.NIP && row.Number) { // aby sprawdzać kolizje numerów faktur, musi być podany NIP oraz numer faktury.
                    let cm = checkMap.get(row.NIP);
                    if (!cm.has(row.Number)) cm.add(row.Number);
                    else er.errors.push(msgs.gui.importInvalidMultipleInvoices);
                }

                if(er.errors.length>0) errors.push(er);
            }
            let d=Array.from(clients.values());
            d.sort((c1: InvoiceMonitoringImportClient, c2: InvoiceMonitoringImportClient) =>  stringCompare(stringToLower(c1.name), stringToLower(c2.name)));
            setClients(d);
            setImportFileName(file[0].name);
            if(errors.length>0) {
                setImportErrors(errors);
            } else {
                setState(1);
                onReady(d);
            }

        } catch(e) {
            console.error("Import error", e);
        } finally {
            wg();
        }
    }, []);

    switch (stage) {
        case 0:
            return <BForm>
                <IconAlert className="mb-4">{msgs.gui.hintImportInvoices}</IconAlert>
                <BForm.Row>
                    <DefaultDropzone
                        onDrop={handleFileDrop}
                        multiple={false}
                        className={importErrors ? "dropzone-danger" : null}
                        accept={NetworkDataProcessor.acceptData}
                    >
                        {!importFileName ? null : <div className="file-info">
                            <span>{importFileName}</span>
                            {Array.isArray(clients) ? <span>{clients.length}</span> : null}
                        </div>}
                    </DefaultDropzone>
                    {!importErrors ? null : <Alert variant="danger" className="mt-4 w-100">
                        <h4>{formatString(msgs.gui.importInvalid, importFileName)}</h4>
                        <ul>
                            {importErrors.map((er, index) => <li key={index}>
                                <b>{formatString(msgs.gui.importInvalidRow, er.row + 1)}</b> <span>{er.errors.join(", ")}</span>
                            </li>)}
                        </ul>
                    </Alert>}

                </BForm.Row>
            </BForm>
        case 1:
            return <>
                <IconAlert>{msgs.gui.hintCheckUploadedData}</IconAlert>
                <Table>
                    <thead>
                    <tr>
                        <td>{msgs.gui.labelDebtorName}</td>
                        <td>{msgs.gui.labelNIP}</td>
                        <td>{msgs.gui.labelDebtorAddress}</td>
                        <td>{msgs.gui.labelAmount}</td>
                        <td>{msgs.gui.labelAmountOfReceivables}</td>
                    </tr>
                    </thead>
                    <tbody>
                    {clients.map((c: InvoiceMonitoringImportClient, index) => {
                        let amount=new BigNumber(0), paid=new BigNumber(0);
                        let currency;
                        for(const i: InvoiceMonitoringImportInvoice of c.invoices) {
                            currency=i.currency;    // TODO: Co, gdy różne waluty?!
                            amount=amount.plus(i.amount);
                            paid=paid.plus(i.paid);
                        }

                        return <tr key={index}>
                            <td>{c.name}</td>
                            <td>{c.tax}</td>
                            <td>{c.address}</td>
                            <td>{formatMoney(amount, currency)}</td>
                            <td>{c.invoices.length}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </>
    }
}

export const ImportInvoicesDialog = () => {
    const msgs=useMsgs();
    const history = useHistory();
    const location = useLocation();

    const [ data, setData ] = useState<null|Array<InvoiceMonitoringImportClient>>(null);
    const handleSend = useCallback(async() => {
        console.log("Send invoices: ", data);
        const res=waitGlass();
        try {
            const response=await store.userApi.uploadInvoices(data);
            closeDialog(history, location);
            if(response) {
                window.setTimeout(() => {
                    emitEvent("Error", ({
                        code: 500,
                        time: new Date(),
                        method: "UserAPI.uploadInvoices",
                        message: response,
                        payload: "",
                    }: RequestError))
                }, 10);
            }
        }finally {
            res();
        }
    }, [ data, history, location ]);
    return <Dialog
        title={msgs.gui.labelImportInvoices}
        acceptCustom={<Button variant="success" disabled={!data} onClick={handleSend}>{msgs.gui.buttonImport}</Button> }
        size="xl"
    >
        <ImportInvoicesForm
            onReady={setData}
        />
    </Dialog>
}
