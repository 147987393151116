//@flow
import React from 'react';
import {Alert, Col, Container, Row} from "react-bootstrap";
import {getLangValue, LangLink, langLink, useMsgs} from "./lib/Language";
import type {AgreementInfo} from "./api";
import type {LangInfo} from "./lib/Language";
import {IconAlert} from "./lib/Components";


export function getAgreementGroupLabel(a1: AgreementInfo, langInfo?: string|LangInfo): string|"" {
    if(!a1 || !a1.group) return "";
    const v=getLangValue(a1.group, langInfo);
    if(typeof(v)!=='string') return "";
    return v;
}

/**
 * Funkcja porównująca zgody na potrzeby ustalenia kolejności.
 */
export function agreementComparator(a1: AgreementInfo, a2: AgreementInfo, langInfo?: string|LangInfo): number {
    return a1.order-a2.order;
}

export const ServiceNotAvailable = () => {
    const msgs=useMsgs();
    return <IconAlert>{msgs.gui.serviceNotAvailableInPacket}</IconAlert>
}