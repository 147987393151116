//@flow
// Plik z komponentem wgrywania plików na serwer w oparciu o React-Dropzone
import React, {useCallback, useEffect, useState} from 'react';
import Dropzone, {useDropzone} from "react-dropzone";
import {Button, ProgressBar} from "react-bootstrap";
import msgs, {formatString, useMsgs} from "./Language";
import type {DbFileInfo} from "../api";
import {formatDateTime} from "./DateTime";
import {DropzoneProps} from "react-dropzone";
import {FileRejection} from "react-dropzone";
import {ErrorDialog} from "./Components";
import { readAndCompressImage } from 'browser-image-resizer';


/**
 * Funkcja zwraca ikonę (klasę) dla Font Awesome dla zadanego typu
 * np.: "far fa-file-alt"
 **/
export function mimeTypeToIcon(mimeType: string|null): string {
    if(typeof(mimeType)!=='string' || mimeType.length===0) return "far fa-file-alt";
    switch(mimeType) {
        case 'application/pdf':
            return "far fa-file-pdf";
        case 'audio/vorbis': case 'audio/mpeg': case 'audio/wav':
            return "far fa-file-audio";
        case 'image/jpeg': case 'image/png': case 'image/bmp':
        case 'image/gif': case 'image/tiff': case 'image/webp':
            return "far fa-file-image";
        case 'video/ogg': case 'video/mp4': case 'video/webm': case 'video/mpeg':
            return "far fa-file-video";
        case 'application/x-tar': case 'application/x-bzip': case 'application/x-bzip2':
        case 'application/vnd.rar': case 'application/7z': case 'application/zip':
            return "far fa-file-archive";
        case 'application/msword': case 'application/vnd.oasis.opendocument.text':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return "far fa-file-word";
        case 'application/vnd.oasis.opendocument.spreadsheet': case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return "far fa-file-excel";
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return "far fa-file-powerpoint";
        case 'application/xml': case 'text/xml': case 'text/html':
            return "far fa-file-code";
        case 'text/csv':
            return "far fa-file-csv";
        default:
            return "far fa-file-alt";
    }
}

export function hasPreview(type: string):  boolean {
    if(typeof(type)!=='string') return false;
    return type==="image/png" || type==="image/jpeg" || type==="image/bmp" || type==="image/tiff";
}

/**
 * Zamienia liczbę na zapis w postaci kb, mb itd.
 * @param fs rozmiar do sformatowania
 */
export function formatSize(fs: number|null): string {
    if(typeof(fs)!=='number') return "N/A";
    if(fs<1536) return fs+" B";
    if(fs<1572864) return (fs/1024).toFixed(1)+" KB";
    if(fs<1610612736) return (fs/1048576).toFixed(1)+" MB";
    return (fs/1073741824).toFixed(1)+" GB";
}

export type UploadState = "New" | "Uploading" | "Done" | "Error" | "Aborted";

export type UploadResult = {
    /** Rodzaj pliku na podstawie detekcji wykonanej przez serwer */
    mimeType: string;
    /** Id bazodanowe pliku */
    id: string;
}

/** Informacje o przesyłanym do serwera pliku */
export type UploadInfo = {
    /** Lokalny id */
    id: string;
    /** Nazwa pliku */
    filename: string;
    /** Aktualny stan przesyłania */
    state: UploadState;
    /** Komunikat */
    message: string|null;
    /** Wynik poprawnego wgrywania */
    result:? UploadResult;
    /** Rozmiar pliku */
    size: number;
    /** Ilość przesłana */
    uploaded?: number;
    /** Procentowo ilość przesłana */
    progress?: number;
    /** Funkcja do anulowania przesyłania */
    cancel: () => void;
}


export type UploadListener=(info: UploadInfo) => void;

/**
 * Funkcja wysyłająca plik do serwera.
 * @param file plik do przesłania
 * @param id lokalny id dla pliku
 * @param listener opcjonalny słuchacz podstępu
 * @return {string} id serwerowe pliku
 * @method
 */
export function upload(file: File, id: string, listener?: UploadListener): Promise<UploadResult> {
    return new Promise<string>((resolve, reject) => {
        const req=new XMLHttpRequest();
        const info: UploadInfo = {
            id: id,
            filename: file.name,
            state: "New",
            message: null,
            result: null,
            size: 0,
            cancel: () => {
                if(typeof(req.abort)==='function') req.abort();
            }
        };
        const fd=new FormData();
        fd.append("file", file);

        req.onerror=(error) => {
            info.state="Error";
            info.message=error;
            if(listener) listener({...info});
            reject(error);
        }
        req.onabort=() => {
            info.state="Aborted";
            info.message=null;
            if(listener) listener({...info});
            reject(false);
        }
        req.upload.onprogress=(event: ProgressEvent) => {
            info.state="Uploading";
            info.uploaded=event.loaded;
            info.size=event.total;
            if(event.lengthComputable) {
                info.progress=Math.floor(event.loaded/event.total);
            } else info.progress=null;
            if(listener) listener({...info});
        };
        req.onload=(event) => {
            console.log("Upload done: ", req.responseText);
            info.state="Done";
            info.progress=100;
            if(req.status!==200) {
                info.message=req.responseText;
            } else {
                info.message=null;
                info.result=JSON.parse(req.responseText);
            }

            if(listener) listener({...info});
            if(req.status===200) {
                resolve(info.result);
            } else {
                reject(req.responseText);
            }
        }

        req.open("POST", "/upload/"+id, true);
        req.send(fd);
        if(listener) listener({...info});
    });
}

export function getFileInfo(fid: string): Promise<DbFileInfo> {
    return new Promise<DbFileInfo>((resolve, reject) => {
        fetch("/file/"+fid+"/i/info").then((res: Response) => {
            if(res.status===200) {
                res.json().then(resolve).catch(reject);
            } else {
                reject(res.statusText);
            }
        }).catch(reject);
    })
}

/**
 * Pobranie rozmiaru obrazku dla pliku
 * @return {Promise<{ width: number, height: number }>}
 */
export async function getImageSize(file: File): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
        const fr=new FileReader();
        fr.onload= () => {
            const img=new Image();
            img.onload= () => {
                resolve({ width: img.width, height: img.height});
            }
            img.onerror=reject;
            img.src=fr.result;
        }
        fr.onerror=reject;
        fr.readAsDataURL(file);
    });
}

/**
 * Usunięcie wcześniej wysłanego pliku do serwera w sesji tymczasowej
 */
export function deleteUpload(file: UploadResult): Promise<void> {
    return new Promise((resolve, reject) => {
        if(!file || !file.id) {
            reject(new Error("Invalid argument"));
            return;
        }
        fetch("/upload/"+file.id, {
            method: 'DELETE'
        }).then(resolve).catch(reject);
    });
}

export type SingleFileInputProps = {
    value: string|null;
    onChange: (fileId: string|null) => void;
    /** Czy ma być podgląd */
    preview?: boolean;
    /** Czy tylko przyjmować pliki graficzne */
    imageOnly?: boolean;
    /** Czy wyświetlać informacje o pliku */
    info?: boolean;
    /** Jakie pliki przyjmować */
    accept?: string;
    className?: string;
    /** Czy ma być możliwość pobrania pliku */
    download?: boolean;
    /** Czy ma być możliwość usunięcia pliku */
    remove?: boolean;
    /** Czy pole tylko do odczytu */
    readonly?: boolean;
    /** Czy uproszczony widok */
    simple?: boolean;
    /** Maksymalny rozmiar pliku */
    maxSize?: number;
    /** Maksymalna szerokość dla obrazka */
    maxWidth?: number;
    /** Maksymalna wysokość dla obrazka */
    maxHeight?: number;
    /** Czy obrazek ma być automatycznie zmniejszony */
    downscale?: boolean;
}

export function getDownloadLink(fId: string, name?: string) {
    if(!!fId && fId.startsWith("/")) return fId;
    return "/file/"+fId+"/a/"+(name?encodeURIComponent(name):"-");
}

export function getPreviewLink(fId: string, name?: string) {
    return "/file/"+fId+"/p/"+(name?encodeURIComponent(name):"-");
}

export function getViewLink(fId: string, name?: string) {
    return "/file/"+fId+"/v/"+(name?encodeURIComponent(name):"-");
}


export function getDisplayLink(fId: string, name?: string) {
    if(!!fId && fId.startsWith("/")) return fId;
    return "/file/"+fId+"/"+(name?encodeURIComponent(name):"-");
}

export const UploadErrorDialog=({ errors, maxSize, onHide }: {
    errors: Array<FileRejection>,
    onHide: () => void,
    maxSize: number,
}) => {
    const msgs=useMsgs();
    if(!Array.isArray(errors) || errors.length===0) return null;

    return <ErrorDialog
        title={msgs.gui.uploadErrorTitle}
        onHide={onHide}
    >
        {errors.map((e: FileRejection, index) => {
            if(e.errors.length===0) return null;
            const name=e.file.name;
            const code=e.errors[0].code;
            switch (code){
                case "file-too-large":
                    return <p key={index}>{formatString(msgs.gui.uploadSizeError, name, formatSize(maxSize))}</p>;
                case "file-invalid-type":
                    return <p key={index}>{formatString(msgs.gui.uploadTypeError, name)}</p>
                default:
                    return <p key={index}>{formatString(msgs.gui.uploadOtherError, name)}</p>
            }
        })}
    </ErrorDialog>
}

/**
 * Komponent do pojedynczego wyboru pliku w oparciu o id bazodanowe
 */
export const SingleFileInput = ({
    value, onChange, imageOnly, accept, className, remove, download,
    preview, info, readonly, simple, maxSize, maxWidth, maxHeight,
    downscale
}: SingleFileInputProps) => {
    const [ fileInfo, setFileInfo ] = useState<DbFileInfo>(null);
    const [ processing, setProcessing ] = useState<UploadInfo>(null);
    const [ error, setError ] = useState(null);
    const onDrop=useCallback(async (files) => {
        if(files.length!==1 || !files[0]) {
            console.warn("Invalid argument!");
            return;
        }
        let file=files[0];
        if(processing) {
            if (processing.state === "Done" && processing.result) {
                console.log("Delete temporary upload");
                await deleteUpload(processing.result);
            } else if(processing.state === "Uploading" || processing.state==="New") {
                console.log("Cancel current upload");
                processing.cancel();
            }
        }

        if(imageOnly && typeof(maxWidth)==='number' && typeof(maxHeight)==='number' ) {
            try {
                const size = await getImageSize(file);
                if(size.width>maxWidth || size.height>maxHeight) {
                    if(downscale) {
                        let resized=await readAndCompressImage(file, {
                            maxWidth: maxWidth,
                            maxHeight: maxHeight,
                            quality: 0.81
                        });
                        resized.name=file.name;
                        file=resized;
                    } else {
                        setError(<p className="text-danger">{formatString(msgs.validation.invalidImageSize,
                            size.width, size.height, maxWidth, maxHeight
                        )}</p>)
                        return;
                    }
                }
            }catch (e) {
                console.error("Error loading image: ", e);
                setError(<p className="text-danger">{msgs.validation.invalidImage}</p> )
                return;
            }
        }
        setError(null);


        let res: UploadResult=await upload(file, "sf", (ui: UploadInfo) => setProcessing(ui));
        onChange(res.id);
    }, [ processing, onChange, imageOnly, maxWidth, maxHeight, onChange, downscale ]);
    useEffect(() => {
        if(info!==true) return ;
        if(value) {
            getFileInfo(value).then(info => {
                setFileInfo(info);
            })
        } else {
            setFileInfo(null);
        }
    }, [ value, info ]);

    const onDropRejected=useCallback((files) => {
        console.log("Files ", files, "rejected");
    })

    if(imageOnly===true) accept="image/jpeg,image/png";
    const canUpload=readonly!==true;
    const { getRootProps, getInputProps, isDragActive, open }=useDropzone({
        onDrop,
        onDropRejected,
        multiple: false,
        accept,
        noClick: true,
        disabled: !canUpload,
        maxSize: maxSize
    });
    const canRemove=remove && (readonly!==true);

    return <div {...getRootProps({
        className: "dropzone "+
            (isDragActive?"active ":"")+
            (preview!==false?"with-preview ":"")+
            (info?"with-info ":"")+
            ((remove || download)!==false?"with-actions ":"")+
            (className || "")
    })}>
        {error}
        <input {...getInputProps()}/>
        {(canRemove || download || (simple && !readonly))?<div className="file-actions">
            {canRemove?<Button disabled={!value} onClick={() => onChange(null)}>{msgs.gui.actionDelete}</Button>:null}
            {download?(value?<a href={getDownloadLink(value)}>{msgs.gui.actionDownload}</a>:<span>{msgs.gui.actionDownload}</span>):null}
            {(simple && !readonly)?<Button variant="outline-secondary" disabled={readonly}
                            onClick={(e: SyntheticEvent) => { e.stopPropagation(); open() }}
            >{msgs.gui.actionAddFile}</Button>:null}
        </div>:null}
        {info?<div className="file-info">
            {fileInfo?<>
                <span>{fileInfo.name}</span>
                <span>{formatSize(fileInfo.size)}</span>
                <span>{formatDateTime(fileInfo.added)}</span>
            </>:<span>&nbsp;</span>}
        </div>:null}
        {preview!==false?<div className="preview">
            {(processing && processing.state!=="Done")?<ProgressBar
                value={processing.progress}
                max={100}
            />:(value?<img src={"/file/"+value+"/p/preview.png"} alt="Podgląd"/>:"N/A")}
        </div>:null}
        {!simple?<div>
            <Button
                size="xl"
                variant="outline-secondary"
                disabled={readonly}
                onClick={(e: SyntheticEvent) => { e.stopPropagation(); open() }}
            >{msgs.gui.actionAddFile}</Button>
            <p className={readonly?"text-muted":null} dangerouslySetInnerHTML={{ __html: msgs.gui.hintUpload }}/>
        </div>:null}
    </div>
}

/**
 * Nakładka na Dropzone, która daje wygląd domyślny dla aplikacji formularza do wrzucania plików
 */
export const DefaultDropzone = ({ children, className, buttonLabel, hintMessage, ...props }
: DropzoneProps & {
    buttonLabel?: string;
    hintMessage?: string;
    className?: string;
}) => {
    const msgs=useMsgs();
    const [ errors, setErrors ]=useState<Array<FileRejection>>(null);
    return <>
        <Dropzone
            onDropRejected={(errors) => setErrors(errors)}
        {...props}
    >{({ getRootProps, getInputProps, open, isDragActive }) => (
        <div {...getRootProps({ className: "dropzone w-100 d-block "+(isDragActive?"active ":"")+ (className || "")})}>
            <input {...getInputProps()}/>
            {children}
            <Button size="xl" variant="outline-secondary" onClick={(e: SyntheticEvent) => { e.stopPropagation(); open(); }}
            >{buttonLabel || msgs.gui.actionAddFile}</Button>
            <p dangerouslySetInnerHTML={{ __html: hintMessage || msgs.gui.hintUpload }}/>
        </div>
    )}</Dropzone>
        <UploadErrorDialog errors={errors} onHide={() => setErrors(null)} maxSize={props.maxSize}/>
    </>;
}