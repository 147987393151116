//@flow
import React, {useCallback, useMemo, useState} from 'react';
import {DialogLink, useMsgs} from "../lib/Language";
import {Breadcrumb, Button, Card} from "react-bootstrap";
import {BreadcrumbItem, PageHeader} from "../lib/Components";
import DesktopScreen from "./DesktopScreen";
import InvoiceMonitoringDesktopScreen from "./InvoiceMonitoringDesktopScreen";
import type {DataTableColumn, DataTableProps} from "../lib/DataTable";
import {dateTimeCell, QueryDataTable} from "../lib/DataTable";
import type {FilterFieldConfig} from "../lib/Filter";
import FilterInput, {emptyQuery, FilterInputProps} from "../lib/Filter";
import {useDelayed, useHistoryState} from "../lib/Hooks";
import {store} from "../application";
import {Redirect} from "react-router-dom";
import {InvoiceMonitoringImportDialog} from "./InvoiceMonitoringInvoicesScreen";
import {useRpcQuery} from "../lib/QueryUtils";
import type {InvoiceMonitoringIntegrationSettings} from "../api";
import {UseQueryResult} from "@tanstack/react-query";


/**
 * Komponent z listą synchronizacji
 */
const SyncTable = (props: $Diff<DataTableProps, { columns: any, data: any }>) => {
    const msgs = useMsgs();
    const columns = useMemo<Array<DataTableColumn>>(() => [
        {
            accessor: 'time',
            Header: msgs.gui.labelSyncTime,
            Cell: dateTimeCell,
            className: "datetime",
        }, {
            accessor: 'type',
            Header: msgs.gui.labelType,
            className: "lg",
            Cell: ({ value }) => msgs.gui["ist"+value] || value,
        }, {
            accessor: 'status',
            Header: msgs.gui.labelStatus,
            Cell: ({ value }) => msgs.gui["iss"+value],
        }
    ], [ msgs ]);
    return <QueryDataTable columns={columns} path="/user" func="queryMonitoredHistoryImport" initialSortBy="-time" {...props}/>;
}

const HistoryFilter = ({ ...props }: $Diff<FilterInputProps, { fields: any }> ) => {
    const msgs = useMsgs();
    const fields = useMemo<Array<FilterFieldConfig>>(() => [
        {
            permanent: true,
            field: "time",
            label: msgs.gui.labelSyncTime,
            type: "date",
        }
        ], [ msgs.gui.language ]);


    return <FilterInput fields={fields} {...props}/>
}

const externalSynchronization=[ 'Reset', 'DataIntegrator', 'Import', null ];

const InvoiceMonitoringSyncHistoryScreen = () => {
    const msgs=useMsgs();
    const write=store.invoiceMonitoring.write;
    const [ filter, setFilter ] = useHistoryState("filter", () => emptyQuery());
    const settingsQuery: UseQueryResult<InvoiceMonitoringIntegrationSettings>=useRpcQuery<InvoiceMonitoringIntegrationSettings>("/user", "getInvoiceMonitoringIntegrationSettings");
    // const [ filter, setFilter ] = useState(() => emptyQuery());
    const [ dFilter, setDFilter ] = useDelayed(filter, setFilter);
    const [ manualSync, setManualSync ] = useState(false);
    const [ ts, setTs ] = useState(Date.now());
    const handleManualSync = useCallback(() => {
        setManualSync(true);
        store.userApi.synchronizeInvoicesNow().then(() => {
            window.setTimeout(() => {
                setManualSync(false);
                setTs(Date.now())
            }, 3500);
        }).catch(e => {
            console.warn(e);
            setManualSync(false);
        });
    }, [ setManualSync, setTs ]);
    if(!store.invoiceMonitoring || !store.invoiceMonitoring.read) return <Redirect to="/" push={false}/>

    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
            <BreadcrumbItem to={InvoiceMonitoringDesktopScreen.link()}>{msgs.gui.menuInvoiceMonitoringDesktop}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.gui.menuInvoiceMonitoringSyncHistory}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.gui.menuInvoiceMonitoringSyncHistory}/>
        {settingsQuery.data && settingsQuery.data.type==="Import" && <Card className="mb-4">
            <Card.Body>
                <DialogLink to={InvoiceMonitoringImportDialog.link()}>{msgs.gui.imhManualImportFromFile}</DialogLink>
            </Card.Body>
        </Card>}
        {settingsQuery.data && !externalSynchronization.includes(settingsQuery.data.type) && <Card className="mb-4">
            <Card.Body>
                <Button
                    disabled={manualSync}
                    variant="secondary"
                    onClick={handleManualSync}
                >{msgs.gui.imhManualImportNow}</Button>
            </Card.Body>
        </Card>}
        <Card className="mb-5">
            <Card.Body>
                <HistoryFilter value={dFilter} onChange={setDFilter} mainField={false}/>
            </Card.Body>
        </Card>
        <SyncTable customFilters={filter} historyState="table" dataTimestamp={ts}/>
    </>;
}

InvoiceMonitoringSyncHistoryScreen.url="/im/sync";
InvoiceMonitoringSyncHistoryScreen.link = () => InvoiceMonitoringSyncHistoryScreen.url;
export default InvoiceMonitoringSyncHistoryScreen;