//@flow
import React, {Suspense} from 'react';
import {Spinner} from "react-bootstrap";
import type {BarChartProps} from "./BarChart";

const BarChart = React.lazy(() => import("./BarChart"));

const LazyBarChart = (props: BarChartProps) => {
    return <Suspense fallback={<Spinner animation="border" role="status"/>}>
        <BarChart {...props}/>
    </Suspense>
}
export default LazyBarChart;