//@flow
import BigNumber from "bignumber.js";
import type {LangOption, Option} from "../api";
import {currentLang, getLangValue} from "./Language";
import {useLocation} from "react-router-dom";
// import {Countries} from "./Data";

/**
 * Czy uruchomienie w trybie deweloperskim
 * @type {boolean}
 */
export const isDevelopment=(!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

export type PromiseFunction<T> = {
    resolve: (T) => void;
    reject: () => void;
}

export function arrayToggleItem<T>(data: Array<T>, item: T): Array<T> {
    let res=data.filter((v) => v!==item);   // kopia i usunięcie
    if(res.length===data.length) res.push(item)  // jeżeli nie usunięto, to dodajemy
    return res;
}

/**
 * Dodanie elementu do tablic, o ile go nie było tam wcześniej
 */
export function arrayInclude<T>(data: Array<T>, item: T): Array<T> {
    let cpy=data.filter((v) => v!==item);
    if(cpy.length===data.length) {  // nie było elementu
        cpy.push(item); // dodajemy element do kopii i zwracamy
        return cpy;
    }
    return data;    // brak zmian - element już był
}

export function arrayRemove<T>(data: Array<T>, item: T): Array<T> {
    let cpy=data.filter((v) => v!==item);
    if(cpy.length===data.length) return data;   // brak zmian - zwracały oryginał
    return cpy; // nowa tablica bez elementu
}

export function includes(input: any, text: string): boolean {
    if(input===null || input===undefined) input="";
    else if(typeof(input)!=='string') input=String(input);
    return input.toLowerCase().includes(text);
}

/**
 * Funkcja wyszukuje element w tablicy, gdy istnieje i go podmienia.
 * Gdy go nie ma, to go dodaje do tablicy (na końcu).
 * @param data tablica danych
 * @param test funkcja testująca
 * @param item element zastępujący/do wstawienia
 * @param [copy] czy ma być zwrócona nowa tablica (kopia)
 * @return tablica danych
 */
export function arrayReplaceOrAdd<T>(data: Array<T>, test: (i: T) => boolean, item: T, copy?: boolean): Array<T> {
    if(copy===true) {
        const res:Array<T>=[];
        for(let i=0;i<data.length;++i) {
            const el=data[i];
            if(test(el)===true) {   // znaleziono
                res.push(item);     // więc podmieniamy
                while(++i<data.length) res.push(data[i]); // i kopiujemy resztę
                return res;
            }
            res.push(el);
        }
        res.push(item);
        return res;
    } else {
        for(let i=0;i<data.length;++i) {
            const el=data[i];
            if(test(el)===true) {   // znaleziono,
                data[i]=item;       // podmieniamy
                return data;        // zwracamy
            }
        }
        data.push(item);    // nie znaleziono, dodajemy i zwracamy
        return data;
    }
}

/**
 * Pomocnicza funkcja, która podmienia element w tablicy
 * @param data tablica
 * @param item element do podmiany
 * @param checker funkcja sprawdzająca, czy dany element powinien być podmieniony
 * @return {Array<T>} kopia tablicy z podmienionym elementem
 */
export function arrayUpdate<T>(data: Array<T>, item: T, checker?: (v1: T, v2: T) => boolean): Array<T> {
    if(!checker) checker=(v1, v2) => v1.id===v2.id;
    return data.map(i => checker(i, item)?item:i);
}

export class LazyData<T> {
    _url:? string;
    _data:? T;
    _listeners:? Array<PromiseFunction<T>>;
    convert: (json: any) => T;

    constructor(url: string, convert:? (json: any) => T) {
        this._url=url;
        this.convert=convert;
    }

    /** Czy dane są już dostępne */
    isReady() {
        return this._data!==undefined;
    }

    /** Pobranie danych bez czekania - zwraca undefined jeżeli dane nie są jeszcze pobrane */
    getNow(): ?T {
        return this._data;
    }

    /** Pobranie danych. */
    get(): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            if(this._data!==undefined) {
                resolve(this._data);
                return;
            }
            if(this._listeners===undefined) {
                this._listeners=[];
                fetch(this._url).then((res) => {
                    res.json().then((data) => {
                        if(this.convert) this._data=this.convert(data);
                        else this._data=data;
                        this._listeners.forEach(({ resolve }) => {
                            try {
                                resolve(this._data);
                            }catch(e) {
                                console.warn(e);
                            }
                        });
                    }).catch(e => this._listeners.forEach(({reject}) => reject(e)))
                        .finally(() => {
                            delete this._url;
                            delete this._listeners;
                        });
                }).catch(e => {
                    this._listeners.forEach(({reject}) => reject(e));
                    delete this._url;
                    delete this._listeners;
                })
            }
            this._listeners.push({ resolve, reject });
        });
    }
}

export function nullifyString(v: string): string|null {
    if(!v) return null;
    return v;
}

export function parseMoney(value: BigNumber|string|null|undefined|number, def?: BigNumber): BigNumber|null {
    if(def===undefined) def=null;
    if(value===null || value===undefined || value==="") return def;
    let res;
    if(value instanceof BigNumber) res=value;
    else res=new BigNumber(value);
    if(!res || Number.isNaN(res) || res.isNaN()) return def;
    return res;
}

export function formatMoney(value: string|null|undefined|number|BigNumber, cur?: string, nullAsZero?: boolean): string {
    let bn=parseMoney(value);
    if(bn===null) {
        if(nullAsZero===true) bn=new BigNumber(0);
        else return "";
    }
    const str=bn.toFormat(2, BigNumber.ROUND_HALF_UP, currentLang.bigNumber);
    if(cur) return str+" "+cur;
    return str;
}

export function addMoney(acc: BigNumber|null, value: BigNumber|string|number|null|undefined): BigNumber|null {
    let v=parseMoney(value);
    if(v===null) return acc;
    if(!acc) acc=new BigNumber(0);
    return acc.plus(v);
}

const ZERO=new BigNumber(0);

/** Odejmuje dwie wartości - jeżeli coś nie jest poprawną liczbą, to uznawane jest za zero */
export function subMoney(v1: any, v2: any): BigNumber {
    return parseMoney(v1, ZERO).minus(parseMoney(v2, ZERO));
}

export function sumMoney(...value: any): BigNumber {
    let sum=ZERO;
    value.forEach(v => {
        const p=parseMoney(v);
        if(p!==null) sum=sum.plus(p);
    })
    return sum;
}


/**
 * Płytkie porównanie obiektów.
 */
export function shallowEquals(a: {}, b: {}): boolean {
    if (a === b) return true;
    if (!a || !b) return false;

    const aKeys = Object.keys(a);
    const len = aKeys.length;

    if (Object.keys(b).length !== len) return false;
    for (let i = 0; i < len; i++) {
        const key = aKeys[i];
        if (a[key] !== b[key] || !Object.prototype.hasOwnProperty.call(b, key)) return false;
    }
    return true;
}



export type SelectOption = string|Option|LangOption;

/** Pobranie etykiety dla opcji */
export function getOptionLabel(o: SelectOption) {
    if(typeof(o)==='string') return o;
    if(typeof(o)==='object') {
        if(typeof(o.label)==='object') return getLangValue(o.label);
        else return o.label;
    }
    if(Array.isArray(o)) return o[1];
    return null;
}

/** Pobranie wartości opcji */
export function getOptionValue(o: SelectOption) {
    if(typeof(o)==='string') return o;
    if(Array.isArray(o)) return o[0];
    if(typeof(o)==='object') return o.value;
    return null;
}

export function findOptionByValue<T: SelectOption>(options: Array<T>, value: string): T|undefined {
    if(!Array.isArray(options)) return undefined;
    return options.find(opt => {
        if(typeof(opt)==='string') return opt===value;
        if(Array.isArray(opt)) return opt[0]===value;
        if(typeof(opt)==='object') return opt.value===value;
        return false;
    });
}

/**
 * Pomocnicza klasa do aktualizacji filtra z opóźnieniem
 */
export class PropertyDelayer<T> {
    disabled: boolean;
    component: React.Component;
    delay: number;
    src: string|null|(T) => void;
    dst: string|(T) => void;
    pending: T;

    handle:? number;

    constructor(component: React.Component, src: string|null|(T) => void, dst: string|(T)=>void, delay: number=800) {
        this.disabled=false;
        this.component=component;
        this.src=src;
        this.dst=dst;
        this.delay=delay;
    }

    stop = () => {
        this.disabled=true;
        if(this.handle) {
            window.clearTimeout(this.handle);
            delete this.handle;
        }
    }

    _fireEvent() {
        if(!this.disabled) {
            if (typeof (this.dst) === 'function') {
                this.dst(this.pending);
            } else this.component.setState({[this.dst]: this.pending})
        }
        delete this.pending;
    }

    forcePending = () => {
        if(!this.handle) return;
        window.clearTimeout(this.handle);
        delete this.handle;
        this._fireEvent();
    }

    update = (value: T, force?: boolean) => {
        if(this.handle) {
            window.clearTimeout(this.handle);
        }
        if(typeof(this.src)==='string') this.component.setState({ [this.src]: value });
        else if(typeof(this.src)==='function') this.src(value);

        if(this.disabled) return;

        this.pending=value;
        if(force===true) {
            this._fireEvent();
        } else {
            this.handle = window.setTimeout(() => {
                delete this.handle;
                this._fireEvent();
            }, this.delay);
        }
    }
}

export function getLocationState(location: Location, name: string|null|undefined, subName?: string): any {
    if(!location || typeof(name)!=='string' || name.length===0) return undefined;
    if(subName) name+=subName;
    if(typeof (location.state)!=='object') return undefined;
    return location.state[name];
}

export function updateLocationState(location: Location, name: string|null|undefined, state: any, subName?: string) {
    if(!location || typeof(name)!=='string' || name.length===0) return;
    if(subName) name+=subName;
    if(typeof (location.state)!=='object') location.state={};
    location.state[name]=state;
}

/**
 * Funkcja aktualizuje stan w historii zmieniając wartość state
 */
export function replaceHistoryState(history: History, location: Location, name: string|null|undefined, value: any, subName?: string) {
    if(!history || !location || typeof(name)!=='string' || name.length===0) return;
    if(subName) name+=subName;
    let state;
    if(typeof(location.state)==='object') {
        if(location.state[name]===value) return;    // brak zmian
        state={
            ...location.state,
            [name]: value
        }
    } else {
        state={ [name]: value }
    }

    history.replace(
        location.pathname,
        state
    );
}

/**
 * Nakładka do useLocation, do uproszczonej obsługi stanu lokacji na zasadzie useState.
 */
export function useLocationState(name: string|null|undefined): [ any, (value: any) => void ] {
    const location=useLocation();
    return [
        getLocationState(location, name),
        (value: any) => updateLocationState(location, name, value)
    ]
}

/**
 * Funkcja formatuje numer identyfikacji podatkowej, w zapisie, w którym z przodku
 * jest kod kraju.
 */
export function formatTax(tax: string|null): string {
    if(typeof(tax)!=='string' || tax.length===0) return "";

    // Inteligentne wyświetlanie numeru NIP
    // if(tax.startsWith("PL") && tax.length===12) {
    //     return "PL "+tax.substr(2,3)+"-"+tax.substr(5, 3)+"-"+tax.substr(8, 2)+"-"+tax.substr(10, 2);
    //     // return "PL "+tax.substr(2);
    // } else if(tax.startsWith("PL") && tax.length===13) {    // nr PESEL
    //     return "PL "+tax.substr(2);
    // } else if(tax.startsWith("DE") && tax.length===13) {
    //     return "DE "+tax.substr(2,2)+" "+tax.substr(4, 3)+" "+tax.substr(7, 3)+" "+tax.substr(10, 3);
    // } else if(tax.startsWith("CZ") && (tax.length===11 || tax.length===12)) {
    //     return "CZ "+tax.substr(2,6)+"/"+tax.substr(8);
    // }
    // const st=tax.charAt(0);
    // if(st>='0' && st<='9') {    // jeżeli od liczby
    //     if(tax.length===10) {   // i 10 znaków, to zakładamy że polski NIP
    //         return tax.substr(0,3)+"-"+tax.substr(3, 3)+"-"+tax.substr(6, 2)+"-"+tax.substr(8, 2);
    //     }
    // }
    // if(tax.length>2) {
    //     const country=tax.substr(0, 2);
    //     if(Countries.byCode.get(country)) {
    //         return country+" "+tax.substr(2);
    //     }
    // }
    // return tax;
    return tax; // Dodać spację po kraju?
}

export function getLangNumeralSuffix(val: number) {
    if(typeof(val)!=='number' || val===0) return "None";
    if(val===1) return "One";
    if(val<=4) return "Few";
    return "Many";
}

/** Funkcja porównująca dla wartości typu Option - po labelu do sortowania */
export function optionCompare(v1: Option, v2: Option):number {
    return v1.label.toLowerCase().localeCompare(v2.label.toLowerCase());
}

/** Zwraca true, jeżeli parametr jest tablicą i jest ona nie pusta */
export function isNotEmptyArray(val: any): boolean {
    return Array.isArray(val) && val.length>0;
}

/** Zwraca true, jeżeli parametr jest tablicą, która ma więcej niż 1 element */
export function isArrayTwoOrMore(val: any): boolean {
    return Array.isArray(val) && val.length>1;
}

export function identity(val: any): any {
    return val;
}

/** Czy przeglądarka, to Safari */
export function isSafari(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
        if (ua.indexOf('chrome') > -1) return false;
        return true;
    }
}

/**
 * Pomocnicza funkcja zamieniająca wartość na małe znaki, o ile jest to string.
 * @param v
 * @return {*|string}
 */
export function stringToLower(v: any): any {
    if(typeof(v)!=='string') return v;
    return v.toLocaleLowerCase();
}

/**
 * Porównanie dwóch elementów jako tekst (localeCompare) z obsługą null|void oraz konwersją dla string dla innych typów (numer)
 * @param v1
 * @param v2
 */
export function stringLocaleCompare(v1: any, v2: any): number {
    if(v1===v2) return 0;
    // Obsługa porównania do nulli|void
    if(v1===null || v1===undefined) {
        if(v2===null || v2===undefined) return 0;
        return -1;
    } else if(v2===null || v2===undefined) return 1;

    if(typeof(v1)!=='string') v1=String(v1);
    if(typeof(v2)!=='string') v2=String(v2);

    return v1.localeCompare(v2);
}

export function stringCompare(v1: any, v2: any): number {
    if(v1===v2) return 0;
    // Obsługa porównania do nulli|void
    if(v1===null || v1===undefined) v1="";
    else if(typeof(v1)!=='string') v1=String(v1);

    if(v2===null || v2===undefined) v2="";
    else if(typeof(v2)!=='string') v2=String(v2);

    if(v1>v2) return 1;
    if(v1<v2) return -1;
    return 0;
}

export function invertCompare<T>(compareFunc: (v1: T, v2: T) => number): (v1: T, v2: T) => number {
    return (v1, v2) => compareFunc(v2, v1);
}

/**
 * Funkcja łączy wiele wartości w jedną, używając podanego separatora.
 * @param sep separator, który ma być użyty do łączenia
 * @param values wartości, które połączyć.
 */
export function stringJoin(sep: string, ...values: Array<string>): string {
    if(!Array.isArray(values) || values.length===0) return "";
    let res="";
    for(let v: string of values) {
        if(typeof(v)==='number') v=v.toString();
        else if(typeof(v)!=='string') continue;

        v=v.trim();
        if(v.length===0) continue;

        if(res.length>0) res+=sep;
        res+=v;
    }
    return res;
}

/**
 * Uproszczona funkcja do parsowania wartości liczbowej.
 * Zwraca ona poprawną liczbę lub null
 * @param {any} v
 * @param {number|null} [def] domyślna wartość, która ma być zwrócona, gdy numer jest niepoprawny
 * @return {number|null}
 */
export function parseNumber(v: any, def: number|null): number|null {
    if(typeof(def)!=='number' && def!==null) def=null;

    if(typeof(v)==='string') v=parseFloat(v);
    else if(typeof(v)!=='number') return def;

    if(isNaN(v) || !isFinite(v)) return def;
    return v;
}


/**
 * Funkcja oczyszczająca nazwę (np. nazwa firmy, adres) z różnych znaków specjalnych
 * i zostawiająca tylko litery i cyfry, aby porównanie dwóch nazw było sensowniejsze.
 * @param name {string} nazwa do oczyszczenia
 * @return {string} oczyszczona nazwa
 */
export function normalizeString(name: string) {
    if(name===null || name===undefined) return "";
    if(typeof(name)==='number') return name.toString();
    else if(typeof(name)!=='string') return ""; //???
    return name
        .toLocaleLowerCase()
        .replace(/[^\w\d\u00c0-\u024f]/g, " ")
        .replace(/\s+/g, " ")
        .trim();
}

/**
 * Funkcja porównująca dwie nazwy, po uprzednim oczyszczeniu ich z różnych znaków specjalnych.
 * @param v1 {string}
 * @param v2 {string}
 * @return {boolean} czy nazwy są takie same
 */
export function normalizedStringEquals(v1: string, v2: string): boolean {
    return normalizeString(v1)===normalizeString(v2);
}