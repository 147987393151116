//@flow
import React from 'react';
import {Col, Form, Modal} from "react-bootstrap";
import {AuthPage, EmailInput, ErrorMessage, IconButton, PasswordInput, waitGlass} from "../lib/Components";
import msgs, {LangContext, LangLink, moveTo} from "../lib/Language";
import Validation from "../lib/Validation";
import {RouteComponentProps} from "react-router";
import ResetPasswordScreen from "./ResetPasswordScreen";
import RegisterScreen from "./RegisterScreen";
import {store} from "../application";
import {isDevelopment} from "../lib/Utils";
import RegisterWithVindicationScreen from "./RegisterWithVindicationScreen";
import * as FacebookPixel from "../lib/FacebookPixel";

type State = {
    email: string;
    password: string;
    invalid: string|null;
}

export default class LoginScreen extends React.Component<RouteComponentProps, State> {
    static url="/login";

    constructor(props) {
        super(props);
        this.state={
            email: isDevelopment?"gg@euleo.pl":"",
            password: isDevelopment?"password":"",
            invalid: null,
        }
    }

    componentDidMount() {
        FacebookPixel.trackSinglePageView();
    }

    onLogin(e: Event) {
        e.preventDefault();
        store.publicApi.login(this.state.email, this.state.password).then(res => {
            let err=Validation.getError(res);
            if(err) {
                this.setState({ invalid: err });
                return;
            }
            store.processLogin().then(() => {
                const last=store.getReturnPage();
                if(last) {
                    this.props.history.replace(last);
                } else {
                    moveTo(this.props, "/", false, true);
                }
            });
            // Przekierowanie do docelowej strony
        }).finally(waitGlass());
    }

    render() {
        return (
            <AuthPage
                title={msgs.gui.titleLogin}
                header={<Modal.Header className="with-action">
                    <Modal.Title>
                        <h1>{msgs.gui.titleLogin}</h1>
                    </Modal.Title>
                    <p className="register">
                        {msgs.gui.labelDontHaveAccount} <LangLink to={RegisterScreen.url}>{msgs.gui.labelRegister}</LangLink><br/>
                        {msgs.gui.labelOr} <LangLink to={RegisterWithVindicationScreen.url}>{msgs.gui.labelRegisterWithDebt}</LangLink>
                    </p>
                </Modal.Header>}
            >
                <Modal.Body>
                    <ErrorMessage message={this.state.invalid}/>
                    <Form className="login-form">
                        <Form.Group controlId="email">
                            <Form.Label>{msgs.gui.labelEmail}</Form.Label>
                            <EmailInput
                                value={this.state.email}
                                onChange={e => this.setState({ email: e.target.value })}
                                onBlur={() => this.setState({ invalid: false })}
                            />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>{msgs.gui.labelPassword}</Form.Label>
                            <PasswordInput
                                value={this.state.password}
                                onChange={e => this.setState({ password: e.target.value })}
                                onBlur={() => this.setState({ invalid: false })}
                            />
                        </Form.Group>
                        <Form.Row className="d-flex align-items-center">
                            <Col md={6}>
                                <LangLink to={ResetPasswordScreen.url}>{msgs.gui.labelNoPassword}</LangLink>
                            </Col>
                            <Col md={6} className="text-right">
                                <IconButton
                                    icon={<span className="icon-user"/>}
                                    type="submit" onClick={(e) => this.onLogin(e)}
                                >{msgs.gui.actionLogin}</IconButton>
                            </Col>
                        </Form.Row>
                    </Form>
                </Modal.Body>
            </AuthPage>
       );
    }
}
LoginScreen.contextType=LangContext;