//@flow
import React from 'react';
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";
import msgs, {currentLang, LangContext, LangLink, useMsgs} from "../lib/Language";
import type {FilterFieldConfig, FilterQuery} from "../lib/Filter";
import FilterInput, {createSimpleQuery, emptyQuery} from "../lib/Filter";
import {store} from "../application";
import {Alert, Breadcrumb, Button, Card, Col, Row} from "react-bootstrap";
import {
    BreadcrumbItem,
    CombinedNIPInput,
    dialogOpen,
    DisplayHTML,
    InlineDialog,
    PageHeader,
    Timeline,
    TimelineEntry
} from "../lib/Components";
import type {DebtorQueryInfo, LangOption, TableQuery, TableResult, VindicationEvent, VindicationPayment} from "../api";
import {DebtEventFile, PaymentEntry} from "../lib/VindicationsView";
import {DataView} from "../lib/DataTable";
import {AddVindication} from "../lib/AddVindication";
import {VindicationNew, VindicationScreen} from "./ViewVindicationScreen";
import {getLocationState, PropertyDelayer, replaceHistoryState} from "../lib/Utils";
import VindicationsScreen from "./VindicationsScreen";

type State = {
    filter: FilterQuery;
    payments: Array<VindicationPayment>|null;
    events: TableResult<VindicationEvent>|null;
    tax: string;
    hasAnyEvents: boolean|number;

    checkDebtor: DebtorQueryInfo|null;
}

const lastPaymentsQuery: TableQuery = {
    from: 0,
    items: 15,
    sort: "-date",
}

export default class DesktopScreen extends React.Component<RouteComponentProps, State> {
    static url = "/";

    filter: Array<FilterFieldConfig>;
    eventTypes: Array<LangOption>;
    delayer: PropertyDelayer<FilterQuery>;
    viewVindications: boolean;

    constructor(props) {
        super(props);
        this.viewVindications=store.vindication && store.vindication.read;
        const filter=getLocationState(this.props.location, 'filter') || emptyQuery();
        this.state = {
            filter: filter,
            payments: null,
            events: null,
            tax: "",
            hasAnyEvents: null,

            checkDebtor: null,
        }
        this.delayer=new PropertyDelayer(this, this.setFilter, this.updateFilter);
    }

    setFilter = (filter: FilterQuery) => {
        this.setState({  filter });
        replaceHistoryState(this.props.history, this.props.location, 'filter', filter);
    }

    initColumns(initial: boolean) {
        if (this.lang === currentLang.code) return;
        this.lang = currentLang.code;
        this.filter=[
            {
                field: "type",
                type: "select",
                label: msgs.gui.labelType,
                options: this.eventTypes,
                permanent: true,
            }, {
                field: "date",
                type: "date",
                label: msgs.gui.labelDate,
                permanent: true,
            }
        ];
        if(!initial) this.forceUpdate();
    }

    loadEvents(from: number): Promise<TableResult<VindicationEvent>> {
        return store.userApi.queryEvents({
            query: this.state.filter.text,
            filters: this.state.filter.fields,
            sort: "-date",
            from: from,
            items: 25,
        });
    }

    refresh = ()  => {
        store.userApi.queryPayments(lastPaymentsQuery).then((res: TableResult) => {
            this.setState({ payments: res.data });
        });
        this.loadEvents(0).then((events) => this.setState({ events }))
    }

    updateFilter = () => {
        this.loadEvents(0).then((events) => this.setState({ events }));
    }

    componentDidMount() {
        if(this.viewVindications) {
            store.getEventTypes().then(eventTypes => {
                this.eventTypes = eventTypes;
                delete this.lang;
                this.initColumns(true)
                // this.initColumns();
            })
            store.userApi.hasAnyEvents().then(hasAnyEvents => this.setState({hasAnyEvents}));
            this.refresh();
        }
        AddVindication.background=this.refresh;
    }

    componentWillUnmount() {
        this.delayer.stop();
        AddVindication.background=null;
    }

    handleLoadMoreEvents =() => {
        this.loadEvents(this.state.events.data.length).then((events) => {
            if (this.state.events) {
                this.setState({
                    events: {
                        data: [...this.state.events.data, ...events.data],
                        items: events.items,
                    }
                });
            } else {
                this.setState({ events });
            }
        });
    }

    componentDidUpdate(prevProps: $ReadOnly<Props>, prevState: $ReadOnly<State>, snapshot: any) {
        this.initColumns();
        return true;
    }

    handleCheckIfDebtor = () => {
        store.userApi.checkDebtor(this.state.tax).then(di => {
            this.setState({
                checkDebtor: di,
                tax: "",
            });
        })
    }

    onAddVindication(e: SyntheticEvent) {
        dialogOpen(this, VindicationNew.url);
    }

    renderCheckNIP() {
        return <Card className="mb-5 color1 check-nip">
            <Card.Body>
                <Card.Title><h3>{msgs.gui.titleCheckIfDebtor}</h3></Card.Title>
                <CombinedNIPInput
                    value={this.state.tax}
                    onChange={(v) => this.setState({ tax: v.target.value })}
                    placeholder={msgs.gui.hintEnterTax}
                />
                <div className="text-center mt-4">
                    <Button
                        disabled={!this.state.tax}
                        size="xl"
                        onClick={this.handleCheckIfDebtor}
                    >{msgs.gui.actionCheckDebtor}</Button>
                </div>
                <CheckNIPDialog value={this.state.checkDebtor} onHide={() => this.setState({ checkDebtor: null })}/>
            </Card.Body>
        </Card>;
    }

    render() {
        if (this.viewVindications) {
            if (!this.eventTypes || this.state.hasAnyEvents === null) return null;
        }

        if (!store.user.orgId) {
            return <>
                <Breadcrumb>
                    <BreadcrumbItem active>{msgs.gui.titleSummary}</BreadcrumbItem>
                </Breadcrumb>
                <PageHeader title={msgs.gui.titleNoCompanies}>
                </PageHeader>
                <Alert variant="white">
                    <p className="text-info" dangerouslySetInnerHTML={{__html: msgs.gui.hintNoCompanies}}/>
                </Alert>
            </>;

        }
        return <>
            <Breadcrumb>
                <BreadcrumbItem active>{msgs.gui.titleSummary}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.gui.titleSummary}>
            </PageHeader>
            {this.viewVindications ? <><Card className="mb-5 search shadow">
                <Card.Body>
                    <Card.Title><h3>{msgs.gui.titleSearch}</h3></Card.Title>
                    <FilterInput
                        fields={this.filter}
                        value={this.state.filter}
                        onChange={this.delayer.update}
                        onSearchClick={this.delayer.forcePending}
                    />
                </Card.Body>
            </Card>
                <Row>
                    <Col md={8}>
                        <DataView
                            value={this.state.events}
                            onLoadMore={this.handleLoadMoreEvents}
                            empty={!this.state.hasAnyEvents ? <Card className="shadow">
                                <Card.Body className="text-center">
                                    <img src="/resources/img/empty/dashboard.png" alt="" className="d-block m-auto img-fluid pt-5 pb-3"/>
                                    <h1>{msgs.gui.titleNoNotifications}</h1>
                                    <h4 className="mt-3 mb-5">{msgs.gui.hintNoNotifications}</h4>
                                    <Button
                                        size="xl" className="btn-icon mb-5 mx-auto" variant="success"
                                        onClick={(e) => this.onAddVindication(e)}
                                    ><span className="outline-round"><div>+</div></span> {msgs.gui.actionNewVindication}</Button>
                                </Card.Body>
                            </Card> : undefined}
                            contentWrapper={(children) => <Timeline>{children}</Timeline>}
                        >{(item: VindicationEvent) => <TimelineEntry
                            key={item.id}
                            title={item.type} date={item.date}
                        >
                            <p className="text-info">
                                {msgs.gui.eventLabelVindication} <LangLink
                                to={VindicationScreen.link(item.vId)}>{item.vFullId}</LangLink> {msgs.gui.eventLabelIn} <Link to={{
                                    pathname: VindicationsScreen.url,
                                    state: {
                                        filter: createSimpleQuery("", "debtor", item.vDebtor)
                                    }
                                }}>{item.vDebtor}</Link>
                            </p>
                            <DisplayHTML className="event-content" value={item.message}/>
                            {/*<p>{item.message}</p>*/}
                            <DebtEventFile value={item}/>
                        </TimelineEntry>}</DataView>
                    </Col>
                    <Col md={4}>
                        {this.renderCheckNIP()}
                        <Card className="mb-5 color2 last-payments data">
                            <Card.Body>
                                <Card.Title><h3>{msgs.gui.labelLastPayments}</h3></Card.Title>
                                {!this.state.payments || this.state.payments.length === 0 ? <p className="text-info">
                                        {msgs.gui.dataTableEmpty}</p> :
                                    this.state.payments.map((p: VindicationPayment, i) => <PaymentEntry
                                        key={i} value={p}>
                                        <p className="text-info">
                                            {msgs.gui.eventLabelVindication} <LangLink to={VindicationScreen.link(p.vId)}>{p.vEcId || p.vExtId || p.vId}</LangLink> {msgs.gui.eventLabelIn} {p.vDebtor}
                                        </p>
                                    </PaymentEntry>)
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </> : this.renderCheckNIP()}
        </>;
    }
}
DesktopScreen.contextType=LangContext;
DesktopScreen.mainClassName="screen-desktop";

/** Proste okno informujące o tym, czy są długi dla odpytanego dłużnika */
const CheckNIPDialog = ({ value, onHide }: { value: DebtorQueryInfo, onHide: () => void }) => {
    const msgs=useMsgs();
    if(!value) return null;
    return <InlineDialog
        title={msgs.gui.titleCheckIfDebtor}
        cancelButton={msgs.gui.buttonClose}
        onCancel={onHide}
        acceptButton={null}
    >
        {value.debts?<h1 className="text-danger"
        >{msgs.gui.infoDebtor}</h1>:<h1 className="text-info"
        >{msgs.gui.infoNotDebtor}</h1>}
    </InlineDialog>
}