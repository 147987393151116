//@flow
import React, {useCallback, useState} from 'react';
import {langLink, useMsgs} from "../lib/Language";
import {Breadcrumb, Card, Col, Container, Row} from "react-bootstrap";
import {BreadcrumbItem, Hint, PageHeader} from "../lib/Components";
import DesktopScreen from "./DesktopScreen";
import InvoiceMonitoringDesktopScreen from "./InvoiceMonitoringDesktopScreen";
import {emptyQuery} from "../lib/Filter";
import {useDelayed, useHistoryState} from "../lib/Hooks";
import {ClientsFilter, ClientsList} from "../lib/InvoiceMonitoringComponents";
import {Redirect, useHistory} from "react-router-dom";
import InvoiceMonitoringClientScreen from "./InvoiceMonitoringClientScreen";
import {store} from "../application";


const InvoiceMonitoringClientsScreen = () => {
    const msgs=useMsgs();
    // const [ filter, setFilter ] = useState(() => emptyQuery());
    const [ filter, setFilter ] = useHistoryState("filter", () => emptyQuery());
    const [ dFilter, setDFilter ] = useDelayed(filter, setFilter);
    const history=useHistory();
    const handleRowClick=useCallback((row) => {
        history.push(langLink(InvoiceMonitoringClientScreen.link(row.id)));
    }, [ history ]);
    if(!store.invoiceMonitoring || !store.invoiceMonitoring.read) return <Redirect to="/" push={false}/>

    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
            <BreadcrumbItem to={InvoiceMonitoringDesktopScreen.link()}>{msgs.gui.menuInvoiceMonitoringDesktop}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.gui.menuInvoiceMonitoringClients}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.gui.menuInvoiceMonitoringClients}/>
        <Card className="mb-5">
            <Card.Body>
                <ClientsFilter value={dFilter} onChange={setDFilter}/>
            </Card.Body>
        </Card>
        <ClientsList
            customFilters={filter}
            onRowClick={handleRowClick}
            tableFooter={<Col className="text-right"><Hint placement="left-end">{msgs.gui.imScenarioHint}</Hint></Col>}
            historyState="table"
        />
    </>;
}

InvoiceMonitoringClientsScreen.url="/im/clients";
InvoiceMonitoringClientsScreen.link = () => InvoiceMonitoringClientsScreen.url;
export default InvoiceMonitoringClientsScreen;