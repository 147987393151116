//@flow
// Plik z ogólnym stanem aplikacji

import type {
    AccessRight,
    ApplicationSettings,
    Dictionaries,
    LangOption,
    Option,
    PublicAPI,
    UserAPI,
    UserRight,
    UserSettings,
    WebSettings
} from "./api";
import type {RequestError} from "./lib/Network";
import {remoteProxy} from "./lib/Network";
import type {LangInfo} from "./lib/Language";
import {getLangValue, langLink, Languages, sortLangOptions} from "./lib/Language";
import type {UserOrganizationConfig} from "./UserConfig";
import {shallowEquals} from "./lib/Utils";
import {emitEvent, events} from "./lib/Events";
import LoginScreen from "./auth/LoginScreen";

/**
 * Zdarzenia wysyłane przez aplikację
 * @type {{}}
 */
export const Events = {
    /** Zdarzenie wywoływane w przypadku zmiany stanu zalogowania użytkownika */
    Login: "Login",
    /** Czy aplikacja zainicjowana */
    Ready: "Ready",
    /** Zmiana w windykacjach */
    VindicationChange: "VindicationChange",
    /** Zmiana w usługach */
    ServiceChange: "ServiceChange",
    /** Zmiana w użytkownikach */
    UserChange: "UserChange",
    /** Zmiana w liście monitorowanych */
    MonitoredChange: "MonitoredChange",
    /** Zmiana danych użytkownika */
    UserSettings: "UserSettings",
    /** Zmiana w usługach */
    OrderChange: "OrderChange",
    /** Zmiana organizacji */
    OrganizationChanged: "OrganizationChanged",
    /** Zmiana w innych użytkownikach, którymi zarządza administrator */
    CompanyUserChange: "CompanyUserChange",
    /** Błąd zapytania HTTP */
    Error: "Error",
}

export { events, useEvent } from "./lib/Events";

export interface StoreAPI {
    +user: ApplicationSettings;
    +isLogged: boolean;
    +publicApi: PublicAPI;
    +userApi: UserAPI;
    +vindication?: AccessRight;
    +globalVindication?: AccessRight;
    +debtExchange?: AccessRight;
    +monitoring?: AccessRight;
    +difficultDebts?: AccessRight;
    +invoiceMonitoring?: AccessRight;
    +services?: Array<AccessRight>;

    +webSettings: WebSettings;

    hasRight(right: UserRight): boolean;

    refreshUser(): Promise<ApplicationSettings>;

    getService(ident: string): AccessRight|undefined;
    getUserSettings(): UserSettings;

    init(): Promise<void>;

    updateLanguage(lang: LangInfo): void;
    processLogin(): Promise<void>;
    logout(): Promise<void>;

    setReturnPage(returnUrl: string|{}): void;
    getReturnPage(): undefined|string|{};

    getCountry(): Promise<string>;
    getDictionaries(): Promise<Dictionaries>;
    getInsurers(): Promise<Array<LangOption>>;
    getEventTypes(): Promise<Array<LangOption>>;
    tryGetEventTypes(): Array<LangOption>|null;

    updateSettings(settings: $Shape<UserOrganizationConfig>): Promise<UserOrganizationConfig>;
}

const userOrganizationDefaults: UserOrganizationConfig = {
    startScreen: "Desktop",
    filters: [],
}

/** Klasa ze stanem aplikacji */
class Store implements StoreAPI {
    /** Strona powrotna po zalogowaniu */
    returnUrl:? string|{};
    /** Informacje o zalogowanym użytkowniku; null, gdy nie ma zalogowanego użytkownika */
    user: ApplicationSettings;
    /** Czy zainicjowany */
    ready: boolean;
    publicApi: PublicAPI;
    userApi: UserAPI;
    rights: Set<UserRight>;
    vindication: AccessRight;
    globalVindication: AccessRight;
    debtExchange: AccessRight;
    monitoring: AccessRight;
    difficultDebts: AccessRight;
    services: Array<AccessRight>;
    country: string;
    eventTypes: Array<LangOption>;
    _dictionaries: Dictionaries;
    _insurers: Array<LangOption>;
    webSettings: WebSettings;
    invoiceMonitoring: AccessRight;

    constructor() {
        this.webSettings=window.appSettings;
        this.user=null;
        this.ready=false;
        this.rights=new Set();
        this.publicApi = remoteProxy(process.env.REACT_APP_SERVER+"/data/public", this._handleErrorCallback);
        this.userApi = remoteProxy(process.env.REACT_APP_SERVER+"/data/user", this._handleErrorCallback);
    }

    _handleErrorCallback=(error: RequestError) => {
        console.log("Error handler: ", error);
        if(error.code===401 || error.code===403) {  // Unauthorized - nastąpiło wylogowanie (np. restart serwera, utrata ważności sesji)
            if(this.user!==null) {
                console.log("Got 401 response - moving to login page");
                window.location=langLink(LoginScreen.url)+"#session";
            }
            return true;
        }
        events.emit(Events.Error, error);
        return true;
    }

    hasRight(right: UserRight): boolean {
        return this.rights.has(right);
    }

    /** Buforowanie informacji o kraju dla IP użytkownika - tylko raz jest to pobierane */
    getCountry(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if(this.country) {
                resolve(this.country);
                return;
            }
            this.publicApi.getCountry().then((country) => {
                this.country=country;
                resolve(country);
            }).catch(reject);
        })
    }

    getDictionaries(): Promise<Dictionaries> {
        return new Promise<Dictionaries>((resolve, reject) => {
            if(this._dictionaries) {
                resolve(this._dictionaries);
                return;
            }
            this.publicApi.getDictionaries().then((dictionaries) => {
                this._dictionaries=dictionaries;
                sortLangOptions(dictionaries.insurers);
                sortLangOptions(dictionaries.documentTypes);
                resolve(dictionaries);
            }).catch(reject);
        })
    }

    getInsurers() {
        return new Promise<Array<LangOption>>((resolve, reject) => {
            resolve(this._insurers);
        });
    }


    getEventTypes(): Promise<Array<LangOption>> {
        return new Promise((resolve, reject) => {
            if(this.eventTypes) {
                resolve(this.eventTypes);
                return;
            }
            this.userApi.getEventTypes().then(eventTypes => {
                sortLangOptions(eventTypes);
                resolve(this.eventTypes=eventTypes);
            }).catch(reject);
        });
    }

    tryGetEventTypes(): Array<LangOption>|null {
        return this.eventTypes;
    }

    getUserSettings(): UserSettings {
        return {
            name: this.user.name,
            email: this.user.email,
            userSettings: {...this.user.userSettings},
            settings: {...this.user.settings},
            language: this.user.language,
            phone: this.user.phone,
            notifications: [...this.user.notifications],
        }
    }

    refreshUser(): Promise<ApplicationSettings> {
        return new Promise<ApplicationSettings>((resolve, reject) => {
            this.publicApi.getSettings().then(updated => {
                this.user=updated;
                const cpy=this.getUserSettings().settings;
                resolve(cpy);
                emitEvent(Events.UserSettings, cpy);
            }).catch(reject);
        })
    }

    updateSettings(changes: $Shape<UserOrganizationConfig>): Promise<UserOrganizationConfig> {
        return new Promise<UserSettings>((resolve, reject) => {
            let current=this.getUserSettings();
            const settings={
                ...current,
                settings: {
                    ...current.settings,
                    ...changes,
                }
            }
            if(shallowEquals(current.settings, settings.settings)) {  // brak zmian
                resolve(current);
                return;
            }
            this.userApi.saveUser(settings).then(() => {
                this.refreshUser().then(resolve).catch(reject);
            }).catch(reject);
        })
    }

    /** Zwraca usługę na podstawie identyfikatora */
    getService(ident: string): AccessRight|undefined {
        if(!this.services || !ident) return undefined;
        return this.services.find((a: AccessRight) => a.ident===ident);
    }

    getReturnPage() {
        const res=this.returnUrl;
        delete this.returnUrl;
        return res;
    }

    setReturnPage(returnUrl) {
        this.returnUrl=returnUrl;
    }

    /** Pomocnicza funkcja, która sortuje usługi */
    _sortServices() {
        if(!Array.isArray(this.services)) return;
        this.services.sort((s1: AccessRight, s2: AccessRight) => {
            const o=s1.order-s2.order;
            if(o!==0) return o;
            return getLangValue(s1.name).toLowerCase().localeCompare(getLangValue(s2.name).toLowerCase());
        })
    }

    /** Sprawdza, czy użytkownik jest zalogowany do systemu (sesja) */
    _checkLogin(cb?: function) {
        this.publicApi.getSettings().then((settings) => {
            if(!settings) {
                if(this.isLogged) {
                    delete this.user;
                    delete this.rights;
                    delete this.vindication;
                    delete this.difficultDebts;
                    delete this.services;
                    events.emit(Events.Login, false);
                }
                return;
            }
            try {
                const wasLogged = this.isLogged;
                // sortRights(settings.services);
                settings.services.forEach((a: AccessRight) => {
                    if (a.write) a.read = true;
                })
                this.getDictionaries().finally(() => {});

                this.user = settings;
                if (!this.user.language) this.user.language = Languages.pl.code;

                this.rights = new Set<>(this.user.rights);
                this.vindication = settings.services.find(i => i.type === "Vindication");
                this.globalVindication = settings.services.find(i => i.type === "GlobalVindication");
                this.monitoring = settings.services.find(i => i.type === "Monitoring");
                this.invoiceMonitoring = settings.services.find(i => i.type === "InvoiceMonitoring");
                this.difficultDebts = settings.services.find(i => i.type === "DifficultDebts");
                this.debtExchange = settings.services.find(i => i.type === "DebtExchange");
                this.services = settings.services.filter(i => !i.type);
                this._sortServices();
                if (this.services.length === 0) delete this.services;

                {   // ustawienia domyślne/nowe użytkownika, gdy nie zapisane w bazie
                    if (typeof (this.user.userSettings) !== 'object' || this.user.userSettings===null) this.user.userSettings = {};
                    if (typeof (this.user.settings) !== 'object' || this.user.settings===null) this.user.settings = {};
                    Object.keys(userOrganizationDefaults).forEach((k) => {
                        const v = userOrganizationDefaults[k];
                        if (typeof (this.user.settings[k]) === 'undefined') this.user.settings[k] = v;
                    });
                }
                this._insurers=settings.insurers;

                if (!wasLogged) {
                    events.emit(Events.Login, true);
                }
            }catch(err) {
                console.warn("Exception processing login", err);
            }
        }).catch(err => {
            const wasLogged=this.isLogged;
            this.user=null;
            if(wasLogged) {
                events.emit(Events.Login, false);
            }
        }).finally(() => {
            if(!this.ready) {
                this.ready=true;
                events.emit(Events.Ready, true);
            }
            if(cb) cb();
        })
    }

    processLogin() {
        return new Promise(((resolve, reject) => {
            this._checkLogin(() => {
                if(this.user) resolve();
                else reject();
            })
        }))
    }

    get isLogged() { return this.user!=null; }

    init(): Promise<void> {
        return new Promise<void>(((resolve, reject) => {
            if(this.ready) {
                resolve();
            } else {
                this._checkLogin(resolve);
            }
        }))
    }

    logout() {
        return new Promise(((resolve, reject) => {
            this.userApi.logout().then(() => {
                const wasLogged=this.isLogged;
                this.user=null;
                this.rights=new Set<>();
                delete this.vindication;
                delete this.services;
                delete this.monitoring;
                delete this.difficultDebts;
                delete this.debtExchange;
                delete this.eventTypes;
                delete this.invoiceMonitoring;
                delete this._dictionaries;
                delete this._insurers;
                if(wasLogged) {
                    events.emit(Events.Login, false);
                }
                resolve();
            }).catch(reject);
        }))
    }

    updateLanguage(lang: LangInfo): void {
        this.publicApi=remoteProxy(process.env.REACT_APP_SERVER+"/"+lang.code+"/data/public", this._handleErrorCallback);
        this.userApi=remoteProxy(process.env.REACT_APP_SERVER+"/"+lang.code+"/data/user", this._handleErrorCallback);
        if(this._dictionaries) {
            sortLangOptions(this._insurers);
            sortLangOptions(this._dictionaries.insurers);
            sortLangOptions(this._dictionaries.documentTypes);
        }
        this._sortServices();
    }

}

export const store: StoreAPI=new Store();