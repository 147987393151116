//@flow
import React, {useEffect, useState} from 'react';
import {Dialog, IconAlert} from "../lib/Components";
import {useMsgs} from "../lib/Language";
import {store} from "../application";
import {useHistory} from "react-router-dom";

export const RemoteHelpDialog=({}) => {
    const msgs=useMsgs();
    const history=useHistory();
    const [ token, setToken ] = useState<string|null>(null);

    useEffect(() => {
        let ht=null;
        store.userApi.getRemoteHelpToken().then(token => {
            if(!token) {
                history.goBack();
                return;
            }
            ht=token;
            setToken(token);
        });

        return () => {
            if(ht===null) return;
            store.userApi.cancelRemoteHelpToken(ht).finally();
        }
    }, []);

    if(!token) return null;

    return <Dialog
        title={msgs.gui.titleRemoteHelp}
        acceptButton={null}
        onAccept={null}>
        <h3 className="text-center">{msgs.gui.remoteHelpToken}</h3>
        <h1 className="display-1 text-center text-monospace">{token.substr(0, 3)+" "+token.substr(3)}</h1>
        <p className="text-info">{msgs.gui.remoteHelpInfo}</p>
    </Dialog>;
}
RemoteHelpDialog.url="/remote_help";