//@flow
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    Cell,
    CellProps,
    Column,
    ColumnInstance,
    HeaderProps,
    Hooks,
    Renderer,
    Row,
    TableInstance,
    useFilters,
    UseFiltersColumnProps,
    UseFiltersInstanceProps,
    useRowSelect,
    useSortBy,
    UseSortByColumnProps,
    UseSortByInstanceProps,
    useTable
} from 'react-table';
import type {FieldFilter, Option, TableQuery, TableResult} from "../api";
import {EmptyCheckboxInput} from "./Components";
import {Button, Form, Table} from "react-bootstrap";
import {formatString, getLangValue, langCompare, useMsgs} from "./Language";
import {
    formatTax,
    getLocationState,
    nullifyString,
    replaceHistoryState,
    stringCompare,
} from "./Utils";
import {DateInput, endOfDay, formatDate, formatDateTime} from "./DateTime";
import Select from "react-select";
import BigNumber from "bignumber.js";
import type {FilterQuery} from "./Filter";
import {Ref} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useEventTrigger} from "./Events";
import {UseRowSelectInstanceProps} from "react-table";
import {useQueryClient} from "@tanstack/react-query";
import {useRpcQuery} from "./QueryUtils";


/** Uproszczona definicja kolumny */
export type DataTableColumn<T, V> = {
    /** Wymagany, gdy accessor jest funkcją */
    id?: string;
    accessor: $Keys<V>|(row: T, rowIndex: number) => any;
    Header: string|Renderer<HeaderProps<T>>;
    Cell: Renderer<CellProps<T, V>>;
    filter?: 'text'|'date'|'select'|'multiselect'|'number'|'function'|null,
    disableSortBy?: boolean;
    /** Opcje w przypadku filtra typu select */
    options?: Array<Option>;
}

export type DataTableInstance = TableInstance & UseFiltersInstanceProps & UseSortByInstanceProps;

export type DataTableColumnInstance = ColumnInstance & UseSortByColumnProps & UseFiltersColumnProps & {
    className?: string;
}

let cbId=0;

/** Hook do obsługi zaznaczania wierszy */
function rowSelectionHook(hooks: Hooks) {
    const id="ts_"+String(cbId++);
    hooks.visibleColumns.push(columns => [
        {
            id: 'selection',
            className: "row-select",
            Header: ({ getToggleAllRowsSelectedProps }: HeaderProps) => (
                <EmptyCheckboxInput id={id} {...getToggleAllRowsSelectedProps()}/>
            ),
            Cell: ({ row }: Cell) => (
                <EmptyCheckboxInput id={id+'_'+row.original.id} {...row.getToggleRowSelectedProps()}/>
            )
        }, ...columns])
}

const optionValue=(option: Option) => option.value;
const optionLabel=(option: Option) => option.label;

const NumberInput = ({ value, onChange }) => {
    return <Form.Control
        type="number"
        value={typeof(value)==='number'?value:(value || "")}
        onChange={e => onChange(nullifyString(e.target.value))}
    />
}

function fixValue(val: any): string|number|null {
    if(val===null || typeof(val)==='string' || typeof(val)==='number') return val;
    return null;
}

const DataTableFilter= ({ col, ...props }: {
    col: DataTableColumnInstance
}) => {
    const msgs=useMsgs();
    switch (col.filter) {
        case "text":
            return <Form.Control
                type="text"
                value={col.filterValue || ""}
                onChange={e => {
                    col.setFilter(nullifyString(e.target.value));
                }}
            />
        case "number":
            return <NumberInput
                value={col.filterValue}
                onChange={val => col.setFilter(val)}
            />
        case "range": {
            const [from, to] = col.filterValue || [];
            return <div className="range-filter">
                <span>{msgs.gui.labelFrom}</span>
                <NumberInput
                    type="number"
                    value={from}
                    onChange={(from) => {
                        if(from===null && to===null) col.setFilter(null);
                        else col.setFilter([ from, fixValue(to) ])
                    }}
                />
                <span>{msgs.gui.labelTo}</span>
                <NumberInput
                    type="number"
                    value={to}
                    onChange={(to) => {
                        if(from===null && to===null) col.setFilter(null);
                        else col.setFilter([ fixValue(from), to ])
                    }}
                />
            </div>;
        }
        case "date": {
            const [from, to] = col.filterValue || [];
            return <div className="date-filter">
                <span>{msgs.gui.labelFrom}</span>
                <DateInput
                    value={from}
                    onChange={(from) => {
                        if (!from && !to) col.setFilter(null);
                        else col.setFilter([from, to || null]);
                    }}
                />
                <span>{msgs.gui.labelTo}</span>
                <DateInput
                    value={to}
                    onChange={(to) => {
                        if (!from && !to) col.setFilter(null);
                        else col.setFilter([from, to || null]);
                    }}
                    convert={endOfDay}
                    // popperPlacement="auto"
                />
            </div>
        }
        case "multiselect":
            return <Select
                value={col.filterValue || []}
                onChange={(values) => {
                    if(!Array.isArray(values) || values.length===0) col.setFilter(null);
                    else col.setFilter(values);
                }}
                isMulti
                options={col.options}
                getOptionLabel={optionLabel}
                getOptionValue={optionValue}
                menuPortalTarget={document.getElementById("root")}
            />
        case "select":
            return <Form.Control
                as="select"
                custom
                value={col.filterValue || ""}
                onChange={(e) => {
                    if(!e.target.value) col.setFilter(null);
                    else col.setFilter(e.target.value)
                }}
            >
                <option value=""></option>
                {col.options.map((o: Option) => <option key={o.value} value={o.value}>{o.label}</option>)}
            </Form.Control>

        default:
            return null;
    }
}

export type DataTableRowSelection = { [string]: boolean };

export type DataTableAPI = {
    clearSelection: () => void;
}

export type DataTableProps<T> = {
    className?: string;
    /** Nazwa tabeli */
    name?: string;
    columns: Array<DataTableColumn<T, any>>;
    data: (query: TableQuery) => Promise<TableResult<T>>;
    /** Funkcja wywoływana, gdy zaznaczone wiersze się zmienią. Zbiór jest zawsze ten sam (w sensie instancja obiektu) */
    onSelectionChange?: (selected: DataTableRowSelection) => void;
    /** Funkcja klikania na cały wiersz */
    onRowClick?: (row: T) => void;
    onReady?: (table: DataTableInstance) => void;
    /** Liczba wierzy wyświetlanych domyślnie */
    defaultLimit?: number;
    /** Liczba doczytywanych wierszy */
    rowsLoad?: number;
    /** Czy ukryć wiersz z filtrami */
    hideFiltersHeader?: boolean;
    /** Filtry dostarczane z zewnątrz */
    customFilters?: Array<FieldFilter>|FieldFilter|FilterQuery;
    /** Czas opóźnienia filtrowania, domyślnie 800 ms */
    delayFilter?: number|null;
    /** Czas wersja/danych do wymuszania odświeżenia */
    dataTimestamp?: number;
    /** Funkcja pobierania identyfikatora z wiersza; domyślnie "id" */
    getRowId?: (row: T, relativeIndex: number, parent?: any) => string;
    /** Dodatkowa klasa dla wiersza danych */
    rowClassName?: (row: T) => string;
    /** Dodatkowe rzeczy pod tabelą */
    tableFooter?: React$Node;
    /** Dodatkowy wiersz (TR!) na końcu tabeli, który jest doklejany, gdy jest */
    summaryRow?: React$Node;
    /** Domyślna kolumna sortowania */
    initialSortBy?: string;
    /** Opcjonalna referencja do query, której aktualnie używa komponent tabelki */
    queryRef?: Ref<TableQuery>;
    /** Nazwa stanu w historii/lokalizacji dla tej tabeli */
    historyState?: string;
    /** Udostępnione rzeczy przez komponent tabeli */
    apiRef?: Ref<DataTableAPI>;
    /** Zdarzenia na jakie ma tabela nasłuchiwać */
    events?: string|Array<string>;
    /** Czy zablokowane wczytywanie więcej pozycji */
    loadMoreDisabled?: boolean;
    /** Czy wyłączyć sortowanie */
    disableSort?: boolean;
}

function defaultGetRowId(row: { id: string }, relativeIndex: number): string {
    if(!row || typeof(row.id)==='undefined') return relativeIndex;
    return row.id;
}

function sortingClassName(column: UseSortByColumnProps): string {
    if(!column.canSort) return "";
    if(column.isSorted) {
        if(column.isSortedDesc) {
            return "sorting sorted sorted-desc";
        } else {
            return "sorting sorted sorted-asc"
        }
    } else return "sorting";
}

export default function DataTable<T> (
    {
        columns, onReady, data, onSelectionChange, onRowClick, disableSort,
        defaultLimit, rowsLoad, className, name, customFilters,
        delayFilter, dataTimestamp, getRowId, summaryRow, initialSortBy,
        queryRef, historyState, apiRef, events, rowClassName, loadMoreDisabled,
        ...props
    }: DataTableProps) {
    /** Dane pobrane z serwera */
    const [ serverData, setServerData ] = useState<Array<T>|null>(null);
    const [ itemsCount, setItemsCount ] = useState<number|null>(null);
    const [ delayedFilter, setDelayedFilter ] = useState<number>(null);
    const [ limit, setLimit ] = useState(defaultLimit || 25);
    const eventsChange=useEventTrigger(events);
    const msgs=useMsgs();
    const location=useLocation();
    const history=useHistory();

    const hasFilters=!!columns.find((c: Column) => !!c.filter);
    let plugins=[];
    if(hasFilters) {
        plugins.push(useFilters);
    }
    plugins.push(useSortBy);
    if(onSelectionChange) {
        plugins.push(useRowSelect);
        plugins.push(rowSelectionHook);
    }

    const tableInitialState = useMemo(() => {
        let res={ };

        let hist=getLocationState(location, historyState);
        if(hist && Array.isArray(hist.sortBy) && hist.sortBy.length>0) {
            res.sortBy=hist.sortBy;
        } else
            {
            if (initialSortBy) {
                if (initialSortBy.startsWith("-")) {
                    res.sortBy = [{id: initialSortBy.substring(1), desc: true}];
                } else {
                    res.sortBy = [{id: initialSortBy, desc: false}];
                }
            }
        }
        if(hist && hist.filters) res.filters=hist.filters;
        return res;
    }, [ initialSortBy ]);

    const table: TableInstance & UseRowSelectInstanceProps = useTable({
        columns: columns,
        data: serverData===null?[]:serverData,
        defaultCanFilter: false,
        autoResetFilters: false,
        autoResetSelectedRows: false,
        // defaultColumn: defaultColumn,
        // filterTypes: filterTypes,
        disableMultiSort: true,
        manualFilters: true,
        autoResetSortBy: false,
        autoResetPage: false,
        manualPagination: true,
        manualSortBy: true,
        // defaultCanSort: true,
        disableSortRemove: true,
        getRowId: getRowId || defaultGetRowId,
        initialState: tableInitialState,
    }, ...plugins);
    useEffect(() => {
        if(onReady) {
            onReady(table);
            return () => onReady(null);
        }
    }, [ onReady, table ]);
    // const loc=useLocation();  // do odświeżania przy zmianach adresu
    // Kiedy ostatni raz pobierano dane
    const lastLoaded=React.useRef<number>(0);

    const { state: { sortBy, filters} } = table;

    const fetchIdRef=React.useRef(0);
    useEffect(() => {
        if(fetchIdRef.current===-1) return;
        let sort;
        if(Array.isArray(sortBy) && sortBy.length>0) {
            const v=sortBy[0];
            if(v.desc) sort='-'+v.id;
            else sort=v.id;
        }
        let query;
        let f=[];
        if(filters) filters.forEach(i => f.push({ field: i.id, value: i.value }));
        if(Array.isArray(customFilters)) f.push(...customFilters);
        else if(typeof(customFilters)==='object' && customFilters!==null) {
            if(typeof(customFilters.text)==='string' && Array.isArray(customFilters.fields)) {    // obiekt typu FilterQuery
                query=customFilters.text;
                f.push(...customFilters.fields);
            } else {    // pojedynczy filtr
                f.push(customFilters);
            }
        }

        const fetchId=++fetchIdRef.current;
        lastLoaded.current=Date.now();
        const tableQuery:TableQuery={
            from: 0,
            items: limit,
            sort: sort,
            filters: f.length>0?f:undefined,
            query
        };
        data(tableQuery).then((res: TableResult<T>) => {
            if(fetchId!==fetchIdRef.current) return;    // były inne zapytania
            if(onSelectionChange) table.toggleAllRowsSelected(false);
            if(res===null) {
                setServerData([]);
                setItemsCount(0);
            } else {
                setServerData(res.data);
                setItemsCount(res.items);
            }
        });
        if(queryRef) queryRef.current=tableQuery;
    }, [ data, sortBy, limit, delayedFilter, dataTimestamp, eventsChange ]);
    useEffect(() => {
        return () => fetchIdRef.current=-1;
    }, []);

    // Obsługa zapamiętywania stanu w historii
    useEffect(() => {
        replaceHistoryState(history, location, historyState, {
            sortBy, filters
        });
    }, [ historyState, sortBy, filters ]);

    useEffect(() => {
        // console.log("State", table.state);
        if(onSelectionChange) onSelectionChange(table.state.selectedRowIds);
    }, [ onSelectionChange, table.state.selectedRowIds ]);

    // Opóźnienie dla filtrowania
    const timerRef=React.useRef(null);
    // Kiedy było wywołane opóźnione odświeżenie
    const delayedInvocation=React.useRef<number>(0);
    useEffect(() => {
        const time=typeof(delayFilter)==='number'?delayFilter:(delayFilter===null?0:800);
        if(time>0) {
            delayedInvocation.current=Date.now();
            if (timerRef.current) window.clearTimeout(timerRef.current);
            timerRef.current = window.setTimeout(() => {
                // console.log("LastLoaded: ", lastLoaded.current, " DelayedInvocation: ", delayedInvocation.current);
                if(lastLoaded.current+20>=delayedInvocation.current) return;
                setDelayedFilter(Date.now())
            }, time);
        } else {
            setDelayedFilter(Date.now());
        }
    }, [ customFilters, filters, delayFilter ]);


    let tableFooter=null;
    if(serverData===null) {
        tableFooter=<p key="loading" className="text-muted">{msgs.gui.dataTableLoading}</p>;
    } else if(serverData.length===0) {
        tableFooter=<p key="empty" className="text-info">{msgs.gui.dataTableEmpty}</p>;
    } else if(serverData.length<itemsCount) {
        tableFooter=<div className="load-more" key="load-more">
            <Button variant="outline-secondary" disabled={loadMoreDisabled} onClick={() => setLimit(limit+(typeof(rowsLoad)==='number'?rowsLoad:25))}>{msgs.gui.dataTableLoadMore}</Button>
            <p className="text-muted">{formatString(msgs.gui.tableItemsLeft, itemsCount-serverData.length)}</p>
        </div>;
    }

    if(apiRef) {
        const api:DataTableAPI={
            clearSelection: () => {
                if(table.toggleAllRowsSelected) table.toggleAllRowsSelected(false);
            }
        }
        apiRef.current=api;
    }

    return <div className={"datatable "+(className||"")}>
        <div className="datatable-content">
            <Table>
                <thead>
                    <tr className="header">
                        {table.allColumns.map((col:DataTableColumnInstance) => (<th
                            {...col.getHeaderProps(disableSort?undefined:col.getSortByToggleProps({
                                className: sortingClassName(col)+" "+(col.className || ""),
                                title: col.canSort?msgs.gui.actionClickToSort:undefined,
                            }))}
                        >{col.render('Header')}</th>))}
                    </tr>
                    {(hasFilters && !props.hideFiltersHeader)?<tr className="filters">
                        {table.allColumns.map((col: DataTableColumnInstance) => (<th
                            key={col.id}
                        ><DataTableFilter col={col}/></th>))}
                    </tr>:null}
                </thead>
                <tbody>
                {table.rows.map((row: Row<T>) => {
                    table.prepareRow(row);
                    let className=onRowClick?"clickable":null;
                    if(rowClassName) {
                        let c=rowClassName(row.original);
                        if(c) {
                            if(className) className+=" "+c;
                            else className=c;
                        }
                    }
                    return <tr
                        {...row.getRowProps({
                            className,
                            onClick: onRowClick?(e: SyntheticEvent) => {
                                // Ignorowanie kliknięcia w komórkę z zaznaczaniem elementów, bo robi się niefajnie
                                let el: HTMLElement=e.target;
                                if(el.tagName==="BUTTON") return;   // przycisk powinien mieć własną obsługę
                                while(el && el.tagName!=='TR') {
                                    if(el.tagName==='TD') {
                                        if(el.className.indexOf('row-select')!==-1) {
                                            return;
                                        }
                                    }
                                    el=el.parentElement;
                                }
                                window.setTimeout(() => {
                                    if(window.getSelection) {   // obsługa zaznaczania tekstu
                                        const sel=window.getSelection();
                                        if(sel.type==='Range') return;
                                        // const text=sel.toString();
                                        // if(typeof(text)==='string' && text.length>0) return;
                                    }
                                    onRowClick(row.original)
                                }, 0);
                            }:null
                        })}
                    >{row.cells.map((cell: Cell<T>) => <td
                        {...cell.getCellProps({
                            className: (cell.column.isSorted?"sorted ":"")+(cell.column.className ||"")
                        })}
                    >{cell.render('Cell')}</td>)}
                    </tr>;
                })}
                {summaryRow}
                </tbody>
            </Table>
        </div>
        <div className="table-footer">
            {tableFooter}
            {props.tableFooter}
        </div>
        {/*{tableFooter?<div className="table-footer">{tableFooter}</div>:null}*/}
    </div>;
}

export type AllDataTableColumn<T, V> = DataTableColumn<T, V> & {
    type: "string"|"number"|"date"|"langString"|"money"
}

export type AllDataTableProps<T> = $Diff<DataTableProps<T>, {
    // usuwamy nieużywane/przeciążane opcje
    columns: Array<DataTableColumn<T, any>>;
    data: (query: TableQuery) => Promise<TableResult<T>>;
    defaultLimit?: number;
    rowsLoad?: number;
}> & {
    columns: Array<AllDataTableColumn<T, any>>;
    data: () => Promise<Array<T>>;
};

function getter(accessor: string|(item: any) => any): (item: any) => any {
    if(typeof(accessor)==="function") {
        return accessor;
    } else {
        return (item) => {
            if(typeof(item)==='object') return item[accessor];
            return null;
        }
    }
}

function findColumn<T>(columns: Array<AllDataTableColumn<T, any>>, name: string): AllDataTableColumn<T, any>|undefined {
    return columns.find((c: AllDataTableColumn) => c.id===name || c.accessor===name);
}

/**
 * Funkcja przetwarzająca po stronie przeglądarki tablicę danych i zwracająca wynik
 * zgodny z wymaganiami.
 */
function processData<T>(columns: Array<AllDataTableColumn<T, any>>, data: Array<T>, query: TableQuery):TableResult<T> {
    if(data===null || data.length===0) return { items: 0, data: [] };
    let items: Array<T>;
    if(!Array.isArray(query.filters) || query.filters.length===0) { // brak filtrów
        items=[...data];
    } else {    // są filtry, trzeba przetworzyć
        let filterFunc: Array<(row: T)=> boolean>=[];
        query.filters.forEach((f: FieldFilter) => {
            if(f.value===null) return;
            // Specjalny rodzaj filtra: funkcja. Jest niezależna od nazwy kolumny i zawsze dodawany do wykonania
            if(f.field==="" && typeof(f.value)==='function') {  // zawsze wykonujemy
                filterFunc.push(f.value);
                return;
            }
            const col=findColumn(columns, f.field);
            // console.log("Filter", f, col);
            if(!col) {
            }

            switch(col.filter) {
                case "function": {
                    const custom=f.value;
                    if(typeof(custom)!=='function') return;
                    const field=getter(col.accessor);
                    filterFunc.push((row) => {
                        const v=field(row);
                        return custom(v, row);
                    })
                    break;
                }
                case "string":
                case "text": {
                    const value=String(f.value).toLowerCase();
                    if(value.length===0) return;
                    const field=getter(col.accessor);
                    filterFunc.push((row: T) => {
                        const v = field(row);
                        if (v === null) return false;
                        return String(v).toLowerCase().includes(value);
                    });
                    break;
                }
                case "number": {
                    if(Array.isArray(f.value)) {
                        const field=getter(col.accessor);
                        let [ from, to ] = f.value;
                        // console.log("Filter ", from, to);
                        if(from || to ){
                            filterFunc.push((row) => {
                                const v=field(row);
                                return v!==null && (!from || v>=from) && (!to || v<=to);
                            })
                        }
                    }
                    break;
                }
                case "date": {
                    if(Array.isArray(f.value)) {
                        const field=getter(col.accessor);
                        let [ from, to ] = f.value;
                        // console.log("Filter ", from, to);
                        if(from || to ){
                            filterFunc.push((row) => {
                                const v=field(row);
                                return v!==null && (!from || v>=from) && (!to || v<=to);
                            })
                        }
                    }
                    break;
                }
                case "select":
                case "multiselect": {
                    const field=getter(col.accessor);
                    if (Array.isArray(f.value) && f.value.length>1) {
                        filterFunc.push((row: T) => {
                            const v=field(row);
                            return v!==null && f.value.includes(v);
                        });
                    } else {
                        let item=f.value;
                        if(Array.isArray(item)) item=item[0];   // jeden element
                        if(item) {
                            console.log("Filter for value: ", item);
                            filterFunc.push((row: T) => field(row)===item)
                        }
                    }
                    break;
                }
                default:
                    console.warn("Unimplemented filter for type: ", col.filter);
            }
        });
        if(filterFunc.length>0) {
            items = data.filter((row: T) => {
                for(let i=0;i<filterFunc.length;++i) if(!filterFunc[i](row)) return false;
                return true;
            })
        } else {
            items=[...data];
        }
    }
    // sortowanie
    if(typeof(query.sort)==='string' && query.sort.length>0) {
        let field=query.sort;
        let desc=false;
        if(field.startsWith("-")) {
            desc=true; field=field.substring(1);
        }
        const col=findColumn(columns, field);
        if(col) {
            const vg=getter(col.accessor);
            let cmpFunc;

            switch(col.type) {
                case "number":
                    cmpFunc=(v1, v2) => v1-v2;
                    break;
                case "date":
                    cmpFunc=stringCompare;
                    break;
                case "money":
                    cmpFunc=(v1, v2) => {
                        const b1=new BigNumber(v1);
                        const b2=new BigNumber(v2);
                        return b1.comparedTo(b2);
                    }
                    break;
                case "langString":
                    cmpFunc=(v1, v2) => langCompare(v1, v2);
                    break;
                case "string":
                default:
                    cmpFunc=(v1, v2) => String(v1).localeCompare(String(v2));
                    break;
            }
            if (desc) {
                items.sort((r1, r2) => cmpFunc(vg(r2), vg(r1)));
            } else {
                items.sort((r1, r2) => cmpFunc(vg(r1), vg(r2)));
            }
        }
    }
    const count=items.length;   // liczba elementów przed odcięciem

    // Odcięcie danych zgodnie z wymaganiami zapytania
    if(items.length>query.items || query.from>0) {
        items=items.slice(query.from, Math.min(items.length, query.items-query.from));
    }
    // console.log("Processed items for query: ", query, items);

    return { items: count, data: items };
}

/**
 * Uproszczona tabela DataTable, do tego, że pobiera dane z serwera
 * jako całość i reszta operacji (filtrowanie, sortowanie) jest realizowane po stronie
 * przeglądarki.<br/>
 * Przygotowane na potrzeby małych źródeł danych (np. do kilkuset elementów), aby
 * nie implementować wszystkich funkcji po stronie serwera (sortowanie, filtrowanie)
 * oraz ograniczyć ilość zapytań.<br/>
 * Komponent bazuje na zwykłym komponencie DataTable z tym, że
 * przeciąża funkcję pobierania danych.
 */
export function AllDataTable<T>({ columns, data, dataTimestamp, delayFilter, ...props }: AllDataTableProps<T>) {
    // Wczytane dane z serwera
    const memory=useRef<Array<T>|null>(null);
    const ds=useRef<number|null>();
    // Nowa przeciążona funkcja danych
    const dataFunc=useCallback((query: TableQuery) => {
        return new Promise<TableResult<T>>((resolve, reject) => {
            if(memory.current===-1) return;
            if(memory.current===null || ds.current!==dataTimestamp) {
                console.log("Loading data from server", memory.current, ds.current, dataTimestamp);
                // console.trace();
                data().then(loaded => {
                    if(memory.current===-1) return;
                    memory.current=loaded;
                    ds.current=dataTimestamp;
                    // console.log("Loaded: ", loaded);
                    resolve(processData(columns, loaded, query))
                }).catch(reject);
            } else {    // gdy dane już są i nie wymagają odświeżenia
                resolve(processData(columns, memory.current, query));
            }
        });
    }, [ data, dataTimestamp ]);
    useEffect(() => {
        return () => memory.current=-1;
    }, []);

    return <DataTable
        dataTimestamp={dataTimestamp}
        columns={columns}
        data={dataFunc}
        delayFilter={typeof(delayFilter)==="number"?delayFilter:200}
        defaultLimit={10000}
        rowsLoad={10000}
        {...props}
    />
}

export type ClientDataTableProps<T> = $Diff<DataTableProps<T>, {
    // usuwamy nieużywane/przeciążane opcje
    data: () => Promise<Array<T>>;
}> & {
    data: Array<T>|null
};

export function ClientDataTable<T>({ columns, data, delayFilter, rowsLoad, defaultLimit, ...props }: AllDataTableProps<T>) {
    // Nowa przeciążona funkcja danych
    const dataFunc=useCallback((query: TableQuery) => {
        return new Promise<TableResult<T>>((resolve, reject) => {
            if(!Array.isArray(data)) {
                resolve({ data: null, items: -1 } )
            } else {    // gdy dane już są i nie wymagają odświeżenia
                resolve(processData(columns, data, query));
            }
        });
    }, [ data ]);

    return <DataTable
        columns={columns}
        data={dataFunc}
        delayFilter={typeof(delayFilter)==="number"?delayFilter:200}
        defaultLimit={typeof(defaultLimit)==='number'?defaultLimit:10000}
        rowsLoad={typeof(rowsLoad)==='number'?rowsLoad:10000}
        {...props}
    />
}

/**
 * Pomocniczy komponent, który wyświetla dane z opcją pobrania więcej.
 */
export function DataView<T>({ value, children, onLoadMore, empty, contentWrapper, disabled }: {
    value: TableResult<T>,
    onLoadMore: () => void,
    children: (item: T, index: number) => React$Node|string|null;
    empty?: React$Node;
    contentWrapper?: (content: React$Node) => React$Node;
    disabled?: boolean;
}) {
    const msgs = useMsgs();
    if (!value || !Array.isArray(value.data)) return null;
    if (value.data.length === 0) {
        if(empty) return empty;
        return <p className="text-info">{msgs.gui.dataTableEmpty}</p>;
    }
    const left=value.items-value.data.length;
    let res=<>
        {value.data.map(children)}
        {left>0?<div className="load-more">
            <Button variant="outline-secondary" disabled={disabled} onClick={onLoadMore}>{msgs.gui.dataTableLoadMore}</Button>
            <p className="more-info text-muted">{formatString(msgs.gui.dataTableDataCount, left)}</p>
        </div>:null}
    </>;
    if(contentWrapper) return contentWrapper(res);
    else return res;
}

export function dateCell({ value }) {
    return formatDate(value);
}

export function dateTimeCell({ value }) {
    return formatDateTime(value);
}

export function taxCell({ value }) {
    return formatTax(value);
}

export function langCell({ value }) {
    return getLangValue(value);
}

export type QueryDataTableProps<T> = $Diff<DataTableProps<T>, { data: any }> & {
    path: string, func: string; params: Array<any>
};

export function QueryDataTable<T>({path, func, data, params, ...props}: QueryDataTableProps<T>) {
    const q=useQueryClient();
    const d=useCallback(async (query: TableQuery) => {
        let key=[ "rpc", path, func ];
        if(Array.isArray(params)) key.push([...params, query]);
        else if(params!==undefined) key.push([ params, query ]);
        else key.push([ query ]);
        // console.log("RPCQuery", query, key);
        return await q.fetchQuery(key);
    }, [ q, path, func, params ]);
    return <DataTable
        data={d}
        {...props}
    />
}