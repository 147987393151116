//@flow
import React from 'react';
import {useMsgs} from "../lib/Language";
import {Breadcrumb} from "react-bootstrap";
import {BreadcrumbItem, PageHeader} from "../lib/Components";
import DesktopScreen from "./DesktopScreen";
import InvoiceMonitoringDesktopScreen from "./InvoiceMonitoringDesktopScreen";
import {VindicationsTable} from "./VindicationsScreen";
import type {FilterQuery} from "../lib/Filter";
import {store} from "../application";

const graceVindicationsFilter: FilterQuery= [
    {
        field: 'status',
        value: 'Grace'
    }];

/**
 * Ekran z listą windykacji w okresie karencji.
 */
const InvoiceMonitoringVindications = () => {
    const msgs=useMsgs();

    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
            <BreadcrumbItem to={InvoiceMonitoringDesktopScreen.link()}>{msgs.gui.menuInvoiceMonitoringDesktop}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.gui.menuInvoiceMonitoringVindications}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.gui.menuInvoiceMonitoringVindications}/>
        <VindicationsTable
            data={store.userApi.queryVindications}
            customFilters={graceVindicationsFilter}
            historyState="im-vttable"
            hideStatus={true}
        />
    </>
}
InvoiceMonitoringVindications.url="/im/debtcollection";
InvoiceMonitoringVindications.link=()=>"/im/debtcollection";

export default InvoiceMonitoringVindications;