//@flow
import React, {useCallback} from 'react';

/**
 * Komponent, który wyświetla treść HTML z pewnymi modyfikacjami.
 */
const ExtendedHTML = ({ html, className, onExtraLink }: {
    html: string;
    className?: string;
    onExtraLink?: (e: MouseEvent, target: string) => void;
}) => {
    const handleBind=useCallback((el: HTMLElement) => {
        if(!el) return;
        if(onExtraLink) {
            el.querySelectorAll("a").forEach((a) => {
                if (a.href && a.href.startsWith("https://extra/")) {
                    const target = a.href.substring(14);
                    a.onclick = (e) => {
                        e.preventDefault();
                        if (onExtraLink) onExtraLink(e, target);
                    }
                }
            });
        }
    }, [ onExtraLink ]);
    return <div className={className} ref={handleBind}
         dangerouslySetInnerHTML={{__html: html }}
    />
}
export default ExtendedHTML;