//@flow
// Nakładka na bibliotekę date-fns oraz komponenty do czasu na bazie react-datepicker
import { format, parse } from 'date-fns';
import DatePicker from "react-datepicker";
import {currentLang, useMsgs} from "./Language";
import React, {useMemo} from "react";
import {BForm} from "./Form";

/** Format day dla aplikacji */
export const dateFormat='dd.MM.yyyy';

export function parseDate(str: string|null|undefined): Date|null {
    if(str===null || str===undefined || str==='') return null;
    if(typeof(str)==='number') return new Date(str);
    else if(str instanceof Date) return str;
    const res=parse(str, "yyyy-MM-dd'T'HH:mm:ss", new Date());
    if(isNaN(res.getTime())) return null;
    return res;
}

export function formatDate(d: Date|string, f :? string): string|null {
    if(d===null || d===undefined || d==='') return null;
    if(typeof(d)==='string') d=parseDate(d);
    if(typeof(f)!=='string') f='dd.MM.yyyy'
    return format(d, f);
}

export function formatDateTime(d: Date|string, f :? string): string {
    if(d===null || d===undefined || d==='') return null;
    if(typeof(d)==='string') d=parseDate(d);
    if(typeof(f)!=='string') f='dd.MM.yyyy HH:mm'
    return format(d, f);
}

export function formatTime(d: Date|string, f :? string): string {
    if(d===null || d===undefined || d==='') return null;
    if(typeof(d)==='string') d=parseDate(d);
    if(typeof(f)!=='string') f='HH:mm'
    return format(d, f);
}

/**
 * Formatuje czas do postaci zbliżonej do ISO tylko bez strefy czasowej.
 */
export function formatJSON(d: Date|string|null): string|null {
    if(d===undefined || d===null || d==='') return null;
    if(typeof(d)==='string') d=parseDate(d);
    return format(d, "yyyy-MM-dd'T'HH:mm:ss");
}

/**
 * Pomocnicza funkcja, która zwraca datę z końcem dnia
 * @param d
 */
export function endOfDay(d: Date|null): Date|null {
    if(d===null || d===undefined) return null;
    let c=new Date(d.getTime());
    c.setHours(23, 59, 59, 999);
    return c;
}

/**
 * Pomocnicza funkcja, która zwraca datę z końcem dnia
 * @param d
 */
export function startOfDay(d: Date|null): Date|null {
    if(d===null || d===undefined) return null;
    let c=new Date(d.getTime());
    c.setHours(0, 0, 0, 0);
    return c;
}

/**
 * Komponent do ustawiania daty
 */
export const DateInput = ({ value, onChange, className, convert, isInvalid, ...props }: {
    value: string|null;
    onChange: (newValue: string) => void;
    className?: string;
    convert?: (val: Date|null) => Date|null;
    isInvalid?: boolean;
}) => {

    try {
        return <DatePicker
            className={"form-control " + (isInvalid?"is-invalid ":"") + (className ? className : "")}
            locale={currentLang.dateFns}
            selected={parseDate(value)}
            onChange={(d) => {
                if(convert) d=convert(d);
                if(d) {
                    if (d.getFullYear() < 1000 || d.getFullYear() > 3000) d = null;
                    else d.setHours(0, 0, 0, 0);    // zerujemy, bo czasami, gdy wciśnie się enter, to jest aktualny czas i jest błąd #5379
                }
                onChange(formatJSON(d));
            }}
            ref={ref => {
                if(ref && ref.input && props.tabIndex && props.tabIndex>0) {
                    ref.input.onkeydown=(e: KeyboardEvent) => {
                        if(e.keyCode===9) ref.setOpen(false);
                    }
                }
            }}
            dateFormat="dd.MM.yyyy"
            // customInput={<Input/>}
            {...props}
        />
    }catch(e) {
        console.warn("Exception for DateInput", value);
    }
}


/**
 * Czas w ciągu dnia.
 * Albo numer minut w ciągu dnia, albo godzina i minuta.
 *  */
export type TimeType = number|[ number, number ]|Date;

/**
 * Zamiana godziny i minuty na czas w dniu (minuta dnia).
 * @param time godzina lub minuta dnia
 * @param minute minuta
 * @return {number} minuta dnia
 */
export function toDayMinute(time: TimeType, minute?: number): number|null {
    if(Array.isArray(time)) return time[0]*60+time[1];
    if(typeof(time)==='number' && typeof (minute)==='number') return time*60+minute;
    if(typeof(time)==='number') return time;
    if(time instanceof Date) return time.getHours()*60+time.getMinutes();
    return null;
}

/**
 * Konwersja z minut w dniu na godzinę i minutę.
 * @param time czas lub godzina dnia, gdy użyte z argumentem minute
 * @param minute minuta w danej godzinie
 * @return {[ number, number ]} godzina i minuta
 */
export function toDayTime(time: TimeType, minute?: number): [ number, number ]|null {
    if(Array.isArray(time)) return time;
    if(typeof(time)==='number') {
        if(typeof(minute)==='number') return [ time, minute ];
        const hour=Math.floor(time/60);
        return [ hour, time-hour*60 ];
    }
    if(time instanceof Date) return [ time.getHours(), time.getMinutes() ];
    return null;
}

/**
 * Prosty komponent do wyboru czasu w postaci minut w danym dniu.
 */
export const TimeSelect = ({ value, onChange, from, to, step, nullable, ...props }: {
    /** Aktualna wartość */
    value: number;
    onChange: (value: number) => void;
    /** Czy możliwa jest wartość null true lub tekst do wyświetlenia dla null *
    nullable?: boolean|string;
    /** Czas od */
    from: TimeType;
    /** Czas do <= */
    to: TimeType;
    /** Krok w minutach */
    step?: number;
}) => {
    const msgs=useMsgs();
    if(typeof(step)!=='number') step=15;
    let fm=toDayMinute(from), tm=toDayMinute(to);
    if(typeof(fm)!=='number') fm=0;
    if(typeof(tm)!=='number') tm=24*60;

    const options=useMemo(() => {
        let res=[];
        for(let i=fm;i<=tm;i+=step) {
            const [ h, m ] = toDayTime(i);
            res.push(<option key={i} value={i}>{h.toString().padStart(2, '0')+':'+m.toString().padStart(2, '0')}</option>)
        }
        return res;
    }, [ fm, tm, step ]);

    return <BForm.Control
        as="select"
        value={typeof(value)!=='number'?"":value.toString()}
        onChange={e => {
            if(e.target.value==="") onChange(null);
            else onChange(parseInt(e.target.value))
        }}
        {...props}
    >
        {nullable && <option value="">{typeof(nullable)==='string'?nullable:msgs.gui.optionSelect}</option>}
        {options}
    </BForm.Control>
}