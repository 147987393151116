//@flow
// Kwestie związane z React-Query
import React from 'react';
import type {QueryFunction} from "@tanstack/react-query";
import {QueryClient, useQuery} from "@tanstack/react-query";
import type {QueryFunctionContext, UseQueryOptions, UseQueryResult} from "@tanstack/react-query";
import {Spinner} from "react-bootstrap";

/**
 * Funkcja opakowująca obiekt do wywołań ReactQuery.
 * Pierwszym parametrem dla queryKey ma być nazwa funkcji, a dalej parametr filtrowania
 */
export function tableQueryWrapper<T>(obj: T): QueryFunction {
    return async ({ queryKey }) => {
        if(queryKey.length===1) return await obj[queryKey[0]]();
        else if(queryKey.length===2) return await obj[queryKey[0]](queryKey[1]);
        throw new Error("Illegal state");
    }
}

async function defaultQueryFunction({ queryKey }: QueryFunctionContext) {
    if(queryKey.length!==4) return;
    if(queryKey[0]!=='rpc') return;
    const path=queryKey[1];
    const fun=queryKey[2];
    const payload=JSON.stringify(queryKey[3]);
    const res=await fetch(process.env.REACT_APP_SERVER+"/data"+path+"/"+fun, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            // 'Accept': 'application/json',
        },
        body: payload
    });
    if(res.status===200) {
        return await res.json();
    }
    throw new Error("Invalid response from server "+res.status+" "+res.statusText);
}

export function createAppQueryClient() {
    return new QueryClient({
        defaultOptions: {
            queries: {
                queryFn: defaultQueryFunction,
            }
        }
    });
}

export const staticDictionary: UseQueryOptions = {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
}

export type RpcPath=string|"/public"|"/user";

export function useRpcQuery<T, R>(path: RpcPath, func: $Keys<T>, params?: any, options?: UseQueryOptions): UseQueryResult<R> {
    let key;
    if(params===undefined) key=[ "rpc", path, func, [] ];
    else if(Array.isArray(params)) key=[ "rpc", path, func, params ];
    else key=[ "rpc", path, func, [ params ]]
    return useQuery(key, options);
}

export function useRpcStaticQuery<T, R>(path: RpcPath, func: $Keys<T>, params?: any): UseQueryResult< R > {
    let key;
    if(params===undefined) key=[ "rpc", path, func, [] ];
    else if(Array.isArray(params)) key=[ "rpc", path, func, params ];
    else key=[ "rpc", path, func, [ params ]]
    return useQuery(key, staticDictionary);
}

export const QueryLoader = ({ query, children }: { query: UseQueryResult, children: React$Node|() => React$Node }) => {
    if(!query.isSuccess) return <Spinner animation="border" role="status"/>;
    if(typeof(children)==='function') return children();
    return children;
}