//@flow
/* eslint-disable no-use-before-define */
import React, {useEffect, useMemo, useState} from 'react';
import {Alert, Button, Col, Modal, Row, Form as BForm } from "react-bootstrap";
import msgs, {currentLang, formatString, getLangValue, getMessage, LangContext, LangLink, langLink, useMsgs} from "../lib/Language";
import {AuthInfoScreen, AuthPage, Icon, IconButton} from "../lib/Components";
import type {AgreementInfo, RegisterCompanyInfo, RegisterForm} from "../api";
import {RegisterCompanyInfoInitial, RegisterCompanyInfoValidation, RegisterFormInitial, RegisterFormValidation} from "../api";
import type {RouteChildrenProps} from "react-router";
import {Form} from "../lib/Form";
import {FormikProps} from "formik";
import Validate from "../lib/Validation";
import {store} from "../application";
import {RouteComponentProps} from "react-router";
import {arrayToggleItem} from "../lib/Utils";
import {Ref} from "react";
import {FormikBag} from "formik";
import {FormikHelpers} from "formik";
import LoginScreen from "./LoginScreen";
import {agreementComparator, getAgreementGroupLabel} from "../ClientComponents";
import Cookies from 'js-cookie';
import {Redirect} from "react-router-dom";
import * as FacebookPixel from "../lib/FacebookPixel";

let companyInfo: RegisterCompanyInfo = null;
let registerData: RegisterForm= null;

/**
 * Funkcja sprawdzająca, czy jest w adresie kod promocyjny i jeżeli tak, to zwraca true i zapisuje go w ciasteczku
 */
export function checkPromo(): boolean {
    const search=window.location.search;
    if(!search) return false;
    if(!search.includes("p=")) return false;
    const params = new URLSearchParams(search);
    const promo=params.get("p");
    if(!promo) return false;
    Cookies.set("promo", promo);
    return true;
}

/**
 * Funkcja zwraca zapisany kod promocyjny, gdy jest ustawiony
 */
export function isPromo(): string {
    return Cookies.get('promo');
}

export function removePromo() {
    Cookies.remove('promo');
}

/**
 * Komponent, który renderuje informacje o zgodach
 * @constructor
 */
export const AgreementsView = ({ formik, agreements }: {
    formik: FormikProps<RegisterForm>, agreements: Array<AgreementInfo>
}) => {
    if(agreements===null) return null;
    let res=[];
    let curGroup=null;
    let groupView=null;
    agreements.forEach((a: AgreementInfo) => {
        const error=formik.submitCount && formik.errors["a_"+a.name];
        const group=getAgreementGroupLabel(a);
        if(group!==curGroup) {
            curGroup=group;
            if(group) res.push(<BForm.Group key={'gr_'+a.name}><p dangerouslySetInnerHTML={{ __html: group }}/></BForm.Group>);
        }

        res.push(<BForm.Group
            key={a.name}
            className={group?"pl-5":null}
        >
            <BForm.Check id={"agreement_"+a.name} custom>
                <BForm.Check.Input
                    isInvalid={error}
                    type="checkbox"
                    checked={formik.values.agreements.includes(a.name)}
                    onChange={(e) => {
                        formik.setFieldValue("agreements", arrayToggleItem(formik.values.agreements, a.name));
                    }}
                />
                <BForm.Check.Label dangerouslySetInnerHTML={{ __html: getLangValue(a.info) }}/>
                {error?<BForm.Control.Feedback type="invalid">{getMessage(error)}</BForm.Control.Feedback>:null}
            </BForm.Check>
        </BForm.Group>);
    });
    return res;
}

export const RegisterInput = ({ onComplete, registerButton, formikRef }: {
    onComplete: (helpers: FormikHelpers, value: RegisterForm, companyInfo: RegisterCompanyInfo) => void|Promise,
    registerButton?: boolean,
    formikRef?: Ref<FormikBag>,
}, ref) => {
    const [ agreements, setAgreements ] = useState<Array<AgreementInfo>|null>(null);
    const [ country, setCountry ] = useState<string>(null);
    const msgs=useMsgs();
    const initialValues=useMemo(() => {
        const promo=isPromo();
        if(promo) return {...RegisterFormInitial, promo: promo };
        else return RegisterFormInitial;
    }, []);

    useEffect(() => {
        store.publicApi.getAgreements().then(agreements => setAgreements(agreements.sort(agreementComparator)));
        store.publicApi.getCountry().then(country => setCountry(country));
    }, [])

    if(agreements===null || country===null) return null;
    const lang=currentLang.code;

    return <Form
        formikRef={formikRef}
        initialValues={initialValues}
        validate={RegisterFormValidation}
        customValidation={(values) => {
            const agr:Array<string>=values["agreements"];
            let errors={};
            agreements.forEach((a: AgreementInfo) => {
                if(a.required && !agr.includes(a.name)) errors['a_'+a.name]=msgs.validation.required;
            })
            return errors;
        }}
        initialStatus={{promo: false}}
        className="register-form"
        onSubmit={async (values, helpers) => {
            let res = await store.publicApi.register(values);
            if (Form.setError(helpers, res)) return;
            await onComplete(helpers, values, res);
        }}
    >{(formik: FormikProps<RegisterForm>) => (<>
        <Form.RowFormError/>
        <Row>
            <Col md={6}>
                <Form.Group name="email">
                    <Form.Label>{msgs.gui.labelEmail}</Form.Label>
                    <Form.Email/>
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group name="password">
                    <Form.Label>{msgs.gui.labelPassword}</Form.Label>
                    <Form.Password checkStrength={true}/>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <Form.Group name="name">
                    <Form.Label>{msgs.gui.labelFullName}</Form.Label>
                    <Form.Text/>
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group name="phone">
                    <Form.Label>{msgs.gui.labelPhone}</Form.Label>
                    <Form.Phone/>
                </Form.Group>
            </Col>
        </Row>
        <Form.Group name="tax">
            <Form.Label>{msgs.gui.labelNIP}</Form.Label>
            <Form.NIP/>
        </Form.Group>
        <AgreementsView agreements={agreements} formik={formik}/>
        <Row>
            <Col md={6} className="promo-code">
                {initialValues.promo?"":(formik.status.promo === false ?
                    <Button variant="link" onClick={() => formik.setStatus({promo: true})}
                    >{msgs.gui.registerPromo}</Button>
                    :
                    <Form.Group name="promo">
                        <Form.Text placeholder={msgs.gui.registerPromoHint}/>
                    </Form.Group>
                )}
            </Col>
            <Col md={6} className="text-right">
                {registerButton===false?null:<IconButton
                    onClick={formik.handleSubmit}
                    type="submit"
                    icon={<span className="icon-user"/>}
                >{msgs.gui.actionRegister}</IconButton>}
            </Col>
        </Row>
    </>)}
    </Form>
}

export default class RegisterScreen extends React.Component<RouteChildrenProps, void> {
    static url="/register";

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        FacebookPixel.trackSinglePageView();
    }

    render() {
        if(checkPromo()) return <Redirect to={RegisterScreen.url} push={false}/>
        return (
            <AuthPage title={msgs.gui.titleRegister}>
                <Modal.Body>
                    <RegisterInput onComplete={(helpers, values, res) => {
                        // console.log("Register Input result: ", values, res);
                        companyInfo = res;
                        registerData = {...values};
                        this.props.history.push(langLink(CompanyDataScreen.url));
                    }}/>
                </Modal.Body>
            </AuthPage>
        );
    }
}
RegisterScreen.contextType=LangContext;

/**
 * Pomocnicza funkcja, która wyciąga imię z imienia i nazwiska.
 * @param name
 */
function getFirstName(name: string): string {
    if(typeof(name)!=='string' || name.length===0) return "";
    name=name.trim();
    let sp=name.lastIndexOf(' ');
    if(sp===-1) return name;
    return name.substr(0, sp).trim();
}

/**
 * Ekran z informacjami o firmie.
 */
export class CompanyDataScreen extends React.Component<RouteChildrenProps, void> {
    static url="/register_company";

    componentDidMount(): void {
        if(!registerData) {
            this.props.history.replace(langLink(RegisterScreen.url));
        }
    }

    render() {
        if (!registerData) return null;
        return (
            <AuthPage title={msgs.gui.titleRegister}>
                <Modal.Body>
                    <Alert variant="info">
                        <div className="icon in-circle">
                            <Icon.Info/>
                        </div>
                        <div
                            className="content"
                            dangerouslySetInnerHTML={{
                                __html: formatString(msgs.gui.hintCompanyData, getFirstName(registerData.name))
                            }}
                        />
                    </Alert>
                    <Form
                        initialValues={companyInfo ? companyInfo : RegisterCompanyInfoInitial}
                        validate={RegisterCompanyInfoValidation}
                        onSubmit={async (values, helpers) => {
                            let res = await store.publicApi.register2(values);
                            if (Form.setError(helpers, res)) return;
                            if (res === true) {
                                this.props.history.push(langLink(RegisterComplete.url));
                            }
                        }}
                    >{(formik) => <>
                        <Form.FormError/>
                        <Form.Group name="name">
                            <Form.Label>{msgs.gui.labelCompanyName}</Form.Label>
                            <Form.Text/>
                        </Form.Group>
                        <Form.Row>
                            <Col md={6}>
                                <Form.Group name="postalCode">
                                    <Form.Label>{msgs.gui.labelPostalCode}</Form.Label>
                                    <Form.PostalCode/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group name="city">
                                    <Form.Label>{msgs.gui.labelCity}</Form.Label>
                                    <Form.Text/>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col md={6}>
                                <Form.Group name="address">
                                    <Form.Label>{msgs.gui.labelFullAddress}</Form.Label>
                                    <Form.Text/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group name="country">
                                    <Form.Label>{msgs.gui.labelCountry}</Form.Label>
                                    <Form.Country/>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row className="pt-3">
                            <Col md={12} className="text-right">
                                <Form.Submit>{msgs.gui.actionSave}</Form.Submit>
                            </Col>
                        </Form.Row>
                    </>}</Form>
                </Modal.Body>
            </AuthPage>
        );
    }
}
CompanyDataScreen.contextType=LangContext;

export class RegisterComplete extends React.Component<RouteComponentProps, void> {
    static url="/register_complete";

    componentDidMount() {
        if(!registerData) {
            this.props.history.replace(langLink(RegisterScreen.url));
        }
    }

    render() {
        if(!registerData) return null;
        return <AuthInfoScreen
            tabTitle={msgs.gui.titleRegister}
            title={formatString(msgs.gui.titleRegistrationComplete, getFirstName(registerData.name))}
            html={msgs.gui.hintRegistrationComplete}
            help
            />;
    }
}
RegisterComplete.contextType=LangContext;

type ConfirmState = {
    error: boolean;
    result: string|null;
}

export class RegisterConfirm extends React.Component<RouteChildrenProps<{ code: string }>, ConfirmState> {
    static url="/register/:code";
    static link=(code: string) => "/register/"+code;

    constructor(props) {
        super(props);
        this.state={
            error: false,
            result: null,
        }
    }

    componentDidMount() {
        store.publicApi.confirmAccount(this.props.match.params.code).then((res) => {
            let err=Validate.getError(res);
            if(err) {
                this.setState({ result: err, error: true });
            } else {
                this.setState({ result: "gui.registerAccountActivated" });
            }
        });
    }

    render() {
        if(this.state.result===null) return null;
        return <AuthInfoScreen
            title={msgs.gui.titleRegister}
            variant={this.state.error?"danger":"info"}
            html={getMessage(this.state.result)}
            content={this.state.error?null:<div className="text-right">
                <LangLink className="btn btn-primary btn-lg" to={LoginScreen.url}>{msgs.gui.labelGotoLogin}</LangLink>
            </div>}
        />;
    }
}
RegisterConfirm.contextType=LangContext;

export function setRegisterData(rd: RegisterForm) {
    registerData=rd;
}