//@flow
import React, {Ref} from 'react';
import {RouteComponentProps} from "react-router";
import {Dialog, Icon, SendAlert} from "../lib/Components";
import {Alert, Col} from "react-bootstrap";
import msgs, {formatString} from "../lib/Language";
import {Form} from "../lib/Form";
import type {RegisterCompanyInfo} from "../api";
import {RegisterCompanyInfoInitial, RegisterCompanyInfoValidation} from "../api";
import {FormikProps} from "formik";
import {Events, store} from "../application";
import {emitEvent} from "../lib/Events";

type State = {
    page: "tax"|"data"|"summary";
    tax: string;
    company: RegisterCompanyInfo;
}

export default class AddCompanyDialog extends React.Component<RouteComponentProps, State> {
    static url = "/settings/add_company";
    formTax: Ref<FormikProps>;
    formData: Ref<FormikProps>;

    constructor(props) {
        super(props);
        this.formTax = React.createRef();
        this.formData = React.createRef();
        this.state = {
            page: "tax",
            tax: "",
            company: null,
        }
    }

    render() {
        const first = this.state.page === "tax";
        const last = this.state.page === "summary";

        return <Dialog
            title={msgs.gui.actionAddCompany}
            cancelButton={last ? null : (first ? msgs.gui.buttonCancel : msgs.gui.buttonBack)}
            onCancel={() => {
                switch (this.state.page) {
                    case "tax":
                        return true;
                    case "data":
                        this.setState({page: "tax"});
                        return false;
                    case "summary":
                        this.setState({page: "data"});
                        return false;
                }
            }}
            acceptButton={last ? msgs.gui.buttonClose : msgs.gui.buttonNext}
            onAccept={() => {
                switch (this.state.page) {
                    case "tax":
                        console.log("Submit");
                        this.formTax.current.submitForm();
                        return false;
                    case "data":
                        this.formData.current.submitForm();
                        return false;
                    case "summary":
                        // Bo menu się zmienia.
                        setTimeout(() => window.location.reload(), 100);
                        return true;
                }
            }}
        >
            {this.state.page === "tax" ? <>
            <Form
                key="tax"
                formikRef={this.formTax}
                initialValues={{tax: this.state.tax}}
                validate={{tax: {nip: true, notEmpty: true}}}
                onSubmit={async (values, helpers) => {
                    let res = await store.userApi.addCompanyByNIP(values.tax);
                    if (Form.setError(helpers, res)) {
                        return;
                    }
                    if (res) {
                        this.setState({
                            company: res,
                            tax: values.tax,
                            page: "data",   // TODO: Teoretycznie powinno być pominięte
                        })
                    } else {    // gdy brak informacji o firmie
                        const country = values.tax.substr(0, 2);
                        this.setState({
                            company: {
                                ...RegisterCompanyInfoInitial,
                                country: country || "PL",
                            },
                            tax: values.tax,
                            page: "data",
                        })
                    }
                }}
                    >{(formik) => <>
                    <Form.Group name="tax">
                    <Form.Label>{msgs.gui.labelNIP}</Form.Label>
                    <Form.NIP/>
                    </Form.Group>
                    </>}</Form>
                    </>:null}
                {this.state.page === "data" ? <>
                    <Alert variant="info">
                        <div className="icon in-circle">
                            <Icon.Info/>
                        </div>
                        <div
                            className="content"
                            dangerouslySetInnerHTML={{
                                __html: msgs.gui.hintAddCompany
                            }}
                        />
                    </Alert>
                    <Form
                        key="data"
                        formikRef={this.formData}
                        initialValues={this.state.company}
                        validate={RegisterCompanyInfoValidation}
                        onSubmit={async (values, helpers) => {
                            let res = await store.userApi.addCompany(
                                this.state.tax,
                                values,
                            )
                            if (Form.setError(helpers, res)) {
                                return;
                            }
                            emitEvent(Events.OrganizationChanged);
                            store.refreshUser().finally();
                            this.setState({page: "summary"});
                        }}
                    >{(formik) => <>
                        <Form.FormError/>
                        <Form.Group name="name">
                            <Form.Label>{msgs.gui.labelCompanyName}</Form.Label>
                            <Form.Text/>
                        </Form.Group>
                        <Form.Row>
                            <Col md={6}>
                                <Form.Group name="postalCode">
                                    <Form.Label>{msgs.gui.labelPostalCode}</Form.Label>
                                    <Form.PostalCode/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group name="city">
                                    <Form.Label>{msgs.gui.labelCity}</Form.Label>
                                    <Form.Text/>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col md={6}>
                                <Form.Group name="address">
                                    <Form.Label>{msgs.gui.labelFullAddress}</Form.Label>
                                    <Form.Text/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group name="country">
                                    <Form.Label>{msgs.gui.labelCountry}</Form.Label>
                                    <Form.Country/>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </>}</Form>
                </> : null}
                {this.state.page === "summary" ? <SendAlert className="single-line">{formatString(msgs.gui.hintCompanyAdded, '')}</SendAlert> : null}
            </Dialog>;
            }
}