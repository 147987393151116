//@flow
import React, {useEffect, useState} from 'react';
import {AuthPage} from "../lib/Components";
import {currentLang, langLink, useMsgs} from "../lib/Language";
import type {ActivateAccountInfo, AgreementInfo, RegisterForm} from "../api";
import {store} from "../application";
import {Form} from "../lib/Form";
import {FormikProps} from "formik";
import {Button, Col, FormControl, Modal, Row} from "react-bootstrap";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {AgreementsView} from "./RegisterScreen";

const ActivateScreen = () => {
    const { code } = useParams();
    const history=useHistory();
    const msgs=useMsgs();
    const [ agreements, setAgreements ] = useState<Array<AgreementInfo>|null>(null);
    const [ valid, setValid] = useState<boolean>(null);
    const [ info, setInfo ] = useState<ActivateAccountInfo>(null);
    useEffect(() => {
        store.publicApi.getAgreements().then(agreements => setAgreements(agreements));
        store.publicApi.getActivateAccountInfo(code).then((info) => {
            setValid(!!info);
            setInfo(info);
        })
    }, [])

    if(agreements===null || valid===null || info===null) return null;

    if(!valid) {
        return <AuthPage title={msgs.gui.titleActivateAccount}>
            <Modal.Body>
                <h5 className="text-danger">{msgs.gui.resetInvalidCode}</h5>
            </Modal.Body>
        </AuthPage>
    }

    const lang=currentLang.code;

    return (
        <AuthPage title={msgs.gui.titleActivateAccount}>
            <Form
                initialValues={{ email: info.email, agreements: [], password: "", password2: "", }}
                validate={{}}
                customValidation={(values) => {
                    const agr:Array<string>=values["agreements"];
                    let errors={};
                    agreements.forEach((a: AgreementInfo) => {
                        if(a.required && !agr.includes(a.name)) errors['a_'+a.name]=msgs.validation.required;
                    })
                    return errors;
                }}
                initialStatus={{promo: false}}
                className="activate-form"
                onSubmit={async (values, helpers) => {
                    let res = await store.publicApi.activateAccount({
                        code,
                        password: values.password,
                        agreements: values.agreements
                    });
                    if (Form.setError(helpers, res)) return;
                    history.replace({ pathname: langLink("/")});
                }}
            >{(formik: FormikProps<RegisterForm>) => {
                const same=formik.values.password===formik.values.password2 || !formik.getFieldMeta("password2").touched;
                return <Modal.Body>
                    <Form.RowFormError/>
                    <Row>
                        <Col md={12}>
                            <Form.Group name="email">
                                <Form.Label>{msgs.gui.labelEmail}</Form.Label>
                                <Form.Email disabled/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group name="password">
                                <Form.Label>{msgs.gui.labelPassword}</Form.Label>
                                <Form.Password checkStrength={true}/>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group name="password2">
                                <Form.Label>{msgs.gui.labelRepeatPassword}</Form.Label>
                                <Form.Password isInvalid={!same} checkStrength={false}/>
                                {!same ? <FormControl.Feedback type="invalid">{msgs.gui.validatePasswordDifferent}</FormControl.Feedback> : null}
                            </Form.Group>
                        </Col>
                    </Row>
                    <AgreementsView formik={formik} agreements={agreements}/>
                    <Row>
                        <Col md={12} className="center">
                            <Button
                                onClick={formik.handleSubmit}
                                type="submit"
                            >{msgs.gui.actionRegister}</Button>
                        </Col>
                    </Row>
                </Modal.Body>;
            }}
            </Form>
        </AuthPage>
    );
}
ActivateScreen.url="/activate/:code";

export default ActivateScreen;