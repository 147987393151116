//@flow

//eslint-disable-next-line
import msgs, {Languages, LanguagesOrdered} from "./Language";

const postalCodePattern=new RegExp("^[0-9]{2}-[0-9]{3}$");
//eslint-disable-next-line
const emailPattern=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export type ValidationRule = {
    required?: boolean; requiredMessage?: string;
    notNull?: boolean; notNullMessage?: string;
    notEmpty?: boolean; notEmptyMessage?: string;
    notBlank?: boolean; notBlankMessage?: string;
    minLength?: number; maxLength?: number; lengthMessage?: string;
    pattern?: string; patternMessage?: string;
    nip?: boolean; nipMessage?: string;
    postalCode?: boolean; postalCodeMessage?: string;
    email?: boolean; emailMessage?: string;
    assertTrue: boolean; assertTrueMessage?: string;
    password?: boolean; passwordMessage?: string;
    min?: number; max?: number; rangeMessage?: string;
}

export type ValidationRules = {
    [string]: ValidationRule;
}

export const regex={
    digits: /\d+/,
    letters: /[a-zA-Z]+/,
    lower: /[a-z]+/,
    upper: /[A-Z]+/,
//eslint-disable-next-line
    symbols: /[`~\!@#\$%\^\&\*\(\)\-_\=\+\[\{\}\]\\\|;:'",<.>\/\?€£¥₹]+/,
    spaces: /[\s]+/,
    filename: /(?!\.)(?!com[0-9]$)(?!con$)(?!lpt[0-9]$)(?!nul$)(?!prn$)[^\|\*\?\\:<>/$"]*[^\.\|\*\?\\:<>/$"]+/,

};

export type ErrorResult = {
    error: boolean;
    main?: string;
    fields?: { [string]: string };
};

export default class Validate {
    /**
     * Długość tekstu
     * @param val
     * @param minLength
     * @param maxLength
     */
    static length(val: any, minLength: number|undefined, maxLength: number|undefined) : boolean {
        if((typeof(val)==='object')&& !Array.isArray(val)){    // wartość językowa
            // for(let l in Languages) {
            //     let f=val[Languages[l]];
            //     if(typeof(f)!=='string') continue;
            //     if(Validate.length(f, minLength, maxLength)) return true;
            // }
            return false;
        }

        let length;
        if(typeof(val)==='string') length=val.length;
        else if(Array.isArray(val)) length=val.length;
        else length=0;

        if(typeof(minLength)==='number' && length<minLength) return false;
        if(typeof(maxLength)==='number' && length>maxLength) return false;
        return true;
    }

    static required(val: any): boolean {
        if(val===null || val===undefined) return false;
        if(typeof(val)==='string') return val.length>0;
        return true;
    }

    static notNull(val: any): boolean {
        if(val===null || val===undefined) return false;
        return true;
    }

    static notEmpty(val: any): boolean {
        if(val===null || val===undefined) return false;
        if(Array.isArray(val)) return val.length>0;
        if(typeof(val)==='string') return val.length>0;
        if(typeof(val)==='object') {
            return Validate.notEmpty(val.pl) && Validate.notEmpty(val.en);
        }
        return true;
    }

    static notBlank(val: any): boolean {
        if(typeof(val)==='object') {    // zakładamy, że jest to mapa językowa
            return false;
        }
        if(typeof(val)!=='string') return false;
        return val.trim().length>0;
    }

    static email(v: any): boolean {
        if(typeof(v)!=='string') return false;
        return emailPattern.test(v.toLowerCase());
    }

    static pattern(val: any, pattern: string): boolean {
        if(typeof(val)!=='string') return false;
        return new RegExp(pattern).test(val);
    }

    static postalCode(v: any): boolean {
        if(typeof(v)!=='string') return false;
        if(v.length!==6) return false;
        return v.match(postalCodePattern)!==null;
    }

    static inRange(val: any, min?: number, max?: number): boolean {
        if(val===null || val===undefined || val==="") return true;
        if(typeof(val)!=="string") {
            val=parseFloat(val);
        }
        if(isNaN(val)) return false;
        if(typeof(min)==='number' && val<min) return false;
        if(typeof(max)==='number' && val>min) return false;
        return true;
    }

    static langStringNotEmpty(v: any): boolean {
        if(typeof(v)!=='object') return false;
        for(const l of LanguagesOrdered) {
            if(!Validate.notBlank(v[l.code])) return false;
        }
        return true;
    }

    /**
     * Sprawdza, czy dana wartość to poprawny numer NIP
     * @param v
     * @param checkCrc czy sprawdzać poprawność NIPu - liczbę kontrolną
     */
    static nip(v: any, checkCrc?: boolean): boolean {
        if(typeof(v)!=='string') return false;
        let nip='';
        for(let i=0;i<v.length;++i) {
            const c=v[i];
            if(c>='0' && c<='9') nip+=c;
            else if(c===' ' || c==='-') continue;
            else return false;
        }
        if(nip.length!==10) return false;
        if(checkCrc!==true) return true;
        let crc=(nip[0]-'0')*6 +(nip[1]-'0')*5 +(nip[2]-'0')*7
            +(nip[3]-'0')*2 +(nip[4]-'0')*3
            +(nip[5]-'0')*4 +(nip[6]-'0')*5
            +(nip[7]-'0')*6 +(nip[8]-'0')*7
        return (nip[9]-'0')===(crc%11);
    }

    static passwordVariant(p: number): string {
        if(p>=60) return "strong";
        if(p>=30) return "normal";
        return "weak";
    }

    static passwordMessage(p: number): string {
        if(p>=90) return msgs.gui.passwordVeryGood;
        if(p>=60) return msgs.gui.passwordGood;
        if(p>=20) return msgs.gui.passwordWeak;
        return msgs.gui.passwordBad;
    }

    /**
     * Funkcja testuje hasło i zwraca wynik jakości hasła.
     * @param p 0 słabe hasło, 100 bardzo dobre hasło
     */
    static passwordCheck(p: string): number {
        if(typeof(p)!=='string') return 0;
        let upper=0, lower=0, num=0, sym=0, /*other=0,*/ len=p.length;
        for(let i=0;i<p.length;++i) {
            const c=p[i];
            if(regex.digits.test(c)) ++num;
            else if(regex.upper.test(c)) ++upper;
            else if(regex.lower.test(c)) ++lower;
            else if(regex.symbols.test(c)) ++sym;
            // else other++;
        }
        let score=0;
        if(len<=4) return 0; // za krótkie
        if(len>=8) score+=20+Math.min((len-8)*3, 20);

        if(lower>0) score+=10+Math.min(6, lower*4);
        if(upper>0) score+=10+Math.min(6, upper*4);
        if(num>0) score+=10+Math.min(6, num*4);
        if(sym>0) score+=10+Math.min(6, sym*4);
        return Math.min(100, score);
    }

    /**
     * Funkcja sprawdza, czy hasło spełnia minimalne wymagania
     * @param p
     */
    static password(p: string): boolean {
        if(typeof(p)!=='string') return false;
        let upper=0, lower=0, num=0, sym=0, len=p.length;
        for(let i=0;i<p.length;++i) {
            const c=p[i];
            if(regex.digits.test(c)) ++num;
            else if(regex.upper.test(c)) ++upper;
            else if(regex.lower.test(c)) ++lower;
            else ++sym;
            // else other++;
        }
        return upper>0 && lower>0 && num>0 && sym>0 && len>=8;
    }

    static filename(p: string): boolean {
        if(typeof(p)!=='string') return false;
        if(p.length>200) return false;
        return regex.filename.test(p);
    }

    /**
     * Pomocnicza funkcja, która przeprowadza walidację jednego pola
     * @param inputValue wartość do sprawdzenia
     * @param rule regułka dla wartości
     * @return jednej (pierwszy) komunikat błędu lub null, gdy nie ma błędów.
     */
    static validateField(inputValue: any, rule: ValidationRule) : string|null {
        if(typeof (rule)!=='object') return null;   // nie ma reguł, to nie sprawdzamy
        if(rule.required===true && !Validate.required(inputValue)) return rule.requiredMessage || msgs.validation.required;
        if(rule.notNull===true && !Validate.notNull(inputValue)) return rule.notNullMessage || msgs.validation.notNull;
        if(rule.notEmpty===true && !Validate.notEmpty(inputValue)) return rule.notEmptyMessage || msgs.validation.notEmpty;
        if(rule.notBlank===true && !Validate.notBlank(inputValue)) return rule.notBlankMessage || msgs.validation.notBlank;
        if(rule.email===true && !Validate.email(inputValue)) return rule.emailMessage || msgs.validation.invalidEmail;
        if(rule.postalCode===true && !Validate.postalCode(inputValue)) return rule.postalCodeMessage;
        if(rule.nip===true && !Validate.nip(inputValue)) return rule.nipMessage;
        if(rule.assertTrue && inputValue!==true) return rule.assertTrueMessage || msgs.validation.assertTrue;
        if(rule.password===true && !Validate.password(inputValue)) return rule.passwordMessage || msgs.gui.validatePasswordStrength;
        if((typeof(rule.min)==='number' || typeof(rule.max)==='number')  && !Validate.inRange(inputValue, rule.min, rule.max)) return rule.rangeMessage || msgs.validation.notInRange;
        if(typeof(rule.pattern)==='string' && !Validate.pattern(inputValue, rule.pattern)) return rule.patternMessage || msgs.validation.invalidPattern;
        if(
            (typeof(rule.minLength)==='number' || typeof(rule.maxLength)==='number')
            && !Validate.length(inputValue, rule.minLength, rule.maxLength)
        ) return rule.lengthMessage;
        return null;
    }

    /** Czy dany warunek walidacji mówi, że pole jest wymagane */
    static isRequired(rule?: ValidationRule): boolean {
        return typeof(rule)==='object' && (
            rule.assertTrue===true || rule.notEmpty===true || rule.notBlank===true
            || rule.required===true || rule.notNull
        );
    }

    /**
     * Pomocnicza funkcja, która sprawdza na podstawie propercji, czy komponent ma być sprawdzany
     * i jeżeli tak to go sprawdza.
     * @param inputValue wartość pola
     * @param validateProp ustawienia walidacji
     * @param defRule domyślna regułka walidacji dla validateProp===true
     * @return string, gdy błąd, null gdy brak błędu, undefined gdy nie ma być walidacji
     */
    static validateComponent(inputValue: any, validateProp?: boolean|{}, defRule: ValidationRule): string|null|undefined {
        if(validateProp===true) return Validate.validateField(inputValue, defRule);
        if(typeof(validateProp)==='object') return Validate.validateField(inputValue, validateProp);
        return undefined;
    }

    /**
     * Funkcja sprawdzająca dany obiekt pod względem zadanych reguł.
     * Reguły są zgodne z generowanym przez Javę kodem (BeanValidator).
     * @param input
     * @param rules
     */
    static validate(input: any, rules: ValidationRules) : {} {
        let er= {};
        if(typeof(rules)!=='object') return er; // nie ma czym sprawdzać
        Object.keys(rules).forEach((field) =>{
            let rule=rules[field];
            let inputValue=input[field];
            let res=Validate.validateField(inputValue, rule);
            if(res===null || res===undefined) return;
            er[field]=res;
        });
        return er;
    }

    /**
     * Czy dany obiekt jest obiektem z komunikatem błędu
     * @param res
     * @return {boolean|boolean}
     */
    static isError = (res: ErrorResult|any): boolean => {
        return res && (typeof(res)==='object' && res.error===true);
    };

    /**
     * Pobranie głównego błędu z obiektu błędu.
     * @param res
     * @return {string|null} komunikat błędu, gdy był obiekt błędu i główny błąd lub null w przeciwnym wypadku
     */
    static getError = (res: ErrorResult|any): string|null => {
        if(res && typeof(res)==='object' && res.error===true && typeof(res.main)==='string') return res.main;
        return null;
    }

}