//@flow
/* eslint-disable no-use-before-define */
import React, {Ref, useMemo} from 'react';
import {RouteComponentProps} from "react-router";
import msgs, {currentLang, getLangValue, LangContext, langLink, useMsgs} from "../lib/Language";
import type {FilterField} from "../lib/Filter";
import {Alert, Breadcrumb, Button} from "react-bootstrap";
import {BreadcrumbItem, Dialog, dialogOpen, IconAlert, PageHeader, SendAlert} from "../lib/Components";
import type {AccessRight, OrderInfo} from "../api";
import {store} from "../application";
import type {DataTableColumn, DataTableProps} from "../lib/DataTable";
import DataTable from "../lib/DataTable";
import {formatDate} from "../lib/DateTime";
import {Redirect} from "react-router-dom";
import DesktopScreen from "./DesktopScreen";
import {SelectService, ServiceForm} from "../lib/AddService";
import {FormikBag} from "formik";
import {ServiceNotAvailable} from "../ClientComponents";

type Props = RouteComponentProps<{ name: string }>;

type State = {
    dataTimestamp: number;
    service: AccessRight;
    edit: boolean;
    filter: Array<FilterField>|null;
}

const ServicesTable = ({ service, ...props }: $Diff<DataTableProps, { columns: any}> & { service: AccessRight }) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<DataTableColumn<OrderInfo>>>(() => {
        let res=[
            {
                accessor: "id",
                className: "md",
                Header: msgs.gui.labelOrderId,
            }
        ];
        if(!service) res.push({
            accessor: "type",
            className: "lg",
            Header: msgs.gui.labelService,
            Cell: ({ value }) => value[currentLang.code],
        });
        res.push({
            accessor: "target",
            Header: msgs.gui.labelOrderTarget,
            Cell: ({ row }) => <>
                <h1>{row.original.name}</h1>
                {row.original.nip}
            </>,
        }, {
            accessor: "added",
            className: "datetime",
            Header: msgs.gui.labelOrderDate,
            Cell: ({ value }) => formatDate(value),
        }, {
            accessor: "status",
            className: "status",
            Header: msgs.gui.labelStatus,
            Cell: ({ value }) => msgs.gui["status"+value],
        });
        return res;
    }, [ msgs.gui.language, service ]);
    return <DataTable columns={columns} {...props}/>
}

const ServicesHeader = ({ service, children }) => {
    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
            <BreadcrumbItem active={!service} to={service?ServicesScreen.mainLink:undefined}>{msgs.gui.titleServices}</BreadcrumbItem>
            {service?<BreadcrumbItem active>{getLangValue(service.name)}</BreadcrumbItem>:null}
        </Breadcrumb>
        <PageHeader title={service?getLangValue(service.name):msgs.gui.titleServices}>
            {children}
        </PageHeader>
    </>;
}

/**
 * Ekran wyświetlający listę usług dla danego typu lub globalnie.
 */
export default class ServicesScreen extends React.Component<Props, State> {
    static url=[ "/service", "/service/:name" ];
    static mainLink = "/service";
    static link=(service: AccessRight|string) => "/service/"+(typeof(service)==='object'?service.ident:service);

    constructor(props) {
        super(props);

        this.state={
            dataTimestamp: Date.now(),
            ...this.getStateValue()
        }
    }

    getStateValue(): { service: AccessRight, edit: boolean } {
        if(store.services) {
            let service: AccessRight, edit, filter;
            if (this.props.match.params.name && store.services) {
                service = store.getService(this.props.match.params.name);
            } else {
                service = null;
            }

            if (service) {
                edit = service.write;
                filter=[ { field: "type", value: service.typeId } ]
            } else {
                edit = !!store.services.find(s => s.write);  // jakakolwiek usługa z prawem zapisu
                filter=null;
            }
            return { service, edit, filter };
        } else {
            return { service: null, edit: false, filter: null }
        }
    }

    refresh = ()  => {
        this.setState({ dataTimestamp: Date.now() });
    }

    componentDidMount() {
        RegisterServiceDialog.background=this.refresh;
    }

    componentWillUnmount() {
        RegisterServiceDialog.background=null;
    }

    componentDidUpdate(prevProps: $ReadOnly<Props>, prevState: $ReadOnly<State>, snapshot: any) {
        if(this.props.match.params.name!==prevProps.match.params.name) {
            let s=this.getStateValue();
            this.setState({ ...s });
        }
        return true;
    }

    onAdd() {
        dialogOpen(this, RegisterServiceDialog.link(this.state.service));
    }

    render() {
        if(!store.services) return <Redirect to={langLink("/")}/>;
        if(this.state.service && !this.state.service.show[currentLang.code]) return <Redirect to={langLink("/")}/>;
        const service=this.state.service;
        if(service && !service.read) {
            return <>
                <ServicesHeader service={service}/>
                <ServiceNotAvailable/>
            </>
        }
        const lang=currentLang.code;
        return <>
            <ServicesHeader service={service}>
                <Button
                    size="xl" className="btn-icon" disabled={!this.state.edit}
                    onClick={(e) => this.onAdd(e)}
                >
                    <span className="icon"><span className="icon-add-outline"/></span>
                    {this.state.service?msgs.gui.actionAddServicePrefix+' '+this.state.service.name[currentLang.code]:msgs.gui.actionAddService}
                </Button>
            </ServicesHeader>
            {service?<IconAlert className="mb-5">{service.desc[lang]}</IconAlert>:null}
            <ServicesTable
                service={this.state.service}
                data={store.userApi.queryOrders}
                dataTimestamp={this.state.dataTimestamp}
                customFilters={this.state.filter}
                historyState={"table"+(service?"_"+service.ident:"")}
                delayFilter={0}
            />
        </>;
    }
}
ServicesScreen.contextType=LangContext;
ServicesScreen.mainClassName="screen-services-list";


type RegisterState = {
    /** Wybrany typ */
    type: AccessRight;
    /** Czy wysłano dane do serwera */
    submitted: boolean;
}

/**
 * Okno dodawania usługi. Może być wybrane jako globalne - z formularzem wyboru typu
 * lub dla danego typu.
 */
export class RegisterServiceDialog extends React.Component<RouteComponentProps<{ name: string }>, RegisterState> {
    static background: () => void=null;
    static url = [ "/service/add/", "/service/add/:name" ];
    static link = (a?: AccessRight|string) => "/service/add/"+(a?(typeof(a)==='object'?a.ident:a):"");

    formik: Ref<FormikBag>;

    constructor(props) {
        super(props);

        this.formik=React.createRef();
        this.state = {
            type: store.getService(this.props.match.params.name) || null,
            submitted: false,
        }
    }

    render() {
        const type:AccessRight=this.state.type;
        if(type && !type.show[currentLang.code]) return <Redirect to={langLink("/")}/>;

        let title=msgs.gui.actionAddService;
        if(this.state.submitted) title=msgs.gui.titleServiceSend;
        else if(type) title=msgs.gui.actionAddServicePrefix+": "+getLangValue(type.name);

        return <Dialog
            title={title}
            cancelButton={this.state.submitted?null:msgs.gui.buttonCancel}
            acceptButton={this.state.submitted?msgs.gui.actionFinish:this.formik.current?msgs.gui.actionSend:undefined}
            onAccept={() => {
                if(this.formik.current) {
                    this.formik.current.handleSubmit();
                    return false;
                } else {
                }
            }}
        >
            {this.state.submitted?<SendAlert>{msgs.gui.hintServiceSend}</SendAlert>:
                !type?
                    <SelectService
                        onSelect={(t) => this.setState({ type: t })}
                        services={store.services}
                    />:
                    <ServiceForm
                        type={this.state.type}
                        registerOrder={store.userApi.registerOrder}
                        onSubmitted={() => {
                            this.setState({ submitted: true });
                            if(typeof(RegisterServiceDialog.background)==='function') RegisterServiceDialog.background();
                        }}
                        formikRef={this.formik}
                    />}
        </Dialog>
    }
}
