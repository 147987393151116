//@flow
import React, {useContext} from 'react';
import {Link, RouteProps, useLocation, withRouter} from "react-router-dom";
import type {LanguageData} from "../LangType";
import {isLangKey} from "../LangType";
import {waitGlass} from "./Components";
import {RouteChildrenProps} from "react-router";
import {enGB, pl} from "date-fns/locale"
import type {LangOption} from "../api";

export type LangString={
    pl: string,
    en: string,
};

/**
 * Typ z informacją o języku
 */
export type LangInfo = {
    /** Nazwa kodowa języka - dwie litery */
    code: string;
    /** Pełna nazwa języka w danym języku */
    name: string;
    /** Obiekt z ustawieniami języka dla biblioteki czasu (date-fns) */
    dateFns: Object;
    /** Obiekt z ustawieniami dla biblioteki BigNumber (formatowanie liczb) */
    bigNumber: Object;
}

const plMoneyFormat = {
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupSize: 3,
};

const enMoneyFormat = {
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
}

/**
 * Definicje dostępnych języków
 */
export const Languages : { [string]: LangInfo }  = {
    pl: { code: 'pl', name: 'polski', dateFns: pl, bigNumber: plMoneyFormat },
    en: { code: 'en', name: 'english', dateFns: enGB, bigNumber: enMoneyFormat },
};

/**
 * Języki w tabeli w określonej kolejności
 * @type {LangInfo[]}
 */
export const LanguagesOrdered: Array<LangInfo> = [ Languages.pl, Languages.en ];

/** Dostępne języki */
export type Language=$Keys<typeof Languages>;

/** Domyślny język dla aplikacji, gdy nie wykryto innego dostępnego */
export const defaultLanguage: Language='pl';

/** Funkcja generująca adresy dla route dla dostępnych języków */
export function langPath(path: string|string[]): string[] {
    if(Array.isArray(path)) {
        let res=[];
        path.forEach(p => res.push(p, '/pl'+p, '/en'+p));
        return res;
    } else {
        return [ path, '/pl'+path, '/en'+path ];
    }
}

/** Dostępne dla aplikacji paczki języków */
let appPacks: string=null;
/** Aktualnie wybrany język */
export let currentLang: LangInfo = null;
/** Właściwe klucze językowe */
let msgs: LanguageData = {};

export type LangListener = (lang: LangInfo) => void;

let listeners: Array<LangListener>= [];

export function addLanguageListener(listener: LangListener) {
    listeners.push(listener);
}

export default msgs;

/** Funkcja generująca link językowy dla aktualnego języka */
export function langLink(path: string): string {
    return "/"+currentLang.code+path;
}

/** Komponent linku do danej strony */
export const LangLink = ({to, children, ...props}) => {
    return <Link
        to={langLink(to)}
        {...props}
    >{children}</Link>
};

/** Komponent linku do okna */
export const DialogLink = ({to, children, ...props}) => {
    let location=useLocation();
    return <Link
        to={{
            pathname: langLink(to),
            state: { background: location }
        }}
        {...props}
    >{children}</Link>
};

/** Funkcja generująca link (obiekt do pushowania do historii) dla okna */
export function dialogLink(location: Location, to: string): Object {
    return {
        pathname: langLink(to),
        state: { background: location }
    }
}

/** Funkcja dodająca do historii nowy adres */
export function moveTo(props: RouteChildrenProps, to: string, dialog?: boolean, replace?: boolean) {
    let link;
    if(dialog===true) {
        link={
            pathname: langLink(to),
            state: { background: props.location }
        }
    } else link=langLink(to);
    if(replace===true) props.history.replace(link); else props.history.push(link);
}

/** Funkcja czytująca dany język ustawiająca go jako aktualny */
function loadLanguage(lang: LangInfo): Promise<LanguageData> {
    return new Promise<LanguageData>((resolve, reject) => {
        const url=process.env.REACT_APP_SERVER+"/lang/"+lang.code+'/'+appPacks+".json";
        fetch(url, {
            method: 'GET',
            credentials:  (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')?'include':'same-origin',
        }).then(res => {
            res.json().then(data => {
                // console.log("Language changed: ", lang, data);
                currentLang=lang;
                // Podmieniamy wewnątrz obiektu
                // Object.keys(msgs).forEach(k => delete msgs[k]);
                Object.keys(data).forEach(k => msgs[k]=data[k]);
                resolve(data);
                listeners.forEach(l => l(lang));
            }).catch(reject);
        }).catch(reject);
    });
}

export const SwitchLangLink = ({ children }) => {
    const location=useLocation();
    const msgs=useMsgs();
    let path: string=location.pathname;
    let next=Languages.en;
    if(path.startsWith("/pl/")) {next=Languages.en; path=path.substring(3); }
    else if(path.startsWith("/en/")) { next=Languages.pl; path=path.substring(3); }
    return <Link to={"/"+next.code+path}>{children}</Link>;
}

/** Komponent zmiany języka */
export const SwitchLanguage = () => {
    const location=useLocation();
    const msgs=useMsgs();
    let path: string=location.pathname;
    let next=Languages.en;
    if(path.startsWith("/pl/")) {next=Languages.en; path=path.substring(3); }
    else if(path.startsWith("/en/")) { next=Languages.pl; path=path.substring(3); }
    return <div className="change-language">
        {msgs.gui.langChangePrefix} <Link to={"/"+next.code+path}>{msgs.gui.langChangeLang}</Link>
    </div>;
};

/** Funkcja wykrywająca język dla użytkownika */
function detectLanguage(path?: string): LangInfo {
    if(!path) path=window.location.pathname;
    let res=Languages.pl;
    if(path.startsWith("/pl/")) res=Languages.pl;
    else if(path.startsWith("/en/")) res=Languages.en;
    return res;
}

/** Inicjalizacja języka - wykrywa, ustawia pola, ale nie pobiera właściwych danych językowych */
export function setupLanguage(packs: string): void {
    appPacks=packs;
    const lang=detectLanguage();
    currentLang=lang;
    document.documentElement.setAttribute("lang", lang.code);
}

export function initLanguage(): Promise<void> {
    if(!appPacks) throw new Error("setup first!");
    return loadLanguage(currentLang);
}

type LangContextState = {
    lang: LangInfo;
    msgs: LanguageData;
}

export const LangContext=React.createContext<LangContextState>();

/** Pomocniczy Hook, który zwraca klucze językowe. */
export function useMsgs(): LanguageData {
    return useContext(LangContext).msgs;
}

class _LangContextProvider extends React.Component<RouteProps & { onChange?: (lang: LangInfo) => void }, void> {
    /** Aktualnie pobierany język */
    loading: string|null;
    current: LangContextState;

    constructor(props) {
        super(props);
        this.loading=null;
        this.current={
            lang: currentLang,
            msgs: msgs
        }
    }

    shouldComponentUpdate(nextProps: $ReadOnly<RouteProps>, nextState: $ReadOnly, nextContext: any): boolean {
        if(this.props.location!==nextProps.location) {
            let lang=detectLanguage(nextProps.location.pathname);
            // czy język się zmienił i czy nie jest już w trakcie wczytywania
            if(this.current.lang.code!==lang.code && this.loading!==lang.code) {
                this.loading=lang.code;
                loadLanguage(lang).then((data) => {
                    if(this.loading!==lang.code) return;    // było jeszcze inne wczytywanie zlecone
                    this.loading=null;  // wczytano
                    this.current={
                        lang: currentLang,
                        msgs: msgs
                    };
                    if(this.props.onChange) this.props.onChange(lang);
                    this.forceUpdate();
                }).finally(waitGlass());
            }
        }
        return false;
    }

    render() {
        return <LangContext.Provider value={this.current}>
            {this.props.children}
        </LangContext.Provider>;
    }
}
_LangContextProvider.contextType=LangContext;

export const LangContextProvider=withRouter(_LangContextProvider);

export function langCompare(str1: LangString, str2: LangString, lang?: LangInfo|string): number {
    if(lang===null || lang===undefined) lang=currentLang.code;
    else if(typeof(lang)==='object') lang=lang.code;
    if(!lang) lang="pl";

    const s1=str1 && str1[lang], s2=str2 && str2[lang];
    if(!s1 && !s2) return 0;
    else if(!s1) return -1;
    else if(!s2) return 1;
    return s1.localeCompare(s2);
}

/** Zwraca kopię obiektu LangString z podmienionym kluczem */
export function updateKey(l: LangString, code: string, newValue: string): LangString {
    return {
        ...l,
        [code]: newValue
    }
}

/**
 * Zwraca wartość wejściową lub klucz językowego, jeżeli wartość wejściowa jest kluczem językowym.
 * @param msg
 */
export function getMessage(msg: string): string {
    let c=isLangKey(msg);
    if(!c) return msg;
    let val=msgs[c][msg.substring(c.length+1)];
    if(val===undefined) return "[missing: "+msg+"]";
    return val;
}


/**
 * Formatowanie zgodne, uproszczone do tego z Javowym MessageFormatter.
 * @param format
 * @param args
 */
export function formatString(format: string, ...args): string {
    if(args===undefined || args===null || args.length===0) return format;
    if(format===null || format===undefined) return "";
    let str=format;
    for(let i=0;i<args.length;++i) str=str.replace('{'+i+'}', String(args[i]));
    return str;
}

export function getLangValue(k: LangString|null|undefined, lang?: LangInfo|string): string|null {
    if(!k) return null;
    if(typeof(k)==='string') return k;

    if(!lang) lang=currentLang.code;
    else if(typeof(lang)==='object') lang=lang.code;
    return k[lang];
}

export function getLangOptions(options: Array<LangOption>): React$Node {
    if(!Array.isArray(options)) return null;
    const lang=currentLang.code;
    return options.map(o => <option key={o.value} value={o.value}>{o.label[lang]}</option>);
}

export function sortLangOptions(options: Array<LangOption>) {
    if(!Array.isArray(options)) return;
    const get=(v: LangString) => v[currentLang.code];
    options.sort((v1: LangOption, v2: LangOption) => {
        if(typeof(v1.order)==='number' && typeof(v2.order)==='number') {
            const d=v1.order-v2.order;
            if(d!==0) return d;
        }

        const s1 = get(v1.label) || "", s2 = get(v2.label) || "";
        return s1.localeCompare(s2);
    })
}

export function langSort<T>(data: Array<T>, getLang: (item: T)=> LangString) {
    if(!Array.isArray(data)) return;
    const get=(item: T) => getLang(item)[currentLang.code];
    data.sort((v1, v2) => {
        const s1=get(v1) || "", s2=get(v2) || "";
        return s1.localeCompare(s2);
    })
}

export function langEquals(v1: LangString, v2: LangString): boolean {
    if(v1===null && v2===null) return true;
    if(v1===null || v2===null) return false;
    return v1.pl===v2.pl && v1.en===v2.en;
}