//@flow
import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {useMsgs} from "../lib/Language";
import type {StaticPage} from "../api";
import {store} from "../application";
import {Breadcrumb, Card} from "react-bootstrap";
import {BreadcrumbItem, PageHeader} from "../lib/Components";
import Error404 from "./Error404";
import DesktopScreen from "./DesktopScreen";

const StaticScreen = () => {
    const msgs=useMsgs();
    const { name }=useParams();
    const [ page, setPage ] = useState<StaticPage>(undefined);
    useEffect(() => {
        store.publicApi.getStaticPage(name).then(page=> setPage(page));
    }, [ msgs.gui.language, name, setPage ]);
    if(page===undefined) return null;
    if(page===null) return <Error404/>;
    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
            <BreadcrumbItem active>{page.title}</BreadcrumbItem>
        </Breadcrumb>
        <Card className="mt-5 mx-auto static-text-page">
            <Card.Body>
                <PageHeader title={page.title}/>
                <div
                    className="static-content"
                    dangerouslySetInnerHTML={{ __html: page.content }}
                />
            </Card.Body>
        </Card>

    </>;
}
StaticScreen.mainClassName="screen-static";

export default StaticScreen;