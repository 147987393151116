//@flow
import React, {useEffect} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {currentLang, LangInfo, LanguagesOrdered} from "../lib/Language";
import {store} from "../application";
import {UserCompanyInfo} from "../api";
import {VindicationScreen} from "./ViewVindicationScreen";
import {DebtExchangeView} from "./DebtExchange";
import MonitoredScreen from "./MonitoredScreen";
import ServicesScreen from "./ServicesScreen";
import {stringJoin} from "../lib/Utils";

/**
 * Komponent, który zajmuje się obsługą przekierowań dla linków zewnętrznych (np. z e-maila)
 * jak i wewnętrznych (np. powiadomienia).<br/>
 * Istota tego komponentu wynika, że w przekierowaniach jest informacja o organizacji
 * i użytkownik przez linki może być przełączany pomiędzy organizacjami.
 */
const AppRouter = ({}) => {
    const location=useLocation();
    const history=useHistory();

    useEffect(() => {
        async function process() {
            console.log("Processing link: ", location.pathname);
            const parts = location.pathname.split("/");
            // console.log("Parts: ", parts);
            if (parts.length < 4) return;  // zły adres
            if (parts[0] !== '') return;
            let lang: LangInfo = LanguagesOrdered.find(l => l.code === parts[1]);
            let pos = 1;
            if (lang) ++pos;
            if (parts[pos++] !== 'link') return;
            const orgId = parts[pos++];
            const target = parts[pos++];
            const targetId = parts.length > pos ? parts[pos++] : null;
            const subTarget = parts.length > pos ? parts[pos++] : null;
            const subTargetId = parts.length > pos ? parts[pos++] : null;

            console.log(`Org=${orgId}, target='${target}', tId=${targetId}, subTarget='${subTarget}', subTargetId=${subTargetId}`);
            let path;
            if (lang) path = "/" + lang.code;
            else path = "/" + currentLang.code

            // obsługa poszczególnych celów
            switch (target) {
                case "vindication":
                    if (targetId) {
                        path += VindicationScreen.link(targetId);
                    }
                    break;
                case "debt_exchange":
                    if (targetId) {
                        path += DebtExchangeView.link(targetId);
                    }
                    break;
                case "settings":
                    break;
                case "users":
                    if (targetId) {
                    }
                    break;
                case "monitored":
                    path += MonitoredScreen.url;
                    break;
                case "order": {
                    path += ServicesScreen.link(targetId);
                    break;
                }
                case "im": {
                    path += '/'+stringJoin('/', target, targetId, subTarget, subTargetId);
                }
                default:
                    console.warn("Unimplemented link target: ", target);
                    break;
            }
            let changedCompany = false;
            if (store.user.orgId !== orgId) {
                let org: UserCompanyInfo = store.user.organizations.find(o => o.orgId === orgId);
                if (!org) {
                    console.warn("Cannot change to: ", orgId);
                    return;
                }
                console.log("Processing company change: ", org);
                await store.userApi.selectCompany(org.id);
                changedCompany = true;
            }

            if (changedCompany) {
                window.location.pathname = path;  // przeładowanie, bo zmiana firmy
            } else {
                history.replace({pathname: path});   // normalne przekierowanie (podmiana w historii)
            }
        }
        process().then();
    }, [ location ]);

    return null;
}

export default AppRouter;