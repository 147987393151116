//@flow
import React from "react";

/**
 * Komponent wyświetlający progres w postaci poła za pomocą stylów CSS.
 */
const CircleProgress = ({ value, children, width, height, total, totalColor, valueColor, thickness }: {
    /** Wartość 0-100 oznaczający proces zapełnienia lub wartość z total, gdy total podane */
    value: number;
    /** Maksimum dla liczenia procentów */
    total?: number;
    width?: number|string;
    height?: number|string;
    totalColor?: string;
    valueColor?: string;
    /** Procentowa grubość paska */
    thickness?: number;
}) => {
    if(typeof(width)==='number') width=width+"px";
    else if(typeof(width)!=='string' || !width) width="100%";

    if(typeof(height)==='number') height=height+"px";
    else if(typeof(height)!=='string' || !height) height="100%";

    if(typeof(totalColor)!=='string') totalColor='black';
    if(typeof(valueColor)!=='string') valueColor='gray';

    if(typeof(total)==='number' && typeof(value)==='number' && total>0) value=Math.round(value*100/total);

    if(typeof(value)!=='number') value=0;
    if(value<0) value=0;
    else if(value>100) value=100;

    if(typeof(thickness)!=='number') thickness=20;

    return <div
        className="circle-progress"
        style={{
            width: width,
            height: height,
            background: `
                radial-gradient(closest-side, white ${99-thickness}%, transparent ${100-thickness}% 100%),
                conic-gradient(${valueColor} ${value}%, ${totalColor} 0)
            `
        }}
    >
        {children}
    </div>
}
export default CircleProgress;