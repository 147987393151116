import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {currentLang, initLanguage, LangContextProvider, setupLanguage} from "./lib/Language";
import { BrowserRouter as Router } from "react-router-dom";
import './client.scss';
import {store} from "./application";
import {isSafari} from "./lib/Utils";
import { QueryClientProvider } from '@tanstack/react-query'
import {createAppQueryClient} from "./lib/QueryUtils";

const queryClient=createAppQueryClient();


setupLanguage("gui,validation,links");
Promise.all([ initLanguage(), store.init() ]).then(() => {
    store.updateLanguage(currentLang);
    ReactDOM.render(
        <QueryClientProvider client={queryClient}>
            <Router>
                <LangContextProvider onChange={(lang) => {
                    store.updateLanguage(lang);
                    if(isSafari()) {
                        window.location.reload();
                    } else {
                        window.updateIcon();
                    }
                }}>
                    <App />
                </LangContextProvider>
            </Router>
        </QueryClientProvider>,
        document.getElementById('root')
    );
});
