//@flow
import React from 'react';
import {RouteComponentProps} from "react-router";
import {store} from "../application";
import {Breadcrumb, Card} from "react-bootstrap";
import msgs, {currentLang, LangContext, langLink} from "../lib/Language";
import {BreadcrumbItem, PageHeader} from "../lib/Components";
import type {DifficultDebtInfo} from "../api";
import {formatSize, getDownloadLink} from "../lib/Upload";
import {formatDateTime} from "../lib/DateTime";
import DesktopScreen from "./DesktopScreen";
import {ServiceNotAvailable} from "../ClientComponents";
import {Redirect} from "react-router-dom";
import cn from "classnames";

type State = {
    files: Array<DifficultDebtInfo>|null;
}

const DifficultDebtsPage = ({ children }) => {
    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.gui.titleDifficultDebts}</BreadcrumbItem>
        </Breadcrumb>
        <Card className="mt-5 mx-auto static-text-page">
            <Card.Body>
                <PageHeader title={msgs.gui.titleDifficultDebts}/>
                {children}
            </Card.Body>
        </Card>
    </>;
}

export default class DifficultDebtsScreen extends React.Component<RouteComponentProps, State> {
    static url = "/difficult_debts";

    edit: boolean;
    lang: string;

    constructor(props) {
        super(props);
        this.edit = store.difficultDebts && store.difficultDebts.write;
        this.state = {
            files: null,
        }
    }

    refresh() {
        if(this.lang===currentLang.code) return;
        this.lang=currentLang.code;
        if(!store.difficultDebts || !store.difficultDebts.read) return;  // brak praw
        store.userApi.getDifficultDebts().then(files => {
            if(!files) {
                this.props.history.replace(langLink(DesktopScreen.url));
                return;
            }
            this.setState({ files })
        });
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps: $ReadOnly<RouteComponentProps>, prevState: $ReadOnly<State>, snapshot: any) {
        this.refresh();
    }

    render() {
        if(!store.difficultDebts) return <Redirect to={langLink("/")}/>;
        if(!store.difficultDebts.read) {
            return <DifficultDebtsPage><ServiceNotAvailable/></DifficultDebtsPage>
        }
        return <DifficultDebtsPage>
            {this.state.files?this.state.files.map((f: DifficultDebtInfo) => <a
                key={f.fileId}
                className={cn("file-download", store.user.demo && "link-disabled")}
                onClick={store.user.demo?(e=>e.preventDefault()):null}
                href={getDownloadLink(f.fileId, f.name)}
            >
                <span className="icon"><span className="icon-file-empty"/></span>
                <div className="data">
                    <div className="name">{f.name}</div>
                    <div className="info">
                        <span>{formatSize(f.size)}</span>
                        <span>{formatDateTime(f.date)}</span>
                    </div>
                </div>
            </a>):null}
        </DifficultDebtsPage>;
    }
}
DifficultDebtsScreen.contextType=LangContext;
DifficultDebtsScreen.mainClassName="screen-difficult-debts";