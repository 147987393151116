//@flow
import React from 'react';
import {useMsgs} from "./Language";
import {InlineDialog} from "./Components";

export type PreviewMode = 'email'|'sms';

export const InvoiceMonitoringPreviewDialog = ({ show, mode, scenario, step, nid, org, client, invoices, onHide }: {
    show?: boolean;
    /** Co ma być w podglądzie */
    mode: PreviewMode;
    /** Identyfikator powiadomienia */
    nid?: string;
    /** Id scenariusza */
    scenario?: string;
    /** Krok scenariusza, dla którego wyświetlić podgląd */
    step?: number;
    /** Id organizacji, dla której ma być wyświetlony podgląd */
    org?: string;
    /** Id kontrahenta, dla którego ma być wyświetlony podgląd */
    client?: string;
    /** Czy mają być załączone faktury dla widoku klienta */
    invoices?: boolean;
    onHide?: () => void;
}) => {
    const msgs=useMsgs();
    let loc=window.location.protocol+"//"+window.location.host+"/imp/";

    if(nid) {
        loc+=mode+"/"+org+"/"+nid;
    } else {
        loc += mode + "/" + scenario + '/' + step;
        if (org) loc += '/o/' + org;
        else if (client) {
            if (invoices) loc += '/i/' + client;
            else loc += '/c/' + client;
        } else loc += '/r/0';
    }

    return <InlineDialog
        title={msgs.gui.labelPreview} onHide={onHide}
        onCancel={onHide} show={show}
        size={mode==="sms"?null:"xl"}
        scrollable={false} cancelButton={msgs.gui.buttonClose}
        className={mode==="sms"?"preview-sms":"preview-email"}
    ><iframe src={loc}/></InlineDialog>
}
