//@flow
// Komponenty do budowania formularza do ustawienia praw, który widnieje na ekranach: 1740-1, 1320-2, 1311 oraz innych.
import React from 'react';
import {Col, Form, Row} from 'react-bootstrap'
import {currentLang, langCompare, useMsgs} from "./Language";
import type {AccessRight} from "../api";
import {InternalServices} from "../api";
import type {LangInfo} from "./Language";


/*
Konstrukcja komponentu jest następująca:
    <RightForm label="Usługa" info="Poziom uprawnień">
        <RightGroup label="Windykacje"/>
        <RightGroupData>
            <RightCheckbox/>
            <RightCheckbox/>
        </RightGroupData>
    </RightForm>
 */

/**
 * Komponent typu Radio, który służy do budowania nagłówka dla grupy praw
 */
export const RightGroupRadio = ({ id, label, readonly, value, type, onChange, className }: {
    id: string; label: string; className?: string;
    readonly ?: boolean; type: string;
    value: string; onChange: (value: string) => void;
}) => {
    return <Form.Check
        custom inline type="radio"
        disabled={readonly} id={id}
        label={label} className={className}
        checked={value===type} onChange={() => onChange(type)}
    />;
}

/**
 * Komponent wiersza danych dla formularza praw
 */
export const RightData = ({ label, children, className }: {
    label: string;
    className?: string;
}) => {
    return <div className={"data "+(className||"")}>
        <div className="left">{label}</div>
        <div className="right">{children}</div>
    </div>
}

export type RightPack = "all"|"some"|"none";

/**
 * Grupa praw, czyli komponent, który składa sie z etykiety oraz 3 opcji
 * typu radio - pełne, ograniczone, brak.
 */
export const RightGroup = ({ group, label, value, onChange, readonly }: {
    /** Nazwa grupy na potrzeby generowania idków */
    group?: string;
    label: string;
    value: RightPack;
    onChange: (value: RightPack) => void;
    readonly ?: boolean;
}) => {
    const msgs = useMsgs();
    const idPrefix = (group || "rg") + '_';
    return <RightData label={label}>
        <RightGroupRadio
            id={idPrefix + "all"} label={msgs.gui.rightsFull}
            className="text-success" readonly={readonly}
            type={"all"} value={value} onChange={onChange}
        />
        <RightGroupRadio
            id={idPrefix + "some"} label={msgs.gui.rightsSome}
            className="text-warning" readonly={readonly}
            type={"some"} value={value} onChange={onChange}
        />
        <RightGroupRadio
            id={idPrefix + "none"} label={msgs.gui.rightsNone}
            className="text-danger" readonly={readonly}
            type={"none"} value={value} onChange={onChange}
        />
    </RightData>
}

/**
 * Komponent, który jest nakładką na checkbox-a na potrzeby budowania formularza praw
 */
export const RightCheckbox = ({ id, readonly, label, value, onChange }: {
    id: string; readonly?: boolean; label: string;
    value: boolean; onChange: (value: boolean) => void;
}) => {
    return <Form.Check
        custom type="checkbox"
        disabled={readonly} id={id} label={label}
        checked={!!value} onChange={() => onChange(!value)}
    />;
}

export const RightGroupData = ({ label, children, cols }: {
    label?: string; cols?: boolean;
}) => {
    return <div className="record-data white">
        <Row>
            {label?<Col md={4}>{label}</Col>:null}
            {cols!==false?React.Children.map(children, (c, i) => <Col
                key={"gi"+i}
            >{c}</Col>):<Col md={8}>{children}</Col>}
        </Row>
    </div>;
}

export const RightForm = ({ label, children, info }: {
    label: string;
    info: string|React$Node;
}) => {
    return <div className="privilege">
        <div className="header">
            <div className="left">{label}</div>
            <div className="right">{info}</div>
        </div>
        {children}
    </div>;
}

/** Czy dany dostęp dotyczy windykacji */
export function isVindication(a: AccessRight) {
    return a.type==="Vindication" || a.type==="GlobalVindication";
}

/** Wyszukuje w tablicy elementu który jest dla windykacji */
export function findVindication(access: Array<AccessRight>): AccessRight {
    return access.find(a => isVindication(a));
}

/** Funkcja podmienia element w tablicy */
export function updateRight(src: Array<AccessRight>, a: AccessRight, change?: $Shape<AccessRight>) {
    if(change) a={ ...a, ...change };
    console.log("Change: ", change, a);
    return src.map((i: AccessRight) => (i.typeId===a.typeId)?a:i);
}

function typeOrder(type: InternalServices): number {
    switch (type) {
        case "Vindication": return 0;
        case "GlobalVindication": return 1;
        case "DifficultDebts": return 2;
        case "Monitoring": return 3;
        default: return 4;
    }
}

type AccessInfo = {
    type: InternalServices;
    name: string;
}

export function sortRights(src: Array<AccessInfo>, lang?: LangInfo|string): void {
    if(!Array.isArray(src)) return; // nie ma co sortować
    if(!lang) lang=currentLang.code;

    src.sort((a1: AccessInfo, a2: AccessInfo) => {
        let td=typeOrder(a1.type)-typeOrder(a2.type);
        if(td!==0) return td;
        return langCompare(a1.name, a2.name, lang);
    })
}