//@flow
import React from 'react';
import msgs, {formatString, LangContext, LangLink, langLink} from "../lib/Language";
import {AuthInfoScreen, AuthPage, waitGlass} from "../lib/Components";
import {Form} from "../lib/Form";
import {RouteComponentProps} from "react-router";
import {store} from "../application";
import LoginScreen from "./LoginScreen";
import {Modal} from "react-bootstrap";

let resetUserName=null;

export default class ResetPasswordScreen extends React.Component<RouteComponentProps, void> {
    static url="/password";

    render() {
        return (
            <AuthPage
                title={msgs.gui.titlePasswordReset}
                footer={<LangLink to={LoginScreen.url}>{msgs.gui.buttonBack}</LangLink>}
            >
                <Modal.Body>
                    <Form
                        initialValues={{ email: '' }}
                        validate={{ email: { email:true, required: true } }}
                        onSubmit={async (values, helpers) => {
                            const wg=waitGlass();
                            try {
                                let res = await store.publicApi.resetPassword(values.email);
                                if (!Form.setError(helpers, res)) {
                                    resetUserName = res;
                                    this.props.history.push(langLink(ResetEmailSendScreen.url));
                                }
                            }finally {
                                wg();
                            }
                        }}
                    >{(formik) => <>
                        <Form.FormError/>
                        <Form.Group name="email">
                            <Form.Label>{msgs.gui.labelEmail}</Form.Label>
                            <Form.Email/>
                        </Form.Group>
                        <div className="text-right">
                            <Form.Submit size="lg">{msgs.gui.actionResetPassword}</Form.Submit>
                        </div>
                    </>}</Form>
                </Modal.Body>
            </AuthPage>
        );
    }
}
ResetPasswordScreen.contextType=LangContext;

export class ResetEmailSendScreen extends React.Component<void, void> {
    static url="/password_info"

    render() {
        return <AuthInfoScreen
            title={formatString(msgs.gui.titleResetMessageSend, resetUserName)}
            html={msgs.gui.labelResetMessageSend}
            help footer={false}
            />
    }
}
ResetEmailSendScreen.contextType=LangContext;