//@flow
import React, {Suspense} from 'react';
import {Spinner} from "react-bootstrap";
import {ChartProps} from "./PieChart";

const PieChart = React.lazy(() => import("./PieChart"));

const LazyPieChart = (props: ChartProps) => {
    return <Suspense fallback={<Spinner animation="border" role="status"/>}>
        <PieChart {...props}/>
    </Suspense>
}
export default LazyPieChart;