//@flow
import React, {useMemo, useState} from 'react';
import {useMsgs} from "../lib/Language";
import FilterInput, {emptyQuery} from "../lib/Filter";
import {useDelayed, useHistoryState} from "../lib/Hooks";
import {store} from "../application";
import {Redirect} from "react-router-dom";
import {Breadcrumb, Card} from "react-bootstrap";
import {BreadcrumbItem, PageHeader} from "../lib/Components";
import DesktopScreen from "./DesktopScreen";
import InvoiceMonitoringDesktopScreen from "./InvoiceMonitoringDesktopScreen";
import type {FilterFieldConfig, FilterInputProps} from "../lib/Filter";
import {dateTimeCell, QueryDataTable} from "../lib/DataTable";
import type {DataTableColumn, DataTableProps} from "../lib/DataTable";
import type {IMSubscriptionHistory} from "../api";
import {formatMoney} from "../lib/Utils";

/**
 * Komponent z listą synchronizacji
 */
const SubscriptionTable = (props: $Diff<DataTableProps, { columns: any, data: any }>) => {
    const msgs = useMsgs();
    const columns = useMemo<Array<DataTableColumn<IMSubscriptionHistory>>>(() => [
        {
            accessor: 'time',
            Header: msgs.gui.labelEventTime,
            Cell: dateTimeCell,
            className: "datetime",
        }, {
            accessor: 'type',
            Header: msgs.gui.labelType,
            className: "lg",
            Cell: ({ value }) => msgs.gui["imsEvent"+value] || value,
        }, {
            accessor: 'amount',
            Header: msgs.gui.labelAmount,
            Cell: ({ value }) => value?formatMoney(value, "PLN"):"---",
        }, {
            accessor: "number",
            Header: msgs.gui.labelInvoiceNumber,
            Cell: ({ value, row }) => {
                if(!value) return "---";
                return <a href={"/data/op/"+row.original.paymentId+"/"+row.original.invoiceFilename} target="_blank">{value}</a>;
            },
        }, {
            accessor: "paymentStatus",
            Header: msgs.gui.labelStatus,
            Cell: ({ value }) => value?(msgs.gui['imsStatus'+value] || value):"---",
        }
    ], [ msgs ]);
    return <QueryDataTable columns={columns} path="/user" func="querySubscriptionHistory" initialSortBy="-time" {...props}/>;
}

const SubscriptionFilter = ({ ...props }: $Diff<FilterInputProps, { fields: any }> ) => {
    const msgs = useMsgs();
    const fields = useMemo<Array<FilterFieldConfig>>(() => [
        {
            permanent: true,
            field: "date",
            label: msgs.gui.labelEventTime,
            type: "date",
        }
    ], [ msgs.gui.language ]);


    return <FilterInput fields={fields} {...props}/>
}


const InvoiceMonitoringSubscriptionHistoryScreen = () => {
    const msgs = useMsgs();
    // const [ filter, setFilter ] = useState(() => emptyQuery());
    const [ filter, setFilter ] = useHistoryState("filter", () => emptyQuery());
    const [ dFilter, setDFilter ] = useDelayed(filter, setFilter);
    if(!store.invoiceMonitoring || !store.invoiceMonitoring.read) return <Redirect to="/" push={false}/>
    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
            <BreadcrumbItem to={InvoiceMonitoringDesktopScreen.link()}>{msgs.gui.menuInvoiceMonitoringDesktop}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.gui.menuInvoiceMonitoringSubscriptionHistory}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.gui.menuInvoiceMonitoringSubscriptionHistory}/>
        <Card className="mb-5">
            <Card.Body>
                <SubscriptionFilter value={dFilter} onChange={setDFilter} mainField={false}/>
            </Card.Body>
        </Card>
        <SubscriptionTable customFilters={filter} historyState="table"/>
    </>;

}
InvoiceMonitoringSubscriptionHistoryScreen.url="/im/history";
InvoiceMonitoringSubscriptionHistoryScreen.link = () => InvoiceMonitoringSubscriptionHistoryScreen.url;

export default InvoiceMonitoringSubscriptionHistoryScreen;