//@flow
import React from 'react';
import {RouteComponentProps} from "react-router";
import msgs, {LangContext, langLink} from "../lib/Language";
import {Redirect} from "react-router-dom";

export type InlineScreenType="invoice_monitoring" | "knowledge_base";

export default class ExternalScreen extends React.Component<RouteComponentProps<{ target: InlineScreenType }>, void> {
    static url="/external/:target";
    static link = (target: InlineScreenType) => "/external/"+target;

    componentDidMount() {
        document.getElementById("main").classList.add("flex-view");
    }

    componentWillUnmount() {
        document.getElementById("main").classList.remove("flex-view");
    }

    render() {
        const { target } =this.props.match.params;
        if(target!=="invoice_monitoring" && target!=="knowledge_base") return <Redirect to={langLink("/")}/>;;

        let url;
        if(target==="invoice_monitoring") url=msgs.links.invoiceMonitoring;
        else if(target==="knowledge_base") url=msgs.links.knowledgeBase;
        else return null;

        return <iframe
            className="external-page"
            frameBorder={0}
            src={url}
            width="100%"
            height="100%"
        />;
    }
}
ExternalScreen.contextType=LangContext;
ExternalScreen.mainClassName="screen-external"

