//@flow
import React from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export type ChartEntry = {
    /** Nazwa */
    name: string,
    /** Wartość numeryczna */
    value: number,
    /** Kolor */
    color?: number,
    /** Etykieta do użycia w legendzie */
    legend?: string;
}

export type ChartProps = {
    data: Array<ChartEntry>,
    colors?: Array<string>;
};

function legendFormatter(value, entry: ChartEntry) {
    return entry.legend || entry.name || entry.value;
}

const Chart = ({ data, colors }: ChartProps) => {
    if (!Array.isArray(colors) || colors.length === 0) colors = COLORS;

    if (!Array.isArray(data) || data.length === 0) return null;
    return <ResponsiveContainer minHeight={180}>
        <PieChart>
            <Pie
                data={data}
                dataKey="value"
                nameKey="name"
                cx="50%" cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                // outerRadius={60}
            >{data.map((entry: ChartEntry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color || colors[index % colors.length]}/>
            ))
            }</Pie>
            <Legend layout='vertical' align='right' verticalAlign='middle' formatter={legendFormatter}/>
            <Tooltip/>
        </PieChart>
    </ResponsiveContainer>
}
export default Chart;