//@flow    static url="/password/:code";

import React from 'react';
import {FormControl, Modal} from "react-bootstrap";
import {AuthInfoScreen, AuthPage} from "../lib/Components";
import msgs, {LangContext, langLink} from "../lib/Language";
import {RouteComponentProps} from "react-router";
import {store} from "../application";
import {Form} from "../lib/Form";

type State = {
    valid: boolean|null;
}

export default class PasswordChangeScreen extends React.Component<RouteComponentProps<{ code: string }>, State> {
    static url="/password/:code";
    static link=(code: string) => "/password/"+code;

    constructor(props) {
        super(props);
        this.state={
            valid: null,
        }
    }

    componentDidMount() {
        store.publicApi.isValidCode(this.props.match.params.code).then(valid => {
            this.setState({ valid });
        });
    }

    render() {
        if(this.state.valid===null) return null;
        return (
            <AuthPage title={msgs.gui.titleChangePassword}>
                <Form
                    initialValues={{
                        password: "",
                        password2: "",
                    }}
                    initialStatus={null}
                    onSubmit={async (values, helper) => {
                        const res=await store.publicApi.changePassword(this.props.match.params.code, values.password);
                        console.log("Got response", res);
                        if(Form.setError(helper, res)) return;
                        this.props.history.push(langLink(PasswordChangedScreen.url));
                    }}
                >{formik => {
                    const same=formik.values.password===formik.values.password2 || !formik.getFieldMeta("password2").touched;
                    return <Modal.Body>
                        {!this.state.valid ? <h5 className="text-danger">{msgs.gui.resetInvalidCode}</h5> : <>
                            <Form.FormError/>
                            <Form.Group name="password">
                                <Form.Label>{msgs.gui.labelNewPassword}</Form.Label>
                                <Form.Password checkStrength={true}/>
                            </Form.Group>
                            <Form.Group name="password2">
                                <Form.Label>{msgs.gui.labelRepeatPassword}</Form.Label>
                                <Form.Password isInvalid={!same}/>
                                {!same ? <FormControl.Feedback type="invalid">{msgs.gui.validatePasswordDifferent}</FormControl.Feedback> : null}
                            </Form.Group>
                            <div className="text-right">
                                <Form.Submit size="lg">{msgs.gui.actionChangeAndLogin}</Form.Submit>
                            </div>
                        </>}
                    </Modal.Body>
                }}</Form>
            </AuthPage>
        );
    }
}
PasswordChangeScreen.contextType=LangContext;

export class PasswordChangedScreen extends React.Component<void, void> {
    static url="/password_changed";

    render() {
        return <AuthInfoScreen title={msgs.gui.titleResetPasswordChanged} html={msgs.gui.labelResetPasswordChanged}/>;
    }
}
PasswordChangedScreen.contextType=LangContext;