//@flow
// Pomocnicze hooki
import {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {getLocationState } from "./Utils";

/**
 * Hook, który wywołuje callback z opóźnieniem. Typowe użycie:
 *     const [ filter, setFilter ] = useState(() => emptyQuery());
 *     const [ dFilter, setDFilter ] = useDelayed(filter, setFilter);
 */
export function useDelayed<T>(initialValue: T, setDelayedValue: (value: T) => void, delay?: number) {
    if(!delay) delay=800;

    const [ value, setValue ] = useState(initialValue);
    useEffect(() => {
        const timer=window.setTimeout(() => {
            // console.log("setDelayedValue Timeout: ", value);
            setDelayedValue(value);
        }, delay);
        return () => window.clearTimeout(timer);
    }, [ delay, value, setDelayedValue ]);
    // console.log("useDelayed value: ", value);

    return [ value, setValue ]
}

/**
 * Pomocniczy hook, który jest opakowaniem na setState, który zapisuje stan w historii przeglądarki.
 * @param stateName nazwa stanu w historii przeglądarki
 * @param initialValue wartość początkowa, gdy nie ma w stanie przeglądarki
 */
export function useHistoryState<T>(stateName: string, initialValue: T|() => T): [ T, (value: T|(old: T) => T) => void ] {
    const history=useHistory();
    // const location=useLocation();

    const [ value, setValue ] = useState(() => {
        const location=history.location;
        let v=getLocationState(location, stateName)
        if(v!==undefined) return v;
        if(typeof(initialValue)==='function') return initialValue();
        else return initialValue;
    });

    const setHistoryValue=useCallback((newValue: T|(old: T) => T) => {
        const location=history.location;
        // console.log("setHistoryValue Callback: ", newValue, location);

        setValue((old) => {
            if(typeof(newValue)==='function') newValue=newValue(old);   // gdy funkcja
            if(old===newValue) return old;  // brak zmiany
            // replaceHistoryState(history, location, stateName, newValue);
            let state;
            if(typeof(location.state)==='object') {
                if(location.state[stateName]===newValue) return newValue;    // brak zmiany
                state={ ...location.state, [stateName]: newValue }
            } else {
                state={ [stateName]: newValue }
            }
            // console.log("setHistoryValue update/replace: ", newValue, location.pathname, location.state);
            window.setTimeout(() => {
                history.replace( location.pathname, state );
            }, 0)
            return newValue;
        });
    }, [ stateName, history, /* location.pathname, location.state */ ]);
    // console.log("useHistoryState value: ", value, history);
    return [ value, setHistoryValue ]
}