//@flow
import React, {useMemo} from 'react';
import {getLangValue, useMsgs} from "./Language";
import type {Dictionaries, LangOption, Option, UserOrganizationData, VindicationDictionaries} from "../api";
import {AccessRight} from "../api";
import {RightCheckbox, RightData, RightForm, RightGroup, RightGroupData} from "./Rights";
import {StatusInput} from "./Components";
import {arrayToggleItem, arrayUpdate} from "./Utils";
import {Col, Row} from "react-bootstrap";
import {VindicationFilter} from "./VindicationsView";
import type {FilterQuery} from "./Filter";

function hasSetting(settings: {}, key: string): boolean {
    if(typeof(settings)!=='object') return false;
    return settings[key]===true;
}

function updateSettings(settings: {}, key: string, val: boolean): {} {
    if(typeof(settings)!=='object') return { [key]: val };
    return {
        ...settings,
        [key]: val
    }
}

/**
 * Dedykowany komponent dla praw windykacji, ze względu na dodatkowe ustawienia - ograniczenie filtrem
 */
const VindicationRights = ({ idPrefix, value, onChange, dicts, globalDicts }: {
    idPrefix: string;
    value: AccessRight;
    onChange: (value: AccessRight) => void;
    dicts: VindicationDictionaries,
    globalDicts: Dictionaries
}) => {
    const msgs=useMsgs();
    const filterValue=useMemo<FilterQuery>(() => {
        return {
            text: "",
            fields: value.filter || []
        }
    }, [value.filter] );

    return <RightGroupData label={getLangValue(value.name)} cols={false}>
        <Row>
            <Col md={6}>
                <RightCheckbox
                    id={idPrefix+"view"}
                    label={msgs.gui.accessRead}
                    value={value.read}
                    onChange={() => onChange( { ...value, read: !value.read } )}
                />
            </Col>
            <Col md={6}>
                <RightCheckbox
                    id={idPrefix+"write"}
                    label={msgs.gui.accessEdit}
                    value={value.write}
                    onChange={() => onChange( { ...value, write: !value.write } )}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <VindicationFilter
                    dicts={dicts}
                    globalDicts={globalDicts}
                    mainField={false}
                    value={filterValue}
                    rightsMode={true}
                    onChange={(query: FilterQuery) => {
                        onChange({
                            ...value,
                            filter: query.fields.length===0?null:query.fields
                        })
                    }}
                />
            </Col>
        </Row>
    </RightGroupData>
}

const InvoiceMonitoringRight = ({ idPrefix, value, onChange, dicts, globalDicts }: {
    idPrefix: string;
    value: AccessRight;
    onChange: (value: AccessRight) => void;
    dicts: VindicationDictionaries,
    globalDicts: Dictionaries
}) => {
    const msgs=useMsgs();

    return <RightGroupData label={getLangValue(value.name)} cols={false}>
        <Row>
            <Col md={6}>
                <RightCheckbox
                    id={idPrefix+"view"}
                    label={msgs.gui.accessRead}
                    value={value.read}
                    onChange={() => onChange( { ...value, read: !value.read } )}
                />
            </Col>
            <Col md={6}>
                <RightCheckbox
                    id={idPrefix+"write"}
                    label={msgs.gui.accessEdit}
                    value={value.write}
                    onChange={() => onChange( { ...value, write: !value.write } )}
                />
            </Col>
        </Row>
        <Row className="mt-2">
            <Col md={6}>
                <RightCheckbox
                    id={idPrefix+"payment"}
                    label={msgs.gui.accessPayment}
                    value={hasSetting(value.settings, "im_subscription")}
                    onChange={() => onChange( { ...value, settings: updateSettings(value.settings, 'im_subscription', !hasSetting(value.settings, "im_subscription")) } )}
                />
            </Col>
        </Row>
    </RightGroupData>
}


/**
 * Pomocniczy komponent do ustawiania praw per usługa
 */
const ServiceRights=({ idPrefix, value, onChange, dicts, globalDicts }: {
    idPrefix: string;
    value: AccessRight;
    onChange: (value: AccessRight) => void;
    dicts: VindicationDictionaries,
    globalDicts: Dictionaries
}) => {
    const msgs=useMsgs();
    if(value.type==="Vindication") {    // windykacje obsługujemy inaczej, bo wchodzi w grę filtr
        return <VindicationRights dicts={dicts} globalDicts={globalDicts} idPrefix={idPrefix} value={value} onChange={onChange} />
    }
    if(value.type==="InvoiceMonitoring") {
        return <InvoiceMonitoringRight dicts={dicts} globalDicts={globalDicts} idPrefix={idPrefix} value={value} onChange={onChange} />
    }
    return <RightGroupData label={getLangValue(value.name)}>
        <RightCheckbox
            id={idPrefix+"view"}
            label={msgs.gui.accessRead}
            value={value.read}
            onChange={() => onChange( { ...value, read: !value.read } )}
        />
        <RightCheckbox
            id={idPrefix+"write"}
            label={msgs.gui.accessEdit}
            value={value.write}
            onChange={() => onChange( { ...value, write: !value.write } )}
        />
    </RightGroupData>
}

/**
 * Komponent do konfiguracji użytkowników w firmie.
 * Dostępy w dwóch widokach - firma -> lista użytkowników, użytkownik -> lista firm.
 */
export const UserCompanySettings= ({ organizations, onChange, readonly, userRights, companyView, dicts, globalDicts }: {
    organizations: Array<UserOrganizationData>;
    onChange: (value: Array<UserOrganizationData>) => void;
    userRights: Array<LangOption>;
    /** Czy widok administratora (więcej opcji?) */
    admin?: boolean;
    readonly ?: boolean;
    /** Czy widok z poziomu firmy - wówczas użytkownik jest lewą kolumną */
    companyView?: boolean;
    dicts: VindicationDictionaries;
    globalDicts: Dictionaries;
}) => {
    const msgs = useMsgs();

    return <RightForm
        label={companyView ? msgs.gui.labelFullName : msgs.gui.labelCompany}
        info={msgs.gui.labelRightsLevel}
    >{organizations.map((uo: UserOrganizationData) => {
        const update = (up: $Shape<UserOrganizationData>) => {
            let it = {...uo, ...up};
            onChange(organizations.map((i: UserOrganizationData) => i.id === it.id ? it : i));
        }
        return <div key={uo.id} className="record">
            <RightData className="user-status" label={companyView ? uo.name : uo.org}>
                <StatusInput value={uo.status} onChange={(status) => update({status})}/>
            </RightData>
            <RightGroup
                group={uo.id}
                label={""}
                value={uo.mode === true ? "all" : (uo.mode === false ? "none" : "some")}
                onChange={(mode) => {
                    switch (mode) {
                        case "all":
                            update({mode: true});
                            break;
                        case "some":
                            update({mode: null});
                            break;
                        case "none":
                            update({mode: false});
                            break;
                    }
                }}
            />
            {uo.mode !== null ? null : <>
                <RightGroupData label={msgs.gui.labelManageOrganization}>
                    {userRights.map((o: Option) => <RightCheckbox
                        key={o.value}
                        id={"or_" + uo.id + "_" + o.value}
                        label={getLangValue(o.label)}
                        value={uo.rights.includes(o.value)}
                        onChange={() => update({rights: arrayToggleItem(uo.rights, o.value)})}
                    />)}
                </RightGroupData>
                {!Array.isArray(uo.services) ?
                    <p className="text-warning text-center mt-2">{msgs.gui.hintOrganizationNoPacket}</p>
                    : uo.services.map((r: AccessRight) => <ServiceRights
                        dicts={dicts}
                        globalDicts={globalDicts}
                        idPrefix={"osr_" + uo.id + "_" + r.typeId + "_"}
                        key={r.typeId}
                        value={r}
                        onChange={(v) => update({services: arrayUpdate(uo.services, v, (v1, v2) => v1.typeId === v2.typeId)})}
                    />)
                }
            </>}

        </div>;
    })}</RightForm>;
}


