//@flow
import React, {useState} from 'react';
import {Button, Dropdown, Form, InputGroup} from "react-bootstrap";
import {Icon, SmartMoneyField} from "./Components";
import msgs, {currentLang, formatString, LangContext} from "./Language";
import {DateInput, formatDate, parseDate} from "./DateTime";
import type {FieldFilter} from "../api";
import DropdownItem from "react-bootstrap/DropdownItem";
import {formatMoney, getOptionLabel, getOptionValue, parseMoney} from "./Utils";
import type {SelectOption} from "./Utils";
import cn from 'classnames';

/*
    W tym pliku jest cała definicja komponentu filtrowania.
    Komponent działa w oparciu o konfigurację przekazaną przez FilterFieldConfig.
    I odpowiednio:
    - boolean - tak/nie,
    - date - zakres dat; w wyniku jest to tablica [ string_od, string_do ],
    - activity - liczba dni, w wyniku jest numer,
    - options - wiele opcji; w wyniku tablica wybranych opcji,
    - select - jeden z wielu,
    - string - pole tekstowe,
    - stringNumber - pole tekstowe ograniczone do liczby (input)

    Dla string może zostać podany walidator.
    Dla należy podać funkcję danych lub dane.
 */

export type { FieldFilter } from "../api";

/** Pomocniczy typ dla filtra */
export type FilterQuery = {
    text: string;
    fields: Array<FieldFilter>;
}

export function cloneFilter(filter: FilterQuery): FilterQuery {
    if(!filter) return filter;
    return {
        text: filter.text,
        fields: [...filter.fields],
    }
}

/**
 * Typ z informacjami o filtrowaniu pola
 */
export type FilterFieldConfig = {
    /** Nazwa/identyfikator */
    field: string;
    /** Nazwa wyświetlana */
    label: string;
    /** Opcjonalna nazwa klasy dla fragmentu */
    className?: string;
    /** Domyślna/początkowa wartość */
    initial?: any|() => any;
    /** Rodzaj pola */
    type: 'boolean'|'string'|'date'|'options'|'activity'|'select'|'number'|'money'|'stringNumber';
    /** Czy pole jest tylko do odczytu */
    readonly?: boolean;
    /** Opcjonalny walidator dla typu string */
    validator?: (text: string) => string|null;
    /** Dostępne opcje */
    options?: Array<SelectOption>;
    /** Czy pozycja zawsze wybrana */
    permanent?: boolean;
    /** Opcjonalna funkcja do pobierania wartości dla opcji */
    getValue?: (option: any) => any;
    /** Opcjonalna funkcja do pobierania etykiety dla opcji */
    getLabel?: (option: any) => string;
}

export function emptyQuery(): FilterQuery {
    return {
        text: "",
        fields: [],
    }
}

export function createSimpleQuery(text: string, field: string, value: any) {
    return {
        text,
        fields: [ { field, value } ]
    }
}

/**
 * Funkcja sprawdza, czy podany filtr ma jakieś ustawione warunki
 */
export function isEmptyQuery(query: FilterQuery): boolean {
    if(typeof(query)!=='object') return true;
    if(typeof(query.text)==='string' && query.text.length>0) return false;
    if(!Array.isArray(query.fields) || query.fields.length===0) return true;
    for(let f of query.fields) {
        if(f.value===null || f.value===undefined) continue;
        return false;
    }
    return true;
}

/** Funkcja zwraca wartość do wyświetlenia dla pola filtra */
function getFieldDisplayValue(f: FieldFilter, cfg: FilterFieldConfig): string|React$Node {
    const lGetLabel=cfg.getLabel?cfg.getLabel:getOptionLabel;
    const lGetValue=cfg.getValue?cfg.getValue:getOptionValue;

    switch (cfg.type) {
        case "boolean":
            if(f.value===true) return msgs.gui.labelYes;
            else if(f.value===false) return msgs.gui.labelNo;
            return "";
        case "string":
        case "stringNumber":
            return f.value;
        case "options":
            if (!Array.isArray(f.value) || f.value.length === 0) return '';    // nic nie wybrano
            if (f.value.length === 1) {   // jeden element
                const sel = f.value[0];
                const selO = cfg.options.find((o: SelectOption) => lGetValue(o) === sel);
                if (selO) return lGetLabel(selO);
                else return sel;    // fallback
            }
            // więcej opcji - tylko infomacja o liczbie wybranych
            return formatString(msgs.gui.labelOptionsSelected, f.value.length);
        case "select":
            if (!f.value) return ""; // nie wybrano (?)
            const sel = cfg.options.find((o: SelectOption) => lGetValue(o) === f.value);
            if (sel) return lGetLabel(sel);
            return f.value; // fallback
        case "activity": {
            if (typeof (f.value) !== 'number' || f.value === 0) return "";
            const isNegative = f.value<0 || Object.is(-0, f.value);
            const val=Math.abs(f.value);
            const sign=(isNegative?'\u2265':'\u2264')+' ';
            if (f.value === 1) return sign+formatString(msgs.gui.labelActivityDay, val);
            return sign+formatString(msgs.gui.labelActivityDays, val);
        }
        case "date": {
            if (!Array.isArray(f.value) || f.value.length !== 2) return '';
            const from = parseDate(f.value[0]);
            const to = parseDate(f.value[1]);
            if (!from && !to) return "";
            if (from && to) return formatString(msgs.gui.labelDateRange, formatDate(from), formatDate(to));
            if (from) return formatString(msgs.gui.labelDateRangeFrom, formatDate(from));
            return formatString(msgs.gui.labelDateRangeTo, formatDate(to));
        }
        case "money": {
            if (!Array.isArray(f.value) || f.value.length !== 2) return '';
            const from = parseMoney(f.value[0]);
            const to = parseMoney(f.value[1]);
            if (!from && !to) return "";
            if (from && to) return formatString(msgs.gui.labelDateRange, formatMoney(from), formatMoney(to));
            if (from) return formatString(msgs.gui.labelDateRangeFrom, formatMoney(from));
            return formatString(msgs.gui.labelDateRangeTo, formatMoney(to));
        }
        default:
            return "";
    }
}

/**
 * Pomocniczy komponent do wyświetlania pola filtrowania.
 */
const FilterFieldInput = ({ id, label, value, children, onRemove, onClear, className, readonly }: {
    id: string;
    label: string;
    className?: string;
    value: string|React$Node;
    readonly?: boolean;
    onRemove?: () => void;
    onClear?: () => void;
}) => {
    const [ open, setOpen ] =useState(false);
    if(readonly) {
        return <div className={cn('filter-item', 'readonly')}>
            <InputGroup>
                <InputGroup.Text className={className}>
                    <div className={cn(!value ? "no-value" : undefined, 'readonly-content')}>
                        <label>{label}</label>
                        {(value !== null && value !== undefined) ? <span>{value}</span> : undefined}
                    </div>
                </InputGroup.Text>
            </InputGroup>
        </div>
    }

    return <Dropdown
        className="filter-item"
        show={open}
        onToggle={(isOpen) => {
            setOpen(isOpen);
        }}
    >
        <Dropdown.Toggle id={id} className={className}>
            {onRemove?<span className="remove" role="img" aria-label="Usuń" onClick={(e) => {
                e.stopPropagation(); e.preventDefault(); onRemove();
            }}>❌</span>:null}
            {onClear?<span className="remove" role="img" aria-label="Wyczyść" onClick={(e) => {
                e.stopPropagation(); e.preventDefault(); onClear();
            }}>&#9003;</span>:null}
            <div className={!value?"no-value":undefined}>
                <label>{label}</label>
                {(value!==null && value!==undefined)?<span>{value}</span>:undefined}
            </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {children}
        </Dropdown.Menu>
    </Dropdown>;
}



export type FilterInputProps = {
    /** Czy filtr jest zablokowany (tylko do odczytu) */
    locked?: boolean;
    fields: Array<FilterFieldConfig>;

    /** Czy główne pole tekstowe widoczne */
    mainField?: boolean;
    value: FilterQuery;
    onChange: (filter: FilterQuery) => void;
    /** Funkcja wywoływana po kliknięciu w lupę */
    onSearchClick?: () => void;
    className?: string;
    /** Czy tylko do odczytu */
    readonly?: boolean;
}

type State = {
}

/**
 * Komponent filtra
 */
export default class FilterInput extends React.Component<FilterInputProps, State> {
    /** Wybrane opcje z dostępnych */
    all: Map<string, FilterFieldConfig>;

    lang: string;

    constructor(props) {
        super(props);
        this.lang=currentLang.code;
        this.all=new Map();
        this.props.fields.forEach((f: FilterFieldConfig) => this.all.set(f.field, f));

        let res=[...this.props.value.fields];
        this.props.fields.forEach((f: FilterFieldConfig) => {
            if(!f.permanent || this.getActiveFilter(f.field)) return;
            res.push({ field: f.field, value: null });
        })
        if(res.length>this.props.value.fields) this.props.onChange({
            text: this.props.value.text,
            fields: res,
        });

    }

    getActiveFilter(field: string): FieldFilter|undefined {
        return this.props.value.fields.find((f: FieldFilter) => f.field===field)
    }

    componentDidUpdate(prevProps: $ReadOnly<Props>, prevState: $ReadOnly<State>, snapshot: SS) {
        if(this.props.fields!==prevProps.fields) {
            this.all=new Map();
            this.props.fields.forEach((f: FilterFieldConfig) => this.all.set(f.field, f));
            this.forceUpdate();
        }
        return true;
    }

    componentDidMount() {
    }

    /** Obsługa wybrania pola z listy */
    onAddFilter(f: FilterFieldConfig, e: Event) {
        let value=null;
        if(f.initial) {
            if(typeof (f.initial)==='function') value=f.initial();
            else value=f.initial;
        }

        this.props.onChange({
            text: this.props.value.text,
            fields: [ ...this.props.value.fields, {
                field: f.field,
                value
            }]
        })
    }

    onRemoveFilter(f: FilterFieldConfig) {
        this.props.onChange({
            text: this.props.value.text,
            fields: this.props.value.fields.filter((i: FieldFilter) => i.field!==f.field)
        })
    }

    onClearFilter(f: FilterFieldConfig) {
        this.props.onChange({
            text: this.props.value.text,
            fields: this.props.value.fields.map((i: FieldFilter) => {
                if(i.field!==f.field) return i;
                return { field: i.field, value: null }
            })
        })
    }

    renderFieldForm(f: FieldFilter, cfg: FilterFieldConfig) {
        const lGetLabel=cfg.getLabel?cfg.getLabel:getOptionLabel;
        const lGetValue=cfg.getValue?cfg.getValue:getOptionValue;


        const update=(value) => {
            // console.log("Update of ", f.field, "=", f.value, " => ", value)
            const fields=this.props.value.fields.map((i: FieldFilter) => {
                if(i.field!==f.field) return i;
                return {
                    field: f.field,
                    value: value,
                }
            });
            // console.log("New fields: ", fields);
            this.props.onChange({
                text: this.props.value.text,
                fields: fields
            })
        }

        switch (cfg.type) {
            case "boolean":
                return <>
                    <Form.Group>
                        <Form.Check
                            custom
                            type="radio"
                            id={"ff-"+cfg.field+"-yes"}
                            label={msgs.gui.labelYes}
                            checked={f.value===true}
                            onChange={() =>{}}
                            onClick={() => update(true)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            custom
                            type="radio"
                            id={"ff-"+cfg.field+"-no"}
                            label={msgs.gui.labelNo}
                            checked={f.value===false}
                            onChange={() =>{}}
                            onClick={() => update(false)}
                        />
                    </Form.Group>
                </>;
            case "options": {
                const cur: Array<string> = Array.isArray(f.value) ? f.value : [];
                const sel = new Set(cur);

                return <>
                    {cfg.options.map((o: SelectOption) => {
                        const id = lGetValue(o);
                        const key = "ff-" + cfg.field + "-" + id;
                        const checked = sel.has(id);
                        return <Form.Check
                            custom type="checkbox" id={key} key={key}
                            label={lGetLabel(o)}
                            checked={checked}
                            onChange={() => {
                            }}
                            onClick={() => {
                                if (checked) update(cur.filter(i => i !== id));
                                else update([id, ...cur]);
                            }}
                        />;
                    })}
                </>;
            }
            case "select":
                if(cfg.options.length>20) {
                    return <Form.Control
                        as="select"
                        value={f.value || ""}
                        onChange={(e) => {
                            update(e.target.value);
                        }}
                    >
                        {cfg.options.map((o: SelectOption) => {
                            const v = lGetValue(o);
                            return <option key={v} value={v}>{lGetLabel(o)}</option>;
                        })}
                    </Form.Control>
                } else {
                    return cfg.options.map((o: SelectOption) => {
                        const v = lGetValue(o);
                        return <DropdownItem
                            key={v}
                            active={f.value === v}
                            onClick={() => update(v)}
                        >{lGetLabel(o)}</DropdownItem>;
                    });
                }
            case "date": {
                const from = Array.isArray(f.value) ? f.value[0] : null;
                const to = Array.isArray(f.value) ? f.value[1] : null;
                return <>
                    <Form.Group>
                        <Form.Label>{msgs.gui.labelFrom}</Form.Label>
                        <DateInput
                            value={from}
                            onChange={(from) => update([ from, to ])}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{msgs.gui.labelTo}</Form.Label>
                        <DateInput
                            value={to}
                            onChange={(to) => update([ from, to ])}
                        />
                    </Form.Group>
                </>;
            }
            case "money": {
                const from = Array.isArray(f.value) ? f.value[0] : null;
                const to = Array.isArray(f.value) ? f.value[1] : null;
                return <>
                    <Form.Group>
                        <Form.Label>{msgs.gui.labelFrom}</Form.Label>
                        <SmartMoneyField value={from} onChange={(from) => update([ from, to ])}/>
                        {/*<Form.Control type="number" step={0.01}*/}
                        {/*    value={from || ""}*/}
                        {/*    onChange={(from) => update([ parseMoney(from.target.value), to ])}*/}
                        {/*/>*/}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{msgs.gui.labelTo}</Form.Label>
                        <SmartMoneyField value={to} onChange={(to) => update([ from, to ])}/>
                        {/*<Form.Control type="number" size={0.01}*/}
                        {/*    value={to || ""}*/}
                        {/*    onChange={(to) => update([ from, parseMoney(to.target.value) ])}*/}
                        {/*/>*/}
                    </Form.Group>
                </>;
            }
            case "activity": {
                const value = f.value;
                const isNegative = value < 0 || Object.is(-0, value);
                return <>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <Button variant="outline-primary" onClick={() => update(-value)}>{isNegative?"\u2265":"\u2264"}</Button>
                            </InputGroup.Prepend>
                            <Form.Control
                                size="md"
                                className="set-activity"
                                type="number"
                                ref={r => {
                                    if (r != null) window.setTimeout(() => r.focus(), 1)
                                }}
                                value={(value === null || value === undefined) ? "" : (isNegative?-value:value)}
                                onChange={(e) => {
                                    let v = parseInt(e.target.value);
                                    if (isNaN(v)) update(null);
                                    else if (v < 0) update(0);
                                    else update((isNegative?-1:1)*v);
                                }}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>{msgs.gui.labelUnitDays}</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                </>;
            }
            case "string":
                return <>
                    <Form.Group>
                        <Form.Control
                            type="text"
                            ref={r => { if(r!=null) window.setTimeout(() => r.focus(), 1)}}
                            value={typeof(f.value)==='string'?f.value:""}
                            onChange={e => update(e.target.value)}
                        />
                    </Form.Group>
                </>;
            case "stringNumber":
                return <>
                    <Form.Group>
                        <Form.Control
                            type="number"
                            ref={r => { if(r!=null) window.setTimeout(() => r.focus(), 1)}}
                            value={typeof(f.value)==='string'?f.value:""}
                            onChange={e => update(e.target.value)}
                        />
                    </Form.Group>
                </>;
            default:
                return null;
        }
    }

    render() {
        let fieldsLeft=this.props.fields
            .filter((f: FilterFieldConfig) => !this.getActiveFilter(f.field));
        // console.log("Render: ", this.props.value);

        return <Form inline className={cn("filter", this.props.className)} onSubmit={(e) => e.preventDefault()}>
            {this.props.mainField===false?null:<Form.Group className="main-search filter-main">
                <InputGroup>
                    <Form.Control
                        type="text"
                        value={this.props.value.text}
                        readOnly={this.props.readonly}
                        onChange={e => {
                            const v=e.target.value;
                            this.props.onChange({ text: v, fields: this.props.value.fields })
                        }}
                    />
                    <InputGroup.Append>
                        <Button
                            variant="outline-secondary"
                            onClick={() => {
                                if(this.props.onSearchClick) this.props.onSearchClick(this.props.value);
                            }}
                        ><Icon.Search/></Button>
                    </InputGroup.Append>
                </InputGroup>
            </Form.Group>}
                {this.props.value.fields.map((f: FieldFilter) => {
                    const cfg: FilterFieldConfig = this.all.get(f.field);
                    if(!cfg) {
                        if(f.value===null || f.value===undefined) return null;  // nie ma problemu
                        console.warn("Missing field configuration "+f.field);
                        return null;
                    }
                    return <FilterFieldInput
                        key={f.field}
                        id={"ff-" + f.field + "-btn"}
                        label={cfg.label}
                        className={cfg.className}
                        value={getFieldDisplayValue(f, cfg)}
                        readonly={cfg.readonly || this.props.readonly}
                        onRemove={cfg.permanent?undefined:() => this.onRemoveFilter(cfg)}
                        onClear={cfg.permanent && f.value!==null?() => this.onClearFilter(cfg):undefined}
                    ><div className="form">
                        {this.renderFieldForm(f, cfg)}
                    </div></FilterFieldInput>
                })}
            {fieldsLeft.length===0?null:
                <Dropdown className="filter-add">
                    <Dropdown.Toggle variant="outline-secondary" className="filter-main">
                        <span className="icon gradient-circle mr-3">+</span>
                        {msgs.gui.labelAddFilter}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {fieldsLeft.map((f: FilterFieldConfig) => {
                            return <Dropdown.Item
                                key={f.field}
                                onClick={(e) => this.onAddFilter(f, e)}
                            >{f.label}</Dropdown.Item>;
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            }
        </Form>;
    }
}
FilterInput.contextType=LangContext;

export function extendFilterQuery(query: FilterQuery, field: string, value: any): FilterQuery {
    if(!query) return {
        text: "",
        fields: [ { field, value } ]
    }
    const fields=[ ...query.fields ];
    const idx=fields.findIndex((f: FieldFilter) => f.field===field);
    if(idx>=0) {
        fields[idx]={ field, value };
    } else {
        fields.push({ field, value });
    }
    return {
        text: query.text,
        fields,
    }
}

/**
 * Funkcja odfiltrowująca pola filtrowania
 * @param fields {Array<FilterFieldConfig>} - pola filtrowania, które należy odfiltrować
 * @param exclude {Array<string|FieldFilter>} - pola, które mają być wykluczone
 * @returns {Array<FilterFieldConfig>} - pola filtrowania, które nie zostały wykluczone
 */
export function filterFilterFields(fields: Array<FilterFieldConfig>, exclude: Array<string|FieldFilter>): Array<FilterFieldConfig> {
    if(!Array.isArray(exclude) || exclude.length===0) return fields;    // brak zmian
    const names=new Set(exclude.map(f => typeof(f)==='string'?f:f.field));
    return fields.filter(f => !names.has(f.field));
}

/**
 * Funkcja nadpisująca pola filtrowania
 * @param fields {Array<FieldFilter>|FilterQuery} - pola filtrowania, które mają być nadpisane
 * @param override {Array<FieldFilter>|null|undefined} - pola, które mają nadpisać
 * @return {{text: string, fields: FieldFilter[]}|FieldFilter[]|Array<FieldFilter>|FilterQuery}
 */
export function filterOverride(fields: Array<FieldFilter>|FilterQuery, override: Array<FieldFilter>|null|undefined): Array<FieldFilter>|FilterQuery {
    if(!Array.isArray(override) || override.length===0) return fields;  // brak nadpisywania
    let items=Array.isArray(fields)?[...fields]:[...fields.fields];
    for(let f of override) {
        if(f.value===null || f.value===undefined) continue;
        const idx=items.findIndex((i: FieldFilter) => i.field===f.field);
        if(idx>=0) items[idx]=f;
        else items.push(f);
    }
    if(Array.isArray(fields)) return items;
    return {
        text: fields.text,
        fields: items,
    }
}

export function filterConfigMarkReadonly(config: Array<FilterFieldConfig>, preset: Array<FieldFilter>|null|undefined): Array<FilterFieldConfig> {
    if(!Array.isArray(preset) || preset.length===0) return config;
    const fields=new Set(preset.map(f => f.field));
    return config.map(f => {
        if(fields.has(f.field)) return { ...f, readonly: true };
        return f;
    });
}