//@flow
import React from 'react';
import {PageHeader} from "../lib/Components";
import {formatString, useMsgs} from "../lib/Language";
import {useLocation} from "react-router";

const Error404 = () => {
    const msgs=useMsgs();
    const location=useLocation();
    const msg=formatString(msgs.gui.error404, location.pathname);

    return <>
        <PageHeader title={msg}/>
    </>;
}

export default Error404;