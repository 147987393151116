//@flow
import React from 'react';
import type {AccessRight, OrderRegistrationData} from "../api";
import {Events, events} from "../application";
import {Alert} from "react-bootstrap";
import msgs, {currentLang, formatString, getLangValue} from "./Language";
import {Ref} from "react";
import {FormikBag} from "formik";
import {Form} from "./Form";
import {IconAlert} from "./Components";
import {getDisplayLink} from "./Upload";
import type {ErrorResult} from "./Validation";

/**
 * Komponent, który wyświetla dostępne do wyboru usługi, które użytkownik może tworzyć
 */
export const SelectService = ({ onSelect, services }: {
    onSelect: (s: AccessRight) => void;
    services: Array<AccessRight>;
}) => {
    return <>
        {services.filter((s: AccessRight) => s.write && s.show[currentLang.code]).map((s: AccessRight) => <Alert
            key={s.id}
            variant="success"
            className="service-select"
            onClick={() => onSelect(s)}
        >
            <div>
                <h1>{getLangValue(s.name)}</h1>
                <div dangerouslySetInnerHTML={{ __html: getLangValue(s.desc) }}/>
            </div>
            <span className="icon-angle-right"/>
        </Alert>)}
    </>
}

const emptyService: OrderRegistrationData= {
        name: '',
        nip: '',
        desc: '',
    };

/**
 * Komponent wyświetlający fragment związany ze szczegółami zgłaszania usługi
 */
export const ServiceForm = ({ type, onSubmitted, formikRef, registerOrder }: {
    type: AccessRight;
    onSubmitted: (value: OrderRegistrationData) => void;
    formikRef: Ref<FormikBag>;
    registerOrder: (typeId: string, value: OrderRegistrationData) => Promise<ErrorResult|void>;
}) => {
    return <Form
        initialValues={emptyService}
        onSubmit={async (values, helper) => {
            let res = await registerOrder(type.typeId, values);
            if (!Form.setError(helper, res)) {
                onSubmitted(values);
                events.emit(Events.ServiceChange, type.ident);
            }
        }}
        formikRef={formikRef}
    >{() => <>
        <IconAlert className="mt-5">
            <div dangerouslySetInnerHTML={{ __html: getLangValue(type.desc) }}/>
            <div dangerouslySetInnerHTML={{ __html: formatString(msgs.gui.hintNewDebtPrices, getDisplayLink(getLangValue(type.prices))) }} />
        </IconAlert>
        <Form.RowGroup name="name">
            <Form.Label>{msgs.gui.labelServiceTargetName}</Form.Label>
            <Form.Text/>
        </Form.RowGroup>
        <Form.RowGroup name="nip">
            <Form.Label>{msgs.gui.labelServiceNIP}</Form.Label>
            <Form.NIP/>
        </Form.RowGroup>
        <Form.RowGroup name="desc">
            <Form.Label>{msgs.gui.labelServiceDesc}</Form.Label>
            <Form.TextArea rows={4}/>
        </Form.RowGroup>
    </>}
    </Form>;
}
