//@flow
import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from "react-router";
import {store} from "../application";
import {Modal} from "react-bootstrap";
import type {StaticPage} from "../api";
import Error404 from "./Error404";
import {AuthPage} from "../lib/Components";
import {useMsgs} from "../lib/Language";

/**
 * Wyświetlanie treści zapisanych w systemie nie będąc zalogowanym - ekran 801B.
 */
const StaticScreen=( props : RouteComponentProps<{ name: string }>) => {
    const [ data, setData ] = useState<StaticPage>(undefined);
    const lang=useMsgs().gui.langChangeLang;
    useEffect(() => {
        store.publicApi.getStaticPage(props.match.params.name).then(data => {
            setData(data);
        });
    }, [ props.match.params.name, lang ])

    if(data===undefined) return null;
    if(data===null) return <Error404/>;    // error 404
    return <AuthPage
        title={data.title}
    >
        <Modal.Body>
            <div className="static-content" dangerouslySetInnerHTML={{ __html: data.content }}/>
        </Modal.Body>
    </AuthPage>
}

export default StaticScreen;