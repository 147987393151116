//@flow
import React, {useState} from 'react';
import {useMsgs} from "../lib/Language";
import {Breadcrumb, Card} from "react-bootstrap";
import {BreadcrumbItem, PageHeader} from "../lib/Components";
import DesktopScreen from "./DesktopScreen";
import InvoiceMonitoringDesktopScreen from "./InvoiceMonitoringDesktopScreen";
import {emptyQuery} from "../lib/Filter";
import {useDelayed, useHistoryState} from "../lib/Hooks";
import {NotificationFilter, NotificationsList} from "../lib/InvoiceMonitoringComponents";
import {store} from "../application";
import {Redirect} from "react-router-dom";

const InvoiceMonitoringNotificationsScreen = () => {
    const msgs=useMsgs();
    // const [ filter, setFilter ] = useState(() => emptyQuery());
    const [ filter, setFilter ] = useHistoryState("filter", () => emptyQuery());
    const [ dFilter, setDFilter ] = useDelayed(filter, setFilter);
    if(!store.invoiceMonitoring || !store.invoiceMonitoring.read) return <Redirect to="/" push={false}/>

    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
            <BreadcrumbItem to={InvoiceMonitoringDesktopScreen.link()}>{msgs.gui.menuInvoiceMonitoringDesktop}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.gui.menuInvoiceMonitoringNotifications}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.gui.menuInvoiceMonitoringNotifications}/>
        <Card className="mb-5">
            <Card.Body>
                <NotificationFilter value={dFilter} onChange={setDFilter}/>
            </Card.Body>
        </Card>
        <NotificationsList customFilters={filter} historyState="table"/>
    </>;
}

InvoiceMonitoringNotificationsScreen.url="/im/notifications";
InvoiceMonitoringNotificationsScreen.link = () => InvoiceMonitoringNotificationsScreen.url;
export default InvoiceMonitoringNotificationsScreen;