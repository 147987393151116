//@flow
/* eslint-disable no-use-before-define */
import React, {Ref, useMemo} from 'react';
import {RouteComponentProps} from "react-router";
import type {ImportResult, IMSIGDataEntryInfo, IMSIGDataInfo, MonitoredEntry} from "../api";
import {Events, events, store} from "../application";
import {BreadcrumbItem, DeleteButton, Dialog, dialogOpen, IconAlert, NIPInput, PageHeader} from "../lib/Components";
import msgs, {DialogLink, formatString, LangContext, langLink, useMsgs} from "../lib/Language";
import {Breadcrumb, Button, Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import {BForm} from "../lib/Form";
import {arrayReplaceOrAdd, arrayUpdate, getLangNumeralSuffix, invertCompare, stringCompare} from "../lib/Utils";
import Dropzone, {DropzoneState} from "react-dropzone";
import type {ClientDataTableProps, DataTableAPI, DataTableColumn, DataTableRowSelection} from "../lib/DataTable";
import {ClientDataTable, dateCell, taxCell} from "../lib/DataTable";
import {NetworkDataProcessor} from "../lib/Network";
import DesktopScreen from "./DesktopScreen";
import Validate from "../lib/Validation";
import {ServiceNotAvailable} from "../ClientComponents";
import {Redirect} from "react-router-dom";
import {formatDate} from "../lib/DateTime";
import {FilterField} from "../lib/Filter";
import {FieldFilter} from "../api";

type NIPItem = {
    country: string;
    nip: string;
    id: string;
}

type InputProps = {
    menuPortalTarget?: null|HTMLElement;
    maxMenuHeight?: string|null;
    menuPosition?: "fixed"|"absolute";
}

interface AddFormProps {
    renderInputs(options?: InputProps): React$Node;
    renderDropzone(content: (dropZone: DropzoneState) => React$Node): React$Node;
    handleSubmit(e: SyntheticEvent): Promise<number>;
    +ready: boolean;
}

type AddFormParams = {
    onAdded?: (amount: number) => void;
    children: (addForm: AddFormProps) => React$Node;

}

type AddFormState = {
    values: Array<NIPItem>;
    left: number|null;
    processing: boolean;
}

class AddForm extends React.Component<AddFormParams, AddFormState> implements AddFormProps {
    idGen: number;

    static country: string=null;

    constructor(props) {
        super(props);
        this.idGen = 1;
        this.state = {
            values: null,
            left: undefined,
            processing: false,
        }
    }

    resetValues() {
        this.setState({
            values: [{country: AddForm.country || "PL", nip: "", id: 0}]
        });
    }

    componentDidMount() {
        if(!AddForm.country) {
            store.publicApi.getCountry().then((country) => {
                AddForm.country=country;
                this.resetValues();
            })
        } else {
            this.resetValues();
        }
        store.userApi.getMonitoredLeft().then(left => {
            this.setState({  left });
        })
    }

    handleSubmit = (e) => {
        return new Promise((resolve, reject) => {
            let data=this.state.values.filter(i => i.nip).map(i => ({ country: i.country, nip: i.nip }));
            console.log("Submit: ", data);
            if(data.length===0 || this.state.processing) return;
            this.setState({ processing: true });
            store.userApi.addMonitored(data).then((res) => {
                if(Validate.isError(res)) {
                    console.warn("Got error", res);
                } else {
                    this.resetValues();
                    resolve(res);
                    if (this.props.onAdded) this.props.onAdded(res);
                    if(typeof(res)==='number' && res>0) events.emit(Events.MonitoredChange);
                }
            }).catch(reject).finally(() => this.setState({ processing: false }));
        })
    }

    handleDrop = (files: FileList) => {
        console.log("On Drop: ", files.length);
        if(files.length===1) {
            const f: File=files[0];
            if(f.size>3145728) {
                window.alert("Niepoprawny plik danych");
                return;
            }
            const mr=new NetworkDataProcessor("monitored", "monitored");
            mr.upload(f).then((imp: ImportResult) => {
                // console.log("File processed: ", imp);
                const values:Array<NIPItem>=this.state.values;
                let defCountry=AddForm.country || "PL";
                if ( Array.isArray(values) && values.length>0 ) {
                    const last: NIPItem = values[values.length - 1];
                    if (last.country) defCountry = last.country;
                }
                let nv=values.filter(i => i.nip);
                let added=0;

                for(let i=0;i<imp.data.length;++i) {
                    const rec=imp.data[i];
                    if(typeof(rec)!=='object') continue;
                    let val=rec[0];
                    if(typeof(val)!=='string') continue;
                    val=val.trim();
                    if(val==='' || val.startsWith("#")) continue;
                    const lc=val.toLowerCase();
                    if(lc.includes("nip") || lc.includes("tax")) continue;
                    ++added;
                    if(added>this.state.left) {
                        // TODO: Jakiś komunikat?
                        break;
                    }
                    nv.push({ country: defCountry, nip: val, id: ++this.idGen });
                }
                if(added>0) {
                    this.setState({ values: nv });
                }
            }).catch(err => {
                console.log("Import error: ", err);
            }).finally(() => {
                mr.release();
            });
        }
    }

    renderDropzone(content: (dropZone: DropzoneState) => React$Node): React$Node {
        return <Dropzone
            multiple={false} onDrop={this.handleDrop}
            noClick={true}
            accept={NetworkDataProcessor.acceptData+",text/plain,.txt"}
        >{content}</Dropzone>
    }

    get ready(): boolean {
        return !!this.state.values.find(i => i.nip);
    }

    renderInputs(options?: InputProps) {
        return this.state.values.map((i:  NIPItem, index) => <div
            key={i.id}
            className="mt-3 mb-3"
        ><NIPInput
            maxMenuHeight={options && options.maxMenuHeight}
            menuPortalTarget={options && options.menuPortalTarget}
            menuPosition={options && options.menuPosition}
            countryValue={i.country}
            onCountryChange={country => this.setState({values: arrayUpdate(this.state.values, {...i, country})})}
            value={i.nip}
            onChange={e => {
                let n = arrayUpdate(this.state.values, {...i, nip: e.target.value});
                if (i.nip !== '' && index + 1 === this.state.values.length) {
                    n.push({country: AddForm.country || "PL", nip: "", id: this.idGen++});
                }
                this.setState({values: n});
            }}
            onBlur={() => {
                if (i.nip === '') {
                    if (index + 1 < this.state.values.length) {    // usuwamy puste, gdy nie ostatni
                        this.setState({values: this.state.values.filter(j => j.id !== i.id)});
                    }
                } else {
                    if (index + 1 === this.state.values.length) {
                        this.setState({values: [...this.state.values, {country: AddForm.country || "PL", nip: "", id: this.idGen++}]});
                    }
                }
            }}
        /></div>)
    }

    render() {
        if(!this.state.values || this.state.left===undefined) return null;
        if(typeof(this.props.children)==="function") return this.props.children(this);
        else return this.props.children;
    }
}

type MonitoredEntryExt = MonitoredEntry & {
    /** Czas ostatniego wpisu: dług lub wpis iMSiG */
    lastEntry: string;
}

const MonitoredTable = ({ onDelete, ...props }: $Diff<ClientDataTableProps, { columns: any}> & { onDelete: () => void } ) => {
    const msgs=useMsgs();
    const hasImsig=store.monitoring.settings['imsig']===true;
    const columns=useMemo<Array<DataTableColumn<MonitoredEntryExt>>>(() => {
        let res=[
            {
                accessor: "nip",
                Header: msgs.gui.labelNIP,
                className: "nip",
                type: "string",
                filter: "text",
                Cell: taxCell
            }, {
                accessor: "company",
                type: "string",
                filter: "text",
                Header: msgs.gui.labelCompanyName,
                Cell: ({ value, row }) => row.original.disabled?msgs.gui.entryOverLimit:value,
            }, {
                accessor: "lastImsig",
                className: "xl",
                Header: msgs.gui.labelImsig,
                filter: "function",
                type: "date",
                Cell: ({ value, row }) => {
                    if(row.original.disabled) return null;
                    if(!hasImsig) return msgs.gui.labelImsigNoAccess;
                    if(!value) return msgs.gui.labelImsigEmpty;
                    return <DialogLink to={IMSIGDialog.link(row.original.nip)}>
                        {formatString(msgs.gui.labelImsigLast, formatDate(value))}
                    </DialogLink>;
                }
            }, {
                accessor: "lastDebt",
                className: "date",
                Header: msgs.gui.labelNewestDebt,
                type: "date",
                Cell: dateCell
            }, {
                accessor: "debts",
                className: "md",
                type: "number",
                filter: "number",
                Header: msgs.gui.labelDebtsCount,
                Cell: ({ value, row }) => row.original.disabled?null:value,
            }
        ]
        if(onDelete) res.push({
            id: 'action',
            className: "delete",
            Header: "",
            Cell: ({ row }) => <DeleteButton onClick={() => onDelete(row.original)}/>
        });
        return res;
    }, [ msgs.gui.language, onDelete, hasImsig ]);
    return <ClientDataTable
        columns={columns}
        rowsLoad={500}
        defaultLimit={500}
        {...props}
    />;
}

const MonitoredHeader = ({children}) => {
    const msgs=useMsgs();
    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.gui.titleMonitored}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.gui.titleMonitored}>{children}</PageHeader>
    </>;
}


type State = {
    data: Array<MonitoredEntryExt>|null;
    selected: Array<string>;
    filter: Array<FieldFilter>;
    hasDebts: boolean;
    hasImsig: boolean;
    withDebts: boolean;
    withImsig: boolean;
    nipQuery: string;
}


export default class MonitoredScreen extends React.Component<RouteComponentProps, State> {
    static emptyFilter=[];
    static url="/monitored";

    selected: Set<string>;
    edit: boolean;
    max: number;
    ref: Ref<DataTableAPI>;

    constructor(props) {
        super(props);
        this.edit=store.monitoring && store.monitoring.write;
        this.max=store.monitoring?store.monitoring.limit:0;
        this.selected=null;
        this.ref=React.createRef();
        this.state={
            data: null,
            selected: [],
            filter: [],
            hasDebts: false,
            hasImsig: false,
            withDebts: false,
            withImsig: false,
            nipQuery: "",
        }
    }

    refresh() {
        if(!store.monitoring || !store.monitoring.read) return;
        store.userApi.getMonitored().then((data: Array<MonitoredEntry>) => {
            if(data===null) {
                this.props.history.replace(langLink(DesktopScreen.url));
                return;
            }
            for(let e: MonitoredEntryExt of data) {
                if(e.lastImsig && e.lastDebt) {
                    if(e.lastImsig>e.lastDebt) e.lastEntry=e.lastImsig;
                    else e.lastEntry=e.lastDebt;
                } else if(e.lastImsig) e.lastEntry=e.lastImsig;
                else e.lastEntry=e.lastDebt;
            }
            data.sort((v1, v2) => stringCompare(v2.lastEntry, v1.lastEntry) );

            this.setState({
                data,
                hasDebts: !!data.find(i => i.debts>0),
                hasImsig: !!data.find(i => !!i.lastImsig),
            })
        });
    }

    componentDidMount() {
        this.refresh();
    }

    handleSelectionChange = (sel: DataTableRowSelection) => {
        this.setState({ selected: Object.keys(sel) })

    }

    onAdd(e: Event) {
        dialogOpen(this, AddMonitoredDialog.url, () => this.refresh());
    }

    onDelete= (m: MonitoredEntry)=> {
        store.userApi.deleteMonitored([ m.id ]).then((e) => {
            this.refresh();
        })
    }

    onDeleteSelected = (e: Event) =>{
        if(this.state.selected.length>0) {
            store.userApi.deleteMonitored(this.state.selected).then(() => {
                if(this.ref.current) this.ref.current.clearSelection();
                this.refresh();
            })
        }
    }

    hasFilter(name: string) {
        return !!this.state.filter.find(f => f.field===name);
    }

    toggleFilter(name: string, filter: any) {
        const idx=this.state.filter.findIndex(f => f.field===name);
        if(idx===-1) {
            this.setState({ filter: [ ...this.state.filter, filter ] });
        } else {
            this.setState({ filter: this.state.filter.filter((f: FieldFilter) => f.field!==name) });
        }
    }

    updateFilterState(field: "withDebts"|"withImsig"|"nip", value: any) {
        if(this.state[field]===value) return;
        if(field==="nip") {
            let newFilter;
            if(!value) newFilter=this.state.filter.filter(f => f.field!=='nip');
            else newFilter=arrayReplaceOrAdd(this.state.filter, f => f.field==='nip', {
                field: 'nip',
                value: value
            }, true);

            this.setState({
                nipQuery: value,
                filter: newFilter
            });
        } else {
            let {withDebts, withImsig} = this.state;

            if (field === "withDebts") withDebts = value;
            else if (field === "withImsig") withImsig = value;

            let filterFunc;
            if (withDebts && withImsig) filterFunc = (row: MonitoredEntryExt) => row.debts > 0 || !!row.lastImsig;
            else if (withDebts) filterFunc = (row: MonitoredEntryExt) => row.debts > 0;
            else if (withImsig) filterFunc = (row: MonitoredEntryExt) => !!row.lastImsig;
            else filterFunc = () => true;

            this.setState({
                [field]: value,
                filter: arrayReplaceOrAdd(
                    this.state.filter,
                    (f: FieldFilter) => f.field === '',
                    {field: '', value: filterFunc},
                    true
                )
            });
        }
    }

    renderEmpty() {
        return <Card>
            <Card.Body>
                <div className="text-center mt-3 mb-3">
                    <img src="/resources/img/empty/observation.png" alt=""/>
                </div>
                <h2 className="text-center mt-4 mb-4">{msgs.gui.labelNoMonitored}</h2>
                <h5 className="text-center mb-4">{msgs.gui.hintNoMonitored}</h5>
                {this.edit?<AddForm
                    onAdded={() => this.refresh()}
                >{
                    (props: AddFormProps) => props.renderDropzone(
                        (dropZone) => <Card
                            className={"max-width-sm mx-auto dropzone-border "+(dropZone.isDragActive?"active":"")}
                            {...dropZone.getRootProps()}
                        >
                    <Card.Body>
                        <input {...dropZone.getInputProps()}/>
                        {props.renderInputs()}
                        <div className="text-center mt-4">
                            <Button
                                disabled={!props.ready}
                                size="xl" onClick={props.handleSubmit}
                            >{msgs.gui.actionAddClient}</Button>
                        </div>
                        <div className="text-center mt-4 font-size-lg">
                            {msgs.gui.labelOr} <Button
                            variant="link" size="lg"
                            onClick={() => dropZone.open()}
                        >{msgs.gui.actionFileAddMonitored}</Button>
                        </div>
                    </Card.Body>
                </Card>)}</AddForm>:null}
            </Card.Body>
        </Card>;
    }

    renderData() {
        const debtsFilter=this.state.withDebts;
        const imsigFilter=this.state.withImsig;
        return <>
            {(debtsFilter && !this.state.hasDebts)?<IconAlert variant="warning">{msgs.gui.hintNoMonitoredWithDebts}</IconAlert>:null}
            {(imsigFilter && !this.state.hasImsig)?<IconAlert variant="warning">{msgs.gui.hintNoMonitoredWithImsig}</IconAlert>:null}
            <Button
                variant="link"
                className={'ml-3 mb-2'+(this.state.selected.length===0?' invisible':'')}
                disabled={this.state.selected.length === 0}
                onClick={(e) => this.onDeleteSelected(e)}
            >{msgs.gui.actionDeleteSelected}</Button>
            <MonitoredTable
                onDelete={this.edit && this.onDelete}
                apiRef={this.ref}
                data={this.state.data}
                onSelectionChange={this.edit && this.handleSelectionChange}
                hideFiltersHeader
                customFilters={this.state.filter}
                historyState="table"
                tableFooter={this.state.selected.length===0?null:<Button
                    variant="link"
                    className="ml-3"
                    disabled={this.state.selected.length === 0}
                    onClick={(e) => this.onDeleteSelected(e)}
                >{msgs.gui.actionDeleteSelected}</Button>}
            />
        </>
    }

    renderAddButton() {
        const disabled=this.state.data.length>=this.max;
        return <Button
            size="xl"
            className="btn-icon"
            disabled={disabled}
            style={{ pointerEvents: disabled?'none':undefined }}
            onClick={(e) => this.onAdd(e)}>
            <span className="icon"><span className="icon-add-outline"/></span>
            {msgs.gui.actionAddMonitored}
        </Button>;
    }

    render() {
        if(!store.monitoring) return <Redirect to={langLink("/")}/>;
        if(!store.monitoring.read) {
            return <>
                <MonitoredHeader/>
                <ServiceNotAvailable/>
            </>
        }
        return <>
            <MonitoredHeader>
                {(this.state.data && this.state.data.length>0 && this.edit)?(this.state.data.length>=this.max?<OverlayTrigger
                        overlay={<Tooltip id="add-limit-tooltip">{formatString(msgs.gui.hintLimitReached, this.max)}</Tooltip>}
                    ><span className="d-inline-block">{this.renderAddButton()}</span></OverlayTrigger>:this.renderAddButton())
                    :null}
            </MonitoredHeader>
            {this.state.data?(this.state.data.length===0?this.renderEmpty():<>
                <BForm inline className="mb-3" onSubmit={e => e.preventDefault()}>
                    {/*<BForm.Label>{msgs.gui.labelNIP}</BForm.Label>*/}
                    <BForm.Control
                        type="text"
                        value={this.state.nipQuery}
                        onChange={e => this.updateFilterState('nip', e.target.value)}
                        placeholder={msgs.gui.labelNIP}
                        className="mr-3"
                    />
                    <BForm.Check
                        id="show_only_imsig"
                        className="form-control-lg user-select-none"
                        type="switch"
                        custom
                        size="lg"
                        label={msgs.gui.labelShowOnlyImsig}
                        checked={this.state.withImsig}
                        onChange={() => this.updateFilterState('withImsig', !this.state.withImsig)}
                    />
                    <BForm.Check
                        id="show_only_debtor"
                        className="form-control-lg user-select-none"
                        type="switch"
                        custom
                        size="lg"
                        label={msgs.gui.labelShowOnlyDebtors}
                        checked={this.state.withDebts}
                        onChange={() => this.updateFilterState('withDebts', !this.state.withDebts) }
                    />
                </BForm>
                {this.renderData()}
            </>):null}
        </>;
    }
}
MonitoredScreen.contextType=LangContext;
MonitoredScreen.mainClassName="screen-monitored";

/**
 * Okno z danymi z serwisu iMSiG.pl
 */
export class IMSIGDialog extends React.Component<RouteComponentProps<{ number: string }>, { data: IMSIGDataInfo|null, loaded: boolean }> {
    static url = "/imsig/:number";
    static link=(number: string) => "/imsig/"+number;

    constructor(props) {
        super(props);
        this.state={
            loaded: false,
            data: null,
        }
    }

    componentDidMount() {
        store.userApi.getImsigData(this.props.match.params.number).then(data => {
            this.setState({ data: data, loaded: true });
        })
    }

    render() {
        if(!this.state.loaded) return null;
        return <Dialog title={msgs.gui.titleImsig} className="imsig-dialog" scrollable>
            {this.state.data.entries.map((e: IMSIGDataEntryInfo, index) => <div key={index} className="imsig-entry">
                <div className="imsig-entry-header">
                    <span className="imsig-id">{e.msigSignature || e.krzSignature || e.extId}</span>
                    <span className="imsig-date">{formatDate(e.date)}</span>
                </div>
                <div className="imsig-entry-content" dangerouslySetInnerHTML={{ __html: e.content }}/>
                </div>)
            }
        </Dialog>;
    }
}
IMSIGDialog.contextType=LangContext;

export class AddMonitoredDialog extends React.Component<RouteComponentProps, { limit: number, added: number|null, ready: boolean, processing: boolean }> {
    static url = "/monitored_add";

    constructor(props) {
        super(props);
        this.state = {
            limit: null,
            added: null,
            ready: false,
            processing: false,
        }
    }

    componentDidMount() {
        store.userApi.getMonitoredLeft().then(limit => this.setState({ limit }));
    }

    handleShow = () => {
        this.setState({ ready: true });
    }


    render() {
        if (this.state.limit === null) return null;

        return <AddForm>{(props: AddFormProps) => <Dialog
            scrollable
            onEntering={this.handleShow}
            title={msgs.gui.titleAddMonitored}
            cancelButton={this.state.added === null ? undefined : null}
            acceptButton={this.state.added === null ? msgs.gui.buttonNext : msgs.gui.buttonClose}
            onAccept={(d) => {
                if(this.state.processing) return;
                if (this.state.added === null) {
                    this.setState({ processing: true });
                    props.handleSubmit().then((added) => {
                        this.setState({added})
                        // this.props.history.goBack();
                    }).finally(() => {
                        this.setState({ processing: false });
                    })
                    return false;
                }
            }}
        >
            {this.state.added!==null ? <>
                <IconAlert>
                    <h1>{formatString(msgs.gui['infoMonitoredAdded'+getLangNumeralSuffix(this.state.added)], this.state.added)}</h1>
                    {(this.state.limit+this.state.added)>=store.monitoring.limit?<p>
                        {formatString(msgs.gui.hintLimitReached, store.monitoring.limit)}
                    </p>:null}
                </IconAlert>
            </> : <>
                <IconAlert>{formatString(msgs.gui.hintAddInfo, this.state.limit)}</IconAlert>
                {this.state.ready && props.renderInputs({ menuPosition: "fixed", maxMenuHeight: 300 })}
                {props.renderDropzone((dropZone) => <div
                        className="dropzone"
                        {...dropZone.getRootProps()}>
                        <input {...dropZone.getInputProps()}/>
                        <div className="text-center font-size-lg">
                            {msgs.gui.labelOr} <Button
                            variant="link" size="lg"
                            onClick={() => dropZone.open()}
                        >{msgs.gui.actionFileAddMonitored}</Button>
                        </div>
                    </div>
                )}
            </>}
        </Dialog>}</AddForm>
    }
}