//@flow
import React from 'react';
import type {AccessRight, UserNotification} from "../api";
import {Form, Table} from "react-bootstrap";
import {arrayToggleItem} from "./Utils";
import {formatString, getLangValue, useMsgs} from "./Language";

/** Pomocniczy komponent */
const NotificationCheckbox = ({ readonly, checked, onChange, id }: {
    readonly?: boolean;
    checked: boolean;
    onChange: (e: SyntheticEvent) => void;
    id: string;
}) => {
    return <Form.Check
            custom inline
            type="checkbox"
            disabled={readonly}
            checked={checked}
            onChange={onChange}
            id={id}
    />;
}

/**
 * Komponent wyświetlający checkboxy do konfiguracji powiadomień dla użytkownika
 */
const Notifications = ({ value, onChange, readonly, className, services }: {
    value: Array<UserNotification>;
    onChange: (value: Array<UserNotification>) => void;
    services: Array<AccessRight>;
    readonly ?: boolean;
    className ?: string;
}) => {
    const msgs = useMsgs();
    const change = (i: number, type: string) => {
        return () => {
            let n = value[i];
            let ni = arrayToggleItem(n.mode, type);
            onChange(value.map((v: UserNotification, j) => j !== i ? v : {...v, mode: ni}));
        }
    };

    return <Table className={className}>
        <thead>
        <tr>
            <th/>
            <th className="text-center">{msgs.gui.labelNotificationsInSummary}</th>
            <th className="text-center">{msgs.gui.labelNotificationsInNotifications}</th>
            <th className="text-center">{msgs.gui.labelNotificationsByEmail}</th>
        </tr>
        </thead>
        <tbody>
        {value.map((n: UserNotification, i) => {
            let param=null;
            if(n.orderId) {
                let si:AccessRight=services.find((s: AccessRight) => s.typeId===n.orderId);
                if(si) param=getLangValue(si.name);
            }
            const label=msgs.gui['notification'+n.type];
            return <tr key={i}>
                <td>{param?formatString(label, param):label}</td>
                <td className="text-center checkbox">
                    <NotificationCheckbox id={"ncb_" + i + "_s"} readonly={readonly} checked={n.mode.includes("Summary")} onChange={change(i, "Summary")}/>
                </td>
                <td className="text-center checkbox">
                    <NotificationCheckbox id={"ncb_" + i + "_n"} readonly={readonly} checked={n.mode.includes("Notification")} onChange={change(i, "Notification")}/>
                </td>
                <td className="text-center checkbox">
                    <NotificationCheckbox id={"ncb_" + i + "_e"} readonly={readonly} checked={n.mode.includes("Email")} onChange={change(i, "Email")}/>
                </td>
            </tr>
        })}
        </tbody>
    </Table>
}

export default Notifications;