//@flow
import React, {useEffect} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {Container} from "react-bootstrap";
import {contactUrl, Footer, Icon, staticPageUrl} from "../lib/Components";
import {langLink, langPath, SwitchLanguage, useMsgs} from "../lib/Language";
import LoginScreen from "./LoginScreen";
import RegisterScreen, {CompanyDataScreen, RegisterComplete, RegisterConfirm} from "./RegisterScreen";
import ResetPasswordScreen, {ResetEmailSendScreen} from "./ResetPasswordScreen";
import PasswordChangeScreen, {PasswordChangedScreen} from "./PasswordChangeScreen";
import StaticScreen from "./StaticScreen";
import ContactScreen from "./ContactScreen";
import {useLocation} from "react-router";
import {store} from "../application";
import RegisterWithVindicationScreen from "./RegisterWithVindicationScreen";
import ActivateScreen from "./ActivateScreen";

const LoginRedirect=() => {
    const location=useLocation();

    console.log("Storing return url: ", location);
    store.setReturnPage({
        pathname: location.pathname,
        state: location.state
    });

    return <Redirect to={langLink(LoginScreen.url)}/>;
}

/** Główna część aplikacji logowania */
const LoginApp= () => {
    const msgs=useMsgs();
    useEffect(() => {
        document.body.classList.add("auth");
        return () => {
            document.body.classList.remove("auth");
        }
    });
    return <>
        <main>
            <Container>
                <header>
                    <div className="logos">
                        <a href="/">
                            <Icon.Logo/>
                        </a><span>{msgs.gui.logoEuleoBy}</span><a href="https://euleo.pl" target="_blank" rel="noopener noreferrer">
                            <Icon.EULEOWhite/>
                        </a>
                    </div>
                    <SwitchLanguage/>
                </header>
                <Switch>
                    <Route path={langPath(LoginScreen.url)} exact={true} component={LoginScreen}/>

                    <Route path={langPath(RegisterScreen.url)} exact={true} component={RegisterScreen}/>
                    <Route path={langPath(CompanyDataScreen.url)} exact={true} component={CompanyDataScreen}/>
                    <Route path={langPath(RegisterConfirm.url)} exact={true} component={RegisterConfirm}/>
                    <Route path={langPath(RegisterComplete.url)} exact={true} component={RegisterComplete}/>
                    <Route path={langPath(ActivateScreen.url)} exact={true} component={ActivateScreen}/>

                    <Route path={langPath(RegisterWithVindicationScreen.url)} exact={true} component={RegisterWithVindicationScreen}/>

                    <Route path={langPath(ResetPasswordScreen.url)}  exact={true} component={ResetPasswordScreen}/>
                    <Route path={langPath(ResetEmailSendScreen.url)} exact={true} component={ResetEmailSendScreen}/>

                    <Route path={langPath(PasswordChangeScreen.url)} component={PasswordChangeScreen}/>
                    <Route path={langPath(PasswordChangedScreen.url)} exact={true} component={PasswordChangedScreen}/>
                    <Route path={langPath(staticPageUrl)} component={StaticScreen}/>
                    <Route path={langPath(contactUrl)} component={ContactScreen}/>
                    <Route path={langPath("/")} exact><Redirect to={langLink(LoginScreen.url)}/></Route>

                    <Route><LoginRedirect/></Route>
                    {/*<Route component={Error404}/>*/}
                </Switch>
            </Container>
        </main>
        <Footer/>
    </>;
}

export default LoginApp;

