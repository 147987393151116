//@flow

import { ImportResult } from "../api";

/** Typ z informacjami błędu dla zapytani HTTP remote proxy */
export type RequestError = {
    /** Kod HTTP błędu z serwera */
    code: number;
    /** Komunikat błędu z serwera */
    message: string;
    /** Czas błędu */
    time: Date,
    /** Wywoływana funkcja */
    method: string;
    /** Dane w zapytaniu (body) */
    payload: string;
}

export type ErrorCallback=(error: RequestError) => boolean;


/**
 * Funkcja opakowująca w proxy proste RPC dla wywołania method na serwerze,
 * które zawsze zwracają Promise.
 * @param path baza ścieżki do zapytań
 * @param callback dodatkowy obiekt z metodami do obsługi nietypowych sytuacji
 * @return {T}
 */
export function remoteProxy<T>(path: string, errorCallback?: ErrorCallback): T {
    return new Proxy({}, {
        generated: new Map(),
        get: function(target, name) {
            let res=this.generated.get(name);
            if(res) return res;
            res=function(...args) {
                return new Promise(((resolve, reject) => {
                    const payload=JSON.stringify(args);
                    fetch(path+"/"+name, {
                        method: 'POST',
                        cache: 'no-cache',
                        credentials: 'same-origin',
                        headers: {
                            'Content-Type': 'application/json',
                            // 'Accept': 'application/json',
                        },
                        body: payload
                    }).then(res => {
                        if(typeof(res)!=='object') {
                            console.warn("Invalid response. Got: ", res);
                            reject("Invalid response!");
                            return;
                        }
                        if(res.status===200) {
                            res.json().then((res) => {
                                resolve(res);
                            }).catch(reject);
                        } else {
                            console.warn("Got error response from server: "+res.status+" "+res.statusText);
                            if(typeof(errorCallback)==='function') {
                                const error: RequestError = {
                                    code: res.status,
                                    message: res.statusText,
                                    time: new Date(),
                                    method: name,
                                    payload: payload,
                                };
                                res.text().then(text => {
                                    if(text) {
                                        const ms=text.indexOf('<tr><th>MESSAGE:</th><td>');
                                        if(ms>0) {
                                            const me=text.indexOf('</td>', ms);
                                            if(me>0) {
                                                text=text.substring(ms+25, me)
                                                    .replaceAll('&apos;', "'")
                                                    .replaceAll('&quot;', '"')
                                                    .replaceAll('&gt;', '>')
                                                    .replaceAll('&lt;', '<');
                                            }
                                        }
                                        error.message=text;
                                    }
                                }).finally(() => {
                                    if(!errorCallback(error)) {
                                        window.alert("Unhandled state code: "+error.code+"\n"+error.message);
                                    }
                                });
                            }
                            reject("Invalid response from server "+res.status+" "+res.statusText);
                            // reject(new Error("Invalid response from server "+res.status+" "+res.statusText));
                        }
                    }).catch(reject);
                }));
            }
            this.generated.set(name, res);
            return res;
        }
    });
}

/**
 * Klasa do przetwarzania danych CSV, XLSX przez serwer.
 */
export class NetworkDataProcessor {
    name: string;
    data: ImportResult|undefined;
    type:? string;

    static acceptData=".csv,.xls,.xlsx,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel";

    constructor(name: string, type?: string) {
        this.name=name;
        this.type=type;
    }

    upload(file: File): Promise<ImportResult> {
        return new Promise<ImportResult>((resolve, reject) => {
            fetch("/data/import/"+this.name+(this.type?"?type="+this.type:""), {
                method: "POST",
                body: file
            }).then((res: Response) => {
                if(res.status!==200) {
                    console.warn("Processor response: ", res.status, res.statusText);
                    reject(res.statusText);
                    return;
                }
                res.json().then((data) => {
                    this.data=data;
                    resolve(data);
                }).catch(reject);
            }).catch(reject)
        });
    }

    release(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            fetch("/data/import/"+this.name, {
                method: "DELETE"
            }).then((res: Response) => {
                if(res.status===200) resolve();
                else {
                    reject(res.statusText);
                }
            }).catch(reject);
        });
    }
}